import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "account-view" }
const _hoisted_2 = { class: "account-view-content" }
const _hoisted_3 = {
  key: 0,
  class: "flex-column flex-gap"
}
const _hoisted_4 = { class: "flex-column flex-gap" }
const _hoisted_5 = { class: "flex-column flex-gap flex-start align" }
const _hoisted_6 = {
  key: 1,
  class: "flex-column flex-gap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccountNav = _resolveComponent("AccountNav")!
  const _component_NavPlaceholder = _resolveComponent("NavPlaceholder")!
  const _component_LoginPrompt = _resolveComponent("LoginPrompt")!
  const _component_Separator = _resolveComponent("Separator")!
  const _component_ThemeSwitcher = _resolveComponent("ThemeSwitcher")!
  const _component_LanguageSwitcher = _resolveComponent("LanguageSwitcher")!
  const _component_DateTypeSwitcher = _resolveComponent("DateTypeSwitcher")!
  const _component_AccountSettings = _resolveComponent("AccountSettings")!
  const _component_AccountInfo = _resolveComponent("AccountInfo")!
  const _component_AccountLogins = _resolveComponent("AccountLogins")!
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AccountNav, {
      onReturn: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
    }),
    _createVNode(_component_NavPlaceholder),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.FSApi.isPublicUser())
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_LoginPrompt)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Separator, {
          label: _ctx.t('account.preferences.separator-label')
        }, null, 8, ["label"]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ThemeSwitcher),
          _createVNode(_component_LanguageSwitcher),
          _createVNode(_component_DateTypeSwitcher)
        ])
      ]),
      (!_ctx.FSApi.isPublicUser())
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_Separator, {
              label: _ctx.t('account.settings.separator-label')
            }, null, 8, ["label"]),
            _createVNode(_component_AccountSettings, { class: "align" }),
            _createVNode(_component_Separator, {
              label: _ctx.t('account.info.separator-label')
            }, null, 8, ["label"]),
            _createVNode(_component_AccountInfo),
            _createVNode(_component_Separator, {
              label: _ctx.t('account.logins.separator-label')
            }, null, 8, ["label"]),
            (_ctx.loginHistoryShown)
              ? (_openBlock(), _createBlock(_component_AccountLogins, {
                  key: 0,
                  dateDisplayType: _ctx.AppPreferenceService.dateType.value
                }, null, 8, ["dateDisplayType"]))
              : (_openBlock(), _createBlock(_component_IconButton, {
                  key: 1,
                  icon: "visibility.svg",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loginHistoryShown=true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('account.logins.reveal-button')), 1)
                  ]),
                  _: 1
                }))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}