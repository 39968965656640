import { WebFSUtil } from "ksancloud-commons";
import WebFSFileMeta from "../api/file-meta";
import { MediaType } from "./media-type";
import { partial } from "filesize";
import { fileExtensionIconMap } from './maps/file-extensions';
import { folderNameIconMap } from './maps/folder-names';
import { fileNameIconMap } from './maps/file-names';
import { programmingLaunguageExtensionMap } from './maps/programming-language-extensios';
import { languageIdIconMap } from './maps/language-ids';

const typemap = new Map<string, MediaType>([
  ["gif", MediaType.IMAGE],
  ["png", MediaType.IMAGE],
  ["jpg", MediaType.IMAGE],
  ["bmp", MediaType.IMAGE],
  ["exr", MediaType.IMAGE],
  ["jpeg", MediaType.IMAGE],
  ["tif", MediaType.IMAGE],
  ["webp", MediaType.IMAGE],
  ["mp4", MediaType.VIDEO],
  ["mkv", MediaType.VIDEO],
  ["webm", MediaType.VIDEO],
  ["avi", MediaType.VIDEO],
  ["mov", MediaType.VIDEO],
  ["wav", MediaType.AUDIO],
  ["mp4a", MediaType.AUDIO],
  ["mp3", MediaType.AUDIO],
  ["ogg", MediaType.AUDIO],
  ["flac", MediaType.AUDIO],
  ["pdf", MediaType.PDF],
  ["zip", MediaType.ARCHIVE],
  ["jar", MediaType.ARCHIVE],
  ["war", MediaType.ARCHIVE],
  ["ear", MediaType.ARCHIVE],
  ["apk", MediaType.ARCHIVE],
  ["docx", MediaType.ARCHIVE],
  ["xlsx", MediaType.ARCHIVE],
  ["pptx", MediaType.ARCHIVE],
  ["epub", MediaType.ARCHIVE],
  ["xpi", MediaType.ARCHIVE],
  ["xps", MediaType.ARCHIVE],
  ["odt", MediaType.ARCHIVE],
  ["ods", MediaType.ARCHIVE],
  ["odp", MediaType.ARCHIVE]
]);

const sizeFormatter = partial({base: 2, standard: "jedec"});
export {sizeFormatter};
export function HMS(millis: number) {
  const neg = millis < 0;
  millis = Math.abs(millis);
  const ms = Math.floor(millis);
  const h = Math.floor(ms/3600/1000);
  const m = (Math.floor(ms/60/1000)%60);
  const s = ((ms/1000)%60);
  return `${neg ? '-' : ''}${h}:${m.toString().padStart(2, '0')}:${s<10?'0':''}${s.toFixed(3)}`;
}
export default {
  requireFileIcon(name: string) {
    try {
      return `/img/fileicons/${name}`;
    } catch (e) {
      return `/img/fileicons/file.svg`;
    }
  },

  getIconForFile(filename: string, isdir: boolean, hovered = false): string {
    if(isdir) {
      const dictNameLookup = folderNameIconMap.get(filename);
      return (dictNameLookup ? dictNameLookup : 'folder') + (hovered ? '-open' : '') + '.svg';
    } else {
      const fileNameLookup = fileNameIconMap.get(filename);
      if (fileNameLookup) return fileNameLookup + '.svg';
      const ext = WebFSUtil.splitname(filename).ext;
      if (ext == undefined) return 'file.svg';
      const fileExtensionLookup = fileExtensionIconMap.get(ext.substring(1));
      const programmingLanguageExtensionLookup = programmingLaunguageExtensionMap.get(ext.substring(1));
      const extensionLookup = fileExtensionLookup || programmingLanguageExtensionLookup;
      return (extensionLookup ? (languageIdIconMap.get(extensionLookup) || extensionLookup) : 'file') + '.svg'
    }
  },

  getIconForMeta(meta: WebFSFileMeta, hovered = false): string {
    return this.getIconForFile(meta.name, meta.isdir, hovered);
  },

  getMediaTypeForFile(filename: string, isdir: boolean): MediaType {
    if (isdir) return MediaType.NONE;
    const ext = WebFSUtil.splitname(filename).ext;
    if (ext == undefined) return MediaType.NONE;
    return (
      typemap.get(ext.substring(1)) || (programmingLaunguageExtensionMap.get(ext.substring(1)) ? MediaType.TEXT : undefined) || MediaType.NONE
    );
  },

  getFileLanguage(filename: string, isdir: boolean): string {
    const ext = WebFSUtil.splitname(filename).ext;
    if (ext == undefined) return "";
    const r = isdir ? "" : programmingLaunguageExtensionMap.get(ext.substring(1));
    return !r ? "" : r;
  },

  formatFileSize(size: number) {
    return sizeFormatter(size);
  },
};
