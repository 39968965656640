
import {defineComponent} from "vue";
import LargeLogo from "@/components/logo/LargeLogo.vue";
import FileHistory from "@/components/account/FileHistory.vue";
import FSApi from "@/api/file-system-api";
import {useTranslation} from "i18next-vue";
import ChipPane from "@/components/util/ChipPane.vue";
import FileBookmarks from "@/components/account/FileBookmarks.vue";

export default defineComponent({
  name: "HomeView",
  setup() {
    const {t} = useTranslation();
    return {t};
  },
  computed: {
    FSApi() {
      return FSApi
    }
  },
  components: {
    FileBookmarks,
    FileHistory,
    ChipPane,
    LargeLogo,
  }
});
