
import { useTranslation } from "i18next-vue";
import { defineComponent } from "vue";
import MediumLogo from "../logo/MediumLogo.vue";

export default defineComponent({
	name: "LogoContainer",
	setup() {
		const { i18next } = useTranslation();
		return { t: i18next.t, i18next: i18next };
	},
	components: { MediumLogo },
});
