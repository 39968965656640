import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d2967c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error-display" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterButton = _resolveComponent("RouterButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(_ctx.t([`error.${_ctx.error?.name}`, "error.0"])), 1),
    _createVNode(_component_RouterButton, {
      class: "login-elem",
      icon: "login.svg",
      to: `/login`
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("login.button-message")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_RouterButton, {
      class: "login-elem",
      icon: "home.svg",
      to: `/files${_ctx.FSApi.userInfo.value?.entryFolder}`
    }, null, 8, ["to"])
  ]))
}