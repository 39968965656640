import { Permission, AccessMode, UserRole } from "ksancloud-commons";

export default class UserInfo {
    public uuid?: string;
    public name?: string;
    public login?: string;
    public role?: UserRole;
    public permissions?: Permission[];
    public homedir?: string;
    public enabled?: boolean;

  constructor(
    uuid: string,
    name: string, 
    role: UserRole,
    permissions: Permission[],
    homedir: string,
    enabled: boolean,
    login: string | undefined = undefined
) {
    this.uuid = uuid;
    this.name = name;
    this.role = role;
    this.permissions = permissions
    this.homedir = homedir;
    this.login = login;
    this.enabled = enabled;
  }

  public get isadmin(): boolean {
    return this.role == UserRole.ADMIN
  }

  public get ispublic(): boolean {
    return this.role == UserRole.PUBLIC
  }

  public get entryFolder(): string | undefined{
    return this.permissions && this.permissions.length > 0 ?
      this.permissions[0].pathstring : "/";
  }

  public hasPermission(path: string, mode: AccessMode) {
    if(this.isadmin) return true; // admins best
    if(this.permissions == undefined) return false;
    for(let i = 0; i < this.permissions.length; i++) {
      if(this.permissions[i].match(path, mode)) return true
    } return false;
  }

  public clone(): UserInfo {
    return new UserInfo(this.uuid || '', this.name || '', this.role || UserRole.USER, this.permissions || [], this.homedir || '', this.enabled || false, this.login);
  }
}