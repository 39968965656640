
import { defineComponent } from "vue";
import IconButton from "@/components/util/IconButton.vue";
import AccountIcon from "@/components/account/AccountIcon.vue";
export default defineComponent({
  name: "AccountName",
  components: {AccountIcon, IconButton},
  props: ["name","editable","user","large"],
  emits: ["edit"]
});
