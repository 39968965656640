import {reactive, ref} from 'vue';
import WebFSFileMeta from './file-meta';
import axios from "axios";
import ApiError from "./api-error";
import {ApiErrorType} from "./api-error";
import {Permission, AccessMode, WebFSUtil, LoginAttempt, UserRole, AccessModeObject} from 'ksancloud-commons';
import UserInfo from "./user-info";
import {SearchMatcherType} from "@/util/SearchOptions";
import {AppPreferenceService} from "@/util/app-preference-service";

export const config = reactive<{ port?: number; token: string; endpoint: string; }>({
    port: 5000,
    token: "",
    /**
     * Assumed to have a / at the end
     */
    endpoint: "",
});

export default class FSApi {
    public static logout() {
        localStorage.setItem('api-access-token', '');
        config.token = '';
        this.resetUserData();
    }

    public static loggedIn(): boolean {
        return this.userInfo.value != undefined && config.token != undefined && !this.userInfo.value.ispublic;
    }

    public static isPublicUser(): boolean {
        return this.userInfo.value == undefined || this.userInfo.value.ispublic == true
    }

    public static isAdminUser(): boolean {
        return this.userInfo.value != undefined && this.userInfo.value.isadmin == true
    }

    public static resetUserData() {
        this.userInfoPromiseCache.delete('');
        this.userInfo.value = undefined;
        this.bookmarks.value = [];
        this.bookmarkSet.value = new Set();
    }

    public static readonly userInfo = ref<UserInfo>();
    public static readonly bookmarks = ref<{ path: string, date: number }[]>([]);
    public static readonly bookmarkSet = ref<Set<string>>(new Set())
    public static readonly profilePictureRefresh = ref<number>(Math.random());

    public static hasPermission(path?: string | string[] | WebFSFileMeta, mode: AccessMode | string | AccessModeObject = 'v') {
        if (path == undefined || this.userInfo.value == undefined) return false;
        if (typeof mode === 'string') mode = Permission.parseMode(mode);
        else if (typeof mode !== 'number') mode = Permission.encodeAccessMode(mode)
        if (typeof path === 'string' || Array.isArray(path)) path = WebFSUtil.join(...path);
        else if (path.owner == this.userInfo.value.uuid
            && this.userInfo.value.hasPermission(path.fullpath, Permission.encodeAccessMode({modifyOwned: true}))) return true;
        else path = path.fullpath;
        return this.userInfo.value.hasPermission(path || "", mode);
    }

    public static hasPermissionAll(files: WebFSFileMeta[], mode: AccessMode | string | AccessModeObject = 'v') {
        if (typeof mode === 'string') mode = Permission.parseMode(mode);
        else if (typeof mode !== 'number') mode = Permission.encodeAccessMode(mode)
        for (const f of files)
            if (!this.hasPermission(f, mode)) return false
        return true;
    }

    public static root = new WebFSFileMeta("/", true, undefined, "/", undefined, undefined);

    public static urlForPath(
        apiPath: string,
        path?: string,
        queryParams = {}
    ): string {
        const params = new URLSearchParams(queryParams);
        return `${config.endpoint}${WebFSUtil.encodeFilePath(WebFSUtil.join("api", apiPath, path) as string)}${
            Object.keys(queryParams).length > 0 ? "?" + params.toString() : ""
        }`;
    }

    public static async checkConnectivity(): Promise<boolean> {
        try {
            await axios.get(FSApi.urlForPath("user/self"), {
                headers: {
                    "x-access-token": config.token,
                },
            });
            return true;
        } catch (err) {
            const apiErr = ApiError.handle(err as Error)
            return [ApiErrorType.BAD_REQUEST, ApiErrorType.UNAUTHORIZED].includes(apiErr.name as ApiErrorType);
        }
    }

    public static async stat(path: string): Promise<[ApiError?, WebFSFileMeta?]> {
        try {
            const data = await axios.get(FSApi.urlForPath("file/stat", path), {
                headers: {
                    "x-access-token": config.token,
                },
            });
            const json = data.data;
            return [undefined, WebFSFileMeta.fromJson(json)];
        } catch (err) {
            return [ApiError.handle(err as Error), undefined];
        }
    }

    public static async dir(
        path: string,
        from?: number,
        to?: number
    ): Promise<[ApiError?, WebFSFileMeta[]?]> {
        try {
            const data = await axios.get(
                FSApi.urlForPath("file/dir", path, {begin: from, end: to}),
                {
                    headers: {
                        "x-access-token": config.token,
                    },
                }
            );
            const json = data.data;
            if (!json || json.files == undefined || !Array.isArray(json.files))
                return [
                    new ApiError(ApiErrorType.ERROR, "Received an invalid packet"),
                    undefined,
                ];
            return [undefined, this.decodeFileMetaList(json.files, path)];
        } catch (err) {
            return [ApiError.handle(err as Error), undefined];
        }
    }

    public static async mkdir(dir?: string): Promise<ApiError | undefined> {
        if (!dir) return new ApiError('undefined', 'undefined value passed in');
        try {
            await axios.post(FSApi.urlForPath("file/mkdir", dir), {}, {
                headers: {
                    "x-access-token": config.token,
                },
            });
        } catch (err) {
            return ApiError.handle(err as Error);
        }
    }

    public static async delete(file?: WebFSFileMeta): Promise<ApiError | undefined> {
        if (!file) return new ApiError('undefined', 'undefined value passed in');
        try {
            await axios.post(FSApi.urlForPath("file/delete", file.fullpath), {}, {
                headers: {
                    "x-access-token": config.token,
                },
            });
        } catch (err) {
            return ApiError.handle(err as Error);
        }
    }

    public static async rename(file: WebFSFileMeta, newName: string): Promise<ApiError | undefined> {
        if (!WebFSUtil.nameValid(newName)) return new ApiError('invalidparam', 'invalid filename');
        try {
            await axios.post(FSApi.urlForPath("file/rename", file.fullpath, {to: newName}), {}, {
                headers: {
                    "x-access-token": config.token,
                },
            });
        } catch (err) {
            return ApiError.handle(err as Error);
        }
    }

    private static canMove(p1: string, p2: string) {
        const s1 = WebFSUtil.splitPath(p1);
        let s2 = WebFSUtil.splitPath(p2);
        if (s1.length < s2.length) {
            s2 = s2.slice(0, s1.length);
            for (let i = 0; i < s1.length; i++) if (s1[i] != s2[i]) return true;
            return false;
        }
        return true;
    }

    public static async move(file: WebFSFileMeta, to: string): Promise<ApiError | undefined> {
        if (!WebFSUtil.pathValid(to)) return new ApiError('invalidparam', 'invalid destination path');
        if (file.path == to || file.fullpath == to) return;
        if (!FSApi.canMove(file.fullpath, to)) return;
        try {
            await axios.post(FSApi.urlForPath("file/move", file.fullpath, {to}), {}, {
                headers: {
                    "x-access-token": config.token,
                },
            });
        } catch (err) {
            return ApiError.handle(err as Error);
        }
    }

    public static async userDisplayName(uuid = ''): Promise<string | undefined> {
        if (!uuid) return undefined;
        const [err, info] = await this.fetchUserInformation(uuid);
        if (err || !info) return undefined;
        return info.name;
    }

    public static userInfoPromiseCache = new Map<string, Promise<[ApiError | undefined, UserInfo | undefined]>>([])

    public static fetchUserInformation(uuid = '', ignoreCache = false): Promise<[ApiError | undefined, UserInfo | undefined]> {
        if (!ignoreCache) {
            const promiseCacheResult = this.userInfoPromiseCache.get(uuid);
            if (promiseCacheResult) return promiseCacheResult;
        }

        if (!uuid) {
            this.fetchFileBookmarks().then(res => {
                this.bookmarks.value = res[1] ? res[1] : [];
                this.bookmarkSet.value = new Set(this.bookmarks.value.map(bookmark => bookmark.path))
            })
        }

        const p = new Promise<[ApiError | undefined, UserInfo | undefined]>((resolve) => {
            axios.get(
                FSApi.urlForPath(`${uuid ? (this.isAdminUser() ? "admin/user/info" : "user/info") : "user/self"}`, undefined, uuid ? {uuid} : undefined),
                {
                    headers: {
                        "x-access-token": config.token,
                    },
                }
            ).then(data => {
                const json = data.data;
                if (!json)
                    return resolve([
                        new ApiError(ApiErrorType.ERROR, "Received an invalid packet"),
                        undefined,
                    ]);
                const userinfo = new UserInfo(json.info.uuid, json.info.name, json.info.role, json.info.permissions ? Permission.deserialize(json.info.permissions) : [], '', json.info.enabled, json.info.login);
                return resolve([undefined, userinfo]);
            }).catch(err => {
                return resolve([ApiError.handle(err as Error), undefined]);
            })
        });
        FSApi.userInfoPromiseCache.set(uuid, p);
        return p;
    }

    public static async getSuggestions(input: string): Promise<[ApiError | undefined, UserInfo[] | undefined]> {
        try {
            const req = await axios.get(
                FSApi.urlForPath("admin/user/suggest", undefined, {input}),
                {
                    headers: {"x-access-token": config.token},
                });
            return [undefined, (req.data.suggestions || []).map((json: {
                    uuid: string;
                    name: string;
                    role: UserRole;
                    permissions: string[] | undefined;
                    login: string | undefined;
                    enabled: boolean | undefined;
                }) =>
                    new UserInfo(json.uuid, json.name, json.role, json.permissions ? Permission.deserialize(json.permissions) : [], '', true, json.login)
            )];
        } catch (err) {
            return [ApiError.handle(err as Error), undefined];
        }
    }

    public static async fetchFileHistory(): Promise<[ApiError | undefined, {
        path: string,
        date: number
    }[] | undefined]> {
        try {
            const req = await axios.get(
                FSApi.urlForPath("user/fileHistory"),
                {
                    headers: {"x-access-token": config.token}
                });
            return [undefined, (req.data as { fileHistory: { path: string, date: number }[] }).fileHistory];
        } catch (err) {
            return [ApiError.handle(err as Error), undefined];
        }
    }


    public static async addFileBookmark(addPath: string, updateCache = true): Promise<[ApiError | undefined, {successful: boolean, date: number, path: string} | undefined]> {
        try {
            const req = await axios.post(
                FSApi.urlForPath("user/bookmarks/add", addPath), {},
                {
                    headers: {"x-access-token": config.token}
                });
            const {successful, date, path} = (req.data as {successful: boolean, date: number, path: string})
            if (successful && updateCache) {
                this.bookmarks.value.splice(0, 0, {date, path})
                this.bookmarkSet.value.add(path)
            }
            return [undefined, {successful, date, path}];
        } catch (err) {
            return [ApiError.handle(err as Error), undefined];
        }
    }

    public static async deleteFileBookmark(path: string, updateCache = true): Promise<ApiError | undefined> {
        try {
            await axios.delete(
                FSApi.urlForPath("user/bookmarks/delete", path),
                {
                    headers: {"x-access-token": config.token}
                });
            if (updateCache) {
                const index = this.bookmarks.value.findIndex(bookmark => bookmark.path === path)
                if (index != -1) this.bookmarks.value.splice(index, 1);
                this.bookmarkSet.value.delete(path)
            }
        } catch (err) {
            return ApiError.handle(err as Error);
        }
    }

    public static async fetchFileBookmarks(): Promise<[ApiError | undefined, {
        path: string,
        date: number
    }[] | undefined]> {
        try {
            const req = await axios.get(
                FSApi.urlForPath("user/bookmarks"),
                {
                    headers: {"x-access-token": config.token}
                });
            return [undefined, (req.data as { bookmarks: { path: string, date: number }[] }).bookmarks];
        } catch (err) {
            return [ApiError.handle(err as Error), undefined];
        }
    }

    public static download(files: WebFSFileMeta[]): ApiError | undefined {
        if (files.length == 0) return;
        const zipDownload = files.length > 1 || files[0].isdir;
        try {
            let urllstr;
            if (zipDownload) urllstr = FSApi.urlForPath("file/zip", undefined, Object.fromEntries(files.map((file, i) => ["item" + i, file.fullpath]).concat([['token', config.token]])));
            else urllstr = FSApi.urlForPath("file/download", files[0].fullpath, {'token': config.token});
            const link = document.createElement('a');
            link.href = urllstr;
            link.setAttribute('download', zipDownload ? 'download.zip' : files[0].name);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            return ApiError.handle(err as Error);
        }
    }

    public static async downloadAsText(file: WebFSFileMeta): Promise<[ApiError?, string?]> {
        try {
            const req = await axios.get(
                FSApi.urlForPath("file/download", file.fullpath, {track: AppPreferenceService.preserveFileHistory.value}),
                {
                    headers: {"x-access-token": config.token},
                    responseType: 'text'
                });
            return [undefined, req.data];
        } catch (err) {
            return [ApiError.handle(err as Error), undefined];
        }
    }

    public static async userExists(uuidOrUsername: string): Promise<[ApiError?, boolean?, string?, string?]> {
        try {
            let query;
            if (/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(uuidOrUsername))
                query = {uuid: uuidOrUsername}
            else
                query = {username: uuidOrUsername}
            const req = await axios.get(
                FSApi.urlForPath("admin/user/exists", undefined, query),
                {
                    headers: {"x-access-token": config.token}
                });
            return [undefined, req.data.exists, req.data.uuid, req.data.name];
        } catch (err) {
            return [ApiError.handle(err as Error), undefined, undefined, undefined];
        }
    }

    public static async chown(path: string, newUUID: string): Promise<ApiError | undefined> {
        if (!WebFSUtil.pathValid(path)) return new ApiError('invalidparam', 'invalid destination path');
        try {
            await axios.post(FSApi.urlForPath("file/chown", path, {owner: newUUID}), {}, {
                headers: {
                    "x-access-token": config.token,
                },
            });
            return undefined;
        } catch (err) {
            return ApiError.handle(err as Error);
        }
    }

    public static updateLocalUserName(newUsername: string) {
        if (this.userInfo.value)
            this.userInfo.value.name = newUsername;
        this.userInfoPromiseCache.clear();
    }

    public static async changeUsername(newUsername: string): Promise<ApiError | undefined> {
        if (!this.loggedIn()) return new ApiError('error', 'not logged in');
        try {
            await axios.post(FSApi.urlForPath("user/rename"), {newname: newUsername,}, {
                headers: {
                    "x-access-token": config.token,
                },
            });
            FSApi.updateLocalUserName(newUsername);
            return undefined;
        } catch (err) {
            return ApiError.handle(err as Error);
        }
    }

    public static async changePassword(oldPassword: string, newPassword: string): Promise<ApiError | undefined> {
        if (!this.loggedIn()) return new ApiError('error', 'not logged in');
        try {
            await axios.post(FSApi.urlForPath("user/changepass"), {
                password: oldPassword,
                'new-password': newPassword
            }, {
                headers: {
                    "x-access-token": config.token,
                },
            });
            return undefined;
        } catch (err) {
            return ApiError.handle(err as Error);
        }
    }

    public static decodeFileMetaList(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any[],
        rootpath: string
    ): WebFSFileMeta[] {
        return data
            .map((elem) => WebFSFileMeta.fromJson(elem, rootpath))
            .filter((e) => e != undefined);
    }

    public static async search(path: string, query: string, matcher: SearchMatcherType = SearchMatcherType.PLAIN, limit = -1): Promise<[ApiError?, WebFSFileMeta[]?]> {
        try {
            const data = await axios.get(
                FSApi.urlForPath("file/search", path, {q: query, limit, matcher}),
                {
                    headers: {
                        "x-access-token": config.token,
                    },
                }
            );
            const json = data.data;
            if (!json || json.results == undefined || !Array.isArray(json.results))
                return [
                    new ApiError(ApiErrorType.ERROR, "Received an invalid packet"),
                    undefined,
                ];
            return [undefined, this.decodeFileMetaList(json.results, path)];
        } catch (err) {
            return [ApiError.handle(err as Error), undefined];
        }
    }

    public static async fetchLoginHistory(uuid = ''): Promise<[ApiError?, LoginAttempt[]?]> {
        try {
            const req = await axios.get(
                FSApi.urlForPath(uuid ? "admin/user/loginHistory" : "user/loginHistory", undefined, uuid ? {uuid} : {}),
                {headers: {"x-access-token": config.token},});
            return [undefined, (req.data.loginHistory)
                .map((record: { uuid: string; ip: string; successful: boolean; date: number; }) =>
                    new LoginAttempt(record.uuid, record.ip, record.successful, record.date))];
        } catch (err) {
            return [ApiError.handle(err as Error), undefined];
        }
    }

    public static async fetchLastLogin(uuid: string): Promise<[ApiError?, LoginAttempt?]> {
        try {
            const req = await axios.get(
                FSApi.urlForPath("admin/user/lastLogin", undefined, {uuid}),
                {headers: {"x-access-token": config.token},});
            return [undefined, new LoginAttempt(req.data.lastLogin.uuid, req.data.lastLogin.ip, req.data.lastLogin.successful, req.data.lastLogin.date)];
        } catch (err) {
            return [ApiError.handle(err as Error), undefined];
        }
    }

    public static async fetchAllUsers(): Promise<[ApiError?, UserInfo[]?]> {
        try {
            const req = await axios.get(
                FSApi.urlForPath("admin/user/list"),
                {headers: {"x-access-token": config.token},});
            return [undefined, (req.data.users).map((json: {
                    uuid: string;
                    name: string;
                    role: UserRole;
                    permissions: string[];
                    login: string | undefined;
                    enabled: boolean;
                }) =>
                    new UserInfo(json.uuid, json.name, json.role, json.permissions ? Permission.deserialize(json.permissions) : [], '', json.enabled, json.login)
            )];
        } catch (err) {
            return [ApiError.handle(err as Error), undefined];
        }
    }

    public static async createNewUser(): Promise<[ApiError?, UserInfo?]> {
        try {
            const req = await axios.post(
                FSApi.urlForPath("admin/user/new"), {},
                {headers: {"x-access-token": config.token},});
            const json: {
                uuid: string;
                name: string;
                role: UserRole;
                permissions: string[];
                login: string | undefined;
                enabled: boolean;
            } = req.data.info;
            return [undefined, new UserInfo(json.uuid, json.name, json.role, json.permissions ? Permission.deserialize(json.permissions) : [], '', json.enabled, json.login)];
        } catch (err) {
            return [ApiError.handle(err as Error), undefined];
        }
    }

    public static async deleteUser(uuid: string): Promise<ApiError | undefined> {
        try {
            await axios.post(
                FSApi.urlForPath("admin/user/delete", undefined, {uuid}), {},
                {headers: {"x-access-token": config.token},});
        } catch (err) {
            return ApiError.handle(err as Error);
        }
    }

    public static async modifyUser(uuid: string, key: string, val: string[]): Promise<ApiError | undefined> {
        try {
            const vals = Object.fromEntries(val.map((value, i) => ["val" + (i == 0 ? '' : i.toString()), value]).concat([['uuid', uuid], ['key', key]]))
            await axios.post(
                FSApi.urlForPath("admin/user/set", undefined, vals), {},
                {headers: {"x-access-token": config.token},});
        } catch (err) {
            return ApiError.handle(err as Error);
        }
    }

    /**
     * Aka login
     */
    public static async fetchToken(login: string, password: string): Promise<[ApiError?, string?]> {
        try {
            const data = await axios.post(FSApi.urlForPath("login"), {login, password});
            const json = data.data;
            return [undefined, json.token];
        } catch (err) {
            return [ApiError.handle(err as Error), undefined];
        }
    }

    public static async dologin(login: string, password: string): Promise<ApiError | undefined> {
        const [err, token] = await FSApi.fetchToken(login, password);
        if (err || !token) return err;
        localStorage.setItem('api-access-token', token);
        config.token = token;
        this.userInfoPromiseCache.clear();
        this.userInfo.value == undefined;
        return undefined;
    }

    public static async logoutAll(): Promise<ApiError | undefined> {
        try {
            await axios.post(FSApi.urlForPath("logout"), {}, {
                headers: {
                    "x-access-token": config.token,
                },
            });
            this.logout();
        } catch (err) {
            return ApiError.handle(err as Error);
        }
    }
}