import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9a6908c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "login-form-child"
}
const _hoisted_2 = {
  key: 1,
  class: "login-form-child"
}
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = ["type", "placeholder"]
const _hoisted_5 = { class: "flex-row flex-gap" }
const _hoisted_6 = {
  for: "showpass",
  class: "subspan"
}
const _hoisted_7 = {
  key: 0,
  class: "error-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginLogo = _resolveComponent("LoginLogo")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_Separator = _resolveComponent("Separator")!

  return (_openBlock(), _createElementBlock("form", {
    class: "login-form",
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.doLogin && _ctx.doLogin(...args)), ["prevent"]))
  }, [
    _createVNode(_component_LoginLogo),
    (_ctx.FSApi.loggedIn())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(_ctx.t("login.already-logged-in")), 1),
          _createVNode(_component_IconButton, {
            icon: "logout.svg",
            class: "login-elem",
            onClick: _ctx.doLogout
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("account.settings.logout")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_IconButton, {
            icon: "logout.svg",
            class: "login-elem",
            onClick: _ctx.doContinue
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("login.continue")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            class: "login-elem",
            type: "email",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.login) = $event)),
            placeholder: _ctx.t('login.placeholder.login') || ''
          }, null, 8, _hoisted_3), [
            [_vModelText, _ctx.login]
          ]),
          _withDirectives(_createElementVNode("input", {
            class: "login-elem",
            type: _ctx.showPassword ? 'text' : 'password',
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
            placeholder: _ctx.t('login.placeholder.password') || ''
          }, null, 8, _hoisted_4), [
            [_vModelDynamic, _ctx.password]
          ]),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              id: "showass",
              type: "checkbox",
              class: "lighter",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showPassword) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.showPassword]
            ]),
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.t("login.show-password")), 1)
          ]),
          _createVNode(_component_Separator, {
            label: _ctx.t('login.separator-label')
          }, null, 8, ["label"]),
          _createVNode(_component_IconButton, {
            class: "login-elem",
            onClick: _withModifiers(_ctx.doLogin, ["stop"]),
            icon: "login.svg"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("login.button-message")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_IconButton, {
            class: "login-elem",
            onClick: _withModifiers(_ctx.asPublic, ["stop"]),
            icon: "aspublic.svg"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("login.as-public")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.error), 1))
            : _createCommentVNode("", true)
        ]))
  ], 32))
}