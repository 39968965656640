<template>
  <div class="arrow" dir="up"></div>
</template>

<style scoped lang="scss">
.arrow {
  transition: 0.1s;
  color: var(--text-color);
  &::before {
    content: "▶";
    width: 1rem;
    height: 1rem;
  }
  &[dir="left"] {
    transform: rotate(180deg);
  }
  &[dir="right"] {
    transform: rotate(0deg);
  }
  &[dir="down"] {
    transform: rotate(90deg);
  }
  &[dir="up"] {
    transform: rotate(-90deg);
  }
  &[unused="true"] {
    opacity: 0;
  }
}
</style>
