import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-row flex-gap" }
const _hoisted_2 = { class: "span-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccountIcon = _resolveComponent("AccountIcon")!
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AccountIcon, {
      user: _ctx.user,
      large: _ctx.large
    }, null, 8, ["user", "large"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.name), 1),
    (_ctx.editable)
      ? (_openBlock(), _createBlock(_component_IconButton, {
          key: 0,
          icon: "edit.svg",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edit')))
        }))
      : _createCommentVNode("", true)
  ]))
}