export const languageIdIconMap = new Map<string,string>([
  ["git", "git"],
  ["git-commit", "git"],
  ["git-rebase", "git"],
  ["ignore", "git"],
  ["yaml", "yaml"],
  ["xml", "xml"],
  ["xquery", "xml"],
  ["xsl", "xml"],
  ["matlab", "matlab"],
  ["makefile", "settings"],
  ["toml", "settings"],
  ["ini", "settings"],
  ["properties", "settings"],
  ["shaderlab", "shaderlab"],
  ["diff", "diff"],
  ["json", "json"],
  ["jsonc", "json"],
  ["json5", "json"],
  ["blink", "blink"],
  ["java", "java"],
  ["razor", "razor"],
  ["aspnetcorerazor", "razor"],
  ["python", "python"],
  ["javascript", "javascript"],
  ["typescript", "typescript"],
  ["scala", "scala"],
  ["handlebars", "handlebars"],
  ["perl", "perl"],
  ["perl6", "perl"],
  ["haxe", "haxe"],
  ["hxml", "haxe"],
  ["puppet", "puppet"],
  ["elixir", "elixir"],
  ["livescript", "livescript"],
  ["erlang", "erlang"],
  ["twig", "twig"],
  ["julia", "julia"],
  ["elm", "elm"],
  ["purescript", "purescript"],
  ["stylus", "stylus"],
  ["nunjucks", "nunjucks"],
  ["pug", "pug"],
  ["robotframework", "robot"],
  ["sass", "sass"],
  ["scss", "sass"],
  ["less", "less"],
  ["css", "css"],
  ["testOutput", "visualstudio"],
  ["vb", "visualstudio"],
  ["ng-template", "angular"],
  ["graphql", "graphql"],
  ["solidity", "solidity"],
  ["autoit", "autoit"],
  ["haml", "haml"],
  ["yang", "yang"],
  ["terraform", "terraform"],
  ["applescript", "applescript"],
  ["cake", "cake"],
  ["cucumber", "cucumber"],
  ["nim", "nim"],
  ["nimble", "nim"],
  ["apiblueprint", "apiblueprint"],
  ["riot", "riot"],
  ["postcss", "postcss"],
  ["lang-cfml", "coldfusion"],
  ["haskell", "haskell"],
  ["dhall", "dhall"],
  ["cabal", "cabal"],
  ["nix", "nix"],
  ["ruby", "ruby"],
  ["slim", "slim"],
  ["php", "php"],
  ["hack", "hack"],
  ["javascriptreact", "react"],
  ["mjml", "mjml"],
  ["processing", "processing"],
  ["hcl", "hcl"],
  ["go", "go"],
  ["django-html", "django"],
  ["django-txt", "django"],
  ["html", "html"],
  ["gdscript", "godot"],
  ["gdresource", "godot-assets"],
  ["gdshader", "godot-assets"],
  ["viml", "vim"],
  ["prolog", "prolog"],
  ["pawn", "pawn"],
  ["reason", "reason"],
  ["reason_lisp", "reason"],
  ["sml", "sml"],
  ["tex", "tex"],
  ["doctex", "tex"],
  ["latex", "tex"],
  ["latex-expl3", "tex"],
  ["apex", "salesforce"],
  ["sas", "sas"],
  ["dockerfile", "docker"],
  ["csv", "table"],
  ["tsv", "table"],
  ["psv", "table"],
  ["csharp", "csharp"],
  ["bat", "console"],
  ["awk", "console"],
  ["shellscript", "console"],
  ["c", "c"],
  ["cpp", "cpp"],
  ["objective-c", "objective-c"],
  ["objective-cpp", "objective-cpp"],
  ["coffeescript", "coffee"],
  ["fsharp", "fsharp"],
  ["editorconfig", "editorconfig"],
  ["clojure", "clojure"],
  ["groovy", "groovy"],
  ["markdown", "markdown"],
  ["jinja", "jinja"],
  ["proto", "proto"],
  ["pip-requirements", "python-misc"],
  ["vue", "vue"],
  ["vue-postcss", "vue"],
  ["vue-html", "vue"],
  ["lua", "lua"],
  ["bibtex", "lib"],
  ["bibtex-style", "lib"],
  ["log", "log"],
  ["jupyter", "jupyter"],
  ["plaintext", "document"],
  ["pdf", "pdf"],
  ["powershell", "powershell"],
  ["jade", "pug"],
  ["r", "r"],
  ["rsweave", "r"],
  ["rust", "rust"],
  ["sql", "database"],
  ["kql", "kusto"],
  ["ssh_config", "lock"],
  ["svg", "svg"],
  ["swift", "swift"],
  ["typescriptreact", "react_ts"],
  ["search-result", "search"],
  ["mcfunction", "minecraft"],
  ["rescript", "rescript"],
  ["otne", "otne"],
  ["twee3", "twine"],
  ["twee3-harlowe-3", "twine"],
  ["twee3-chapbook-1", "twine"],
  ["twee3-sugarcube-2", "twine"],
  ["grain", "grain"],
  ["lolcode", "lolcode"],
  ["idris", "idris"],
  ["pgn", "chess"],
  ["gemini", "gemini"],
  ["text-gemini", "gemini"],
  ["v", "vlang"],
  ["wolfram", "wolframlanguage"],
  ["hlsl", "shader"],
  ["glsl", "shader"],
  ["wgsl", "shader"],
  ["tree", "tree"],
  ["svelte", "svelte"],
  ["dart", "dart"],
  ["cadence", "cadence"],
  ["stylable", "stylable"],
]);