import WebFSFileMeta from "./file-meta";

export enum FileListSortType {
    DEFAULT = 'default',
    FILE_SIZE = 'size',
    FILE_NAME = 'fileName',
    MODIFY_DATE = 'modifyDate',
    LOCATION = 'location',
    OWNER = 'owner'
}

export const comparators = new Map<FileListSortType, (a: WebFSFileMeta, b:WebFSFileMeta) => number>([
    [FileListSortType.DEFAULT, (a, b) => {
        if(a.isdir && b.isfile) return -1;
        if(a.isfile && b.isdir) return 1;
        return 0;
    }],
    [FileListSortType.FILE_SIZE, (a, b) => 
        (a.length || 0) - (b.length || 0)
    ],
    [FileListSortType.FILE_NAME, (a, b) => 
        a.name.localeCompare(b.name)
    ],
    [FileListSortType.OWNER, (a, b) => // TODO
        a.name.localeCompare(b.name)
    ],
    [FileListSortType.LOCATION, (a, b) =>
        (a.locationName || '').localeCompare(b.locationName || '')
    ],
    [FileListSortType.MODIFY_DATE, (a, b) => 
        (a.modified || 0) - (b.modified || 0)
    ]
])

export async function sortFileList(list: WebFSFileMeta[], type: FileListSortType, reverse = false): Promise<WebFSFileMeta[]> {
    if (!comparators.has(type)) type = FileListSortType.DEFAULT;
    list.sort(comparators.get(type));
    if(reverse) list.reverse();
    return list;
}