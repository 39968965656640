import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c7345c14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-display" }
const _hoisted_2 = { class: "preview-container" }
const _hoisted_3 = {
  key: 5,
  class: "flex-column fill-height flex-center"
}
const _hoisted_4 = { class: "flex-row flex-wrap flex-gap" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileHeader = _resolveComponent("FileHeader")!
  const _component_NavPlaceholder = _resolveComponent("NavPlaceholder")!
  const _component_ImageDisplay = _resolveComponent("ImageDisplay")!
  const _component_VideoDisplay = _resolveComponent("VideoDisplay")!
  const _component_AudioDisplay = _resolveComponent("AudioDisplay")!
  const _component_PdfDisplay = _resolveComponent("PdfDisplay")!
  const _component_TextDisplay = _resolveComponent("TextDisplay")!
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FileHeader, {
      file: _ctx.headerFile,
      editing: _ctx.editing,
      canEdit: _ctx.canEdit,
      saving: _ctx.saving,
      unsaved: _ctx.codeEdited,
      onReturn: _ctx.returnHandler,
      onDownload: _ctx.doDownload,
      onEditEnable: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editingOverride = true)),
      onSave: _ctx.doSave
    }, null, 8, ["file", "editing", "canEdit", "saving", "unsaved", "onReturn", "onDownload", "onSave"]),
    _createVNode(_component_NavPlaceholder),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.mediaType == 'image')
        ? (_openBlock(), _createBlock(_component_ImageDisplay, {
            key: 0,
            image: _ctx.file
          }, null, 8, ["image"]))
        : (_ctx.mediaType == 'video')
          ? (_openBlock(), _createBlock(_component_VideoDisplay, {
              key: 1,
              image: _ctx.file
            }, null, 8, ["image"]))
          : (_ctx.mediaType == 'audio')
            ? (_openBlock(), _createBlock(_component_AudioDisplay, {
                key: 2,
                file: _ctx.file
              }, null, 8, ["file"]))
            : (_ctx.mediaType == 'pdf')
              ? (_openBlock(), _createBlock(_component_PdfDisplay, {
                  key: 3,
                  file: _ctx.file
                }, null, 8, ["file"]))
              : (_ctx.mediaType == 'text')
                ? (_openBlock(), _createBlock(_component_TextDisplay, {
                    key: 4,
                    ref: "textDisplay",
                    doc: _ctx.file,
                    editing: _ctx.editing,
                    onEdit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.codeEdited = true))
                  }, null, 8, ["doc", "editing"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("h3", null, _toDisplayString(_ctx.t("no-preview")), 1),
                    _createElementVNode("div", _hoisted_4, [
                      (_ctx.file)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.t("no-preview-tip")), 1))
                        : _createCommentVNode("", true),
                      (_ctx.file)
                        ? (_openBlock(), _createBlock(_component_IconButton, {
                            key: 1,
                            icon: "file_download.svg",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.FSApi.download([_ctx.file])))
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ]))
    ])
  ]))
}