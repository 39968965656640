import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c7b989e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroller-div flex-grow" }
const _hoisted_2 = {
  key: 1,
  class: "flex-column"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DirectoryNav = _resolveComponent("DirectoryNav")!
  const _component_NavPlaceholder = _resolveComponent("NavPlaceholder")!
  const _component_PathDisplay = _resolveComponent("PathDisplay")!
  const _component_FileList = _resolveComponent("FileList")!
  const _component_FileIcon = _resolveComponent("FileIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "directory-display",
    onDrop: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dropHandler && _ctx.dropHandler(...args)), ["prevent"])),
    onDragover: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"])),
    onDragleave: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createVNode(_component_DirectoryNav, {
      defaultNav: "true",
      directory: _ctx.directory,
      onMkdir: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mkdir($event))),
      onMkfile: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mkfile($event))),
      onSearch: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ path: '/search', query: { q: $event } })))
    }, null, 8, ["directory"]),
    _createVNode(_component_NavPlaceholder),
    _createVNode(_component_PathDisplay, {
      path: _ctx.directory?.fullpath,
      "archive-path-info": _ctx.directory?.apathinf,
      "item-count": _ctx.files?.length,
      onRefresh: _cache[3] || (_cache[3] = ($event: any) => (_ctx.doRefresh(_ctx.directory)))
    }, null, 8, ["path", "archive-path-info", "item-count"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.files != undefined && (_ctx.files.length > 0 || _ctx.loading))
        ? (_openBlock(), _createBlock(_component_FileList, {
            key: 0,
            "page-mode": true,
            files: _ctx.files,
            class: "scroller",
            loading: _ctx.loading,
            displayMode: _ctx.displayMode,
            noEdit: _ctx.isInArchive,
            noPreview: _ctx.isInArchive,
            onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.doRefresh(_ctx.directory)))
          }, null, 8, ["files", "loading", "displayMode", "noEdit", "noPreview"]))
        : (_ctx.message)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.directory)
                ? (_openBlock(), _createBlock(_component_FileIcon, {
                    key: 0,
                    filename: _ctx.directory.name,
                    isdir: true,
                    isopen: true,
                    large: ""
                  }, null, 8, ["filename"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(_ctx.message), 1)
            ]))
          : _createCommentVNode("", true)
    ])
  ], 32))
}