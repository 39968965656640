import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-098f82a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmallLogo = _resolveComponent("SmallLogo")!
  const _component_AccountName = _resolveComponent("AccountName")!
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createVNode(_component_SmallLogo),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AccountName, {
        name: _ctx.FSApi.userInfo.value?.name,
        user: _ctx.FSApi.userInfo.value?.uuid
      }, null, 8, ["name", "user"])
    ]),
    _createVNode(_component_IconButton, {
      icon: "arrow_back.svg",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('return')), ["prevent"]))
    })
  ]))
}