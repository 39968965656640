"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageInfo = void 0;
class StorageInfo {
    constructor(total, used, free) {
        this.total = total;
        this.used = used;
        this.free = free;
    }
    static fromUsed(total, used) {
        return new StorageInfo(total, used, total - used);
    }
    static fromFree(total, free) {
        return new StorageInfo(total, total - free, free);
    }
    static fromJson(json) {
        return new StorageInfo(json.total, json.used, json.free);
    }
    get percentage() {
        return this.used / this.total;
    }
}
exports.StorageInfo = StorageInfo;
