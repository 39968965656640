import fileutil from "@/util/fileutil";
import {WebFSUtil} from "ksancloud-commons";
import FSApi, { config } from './file-system-api';
import { MediaType } from "@/util/media-type";
import {AppPreferenceService} from "@/util/app-preference-service";

export default class WebFSFileMeta {
  static fromPath(path: string) {
    return new WebFSFileMeta(WebFSUtil.basename(path) || '', false, undefined, WebFSUtil.subPath(path, 0, -1))
  }
  /**
   * @param json data
   * @param rootpath optional path if the data doesn't contian any
   * @returns a file metaobject
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJson(json: any, rootpath = "/"): WebFSFileMeta {
    return new WebFSFileMeta(
      json.name,
      json.isdir,
      json.owner,
      json.path || rootpath,
      json.length,
      json.modified,
      json.archivePathInfo || []
    );
  }
  
  public static readonly EMPTY = new WebFSFileMeta(
    "",
    false,
    undefined,
    undefined,
    undefined
  );

  public name: string;
  public path?: string;
  public length?: number;
  public isdir: boolean;
  public owner?: string;
  public modified?: number;
  public selected?: boolean;
  public apathinf?: number[];

  public get isfile(): boolean {
    return !this.isdir;
  }

  public get mediaType(): MediaType {
    return fileutil.getMediaTypeForFile(this.name, this.isdir);
  }

  public get locationName(): string | undefined {
    if(!this.path) return undefined;
    const loc = WebFSUtil.subPath(this.path, -1);
    return loc === '/' ? 'root' : loc;
  }

  public get fullpath(): string {
    if (this.path == undefined) return this.name;
    return WebFSUtil.join(this.path, this.name) as string;
  }

  public get routerpath(): string {
    return WebFSUtil.absolutePath(WebFSUtil.join("files", this.fullpath) as string);
  }

  public get datauri(): string {
    return FSApi.urlForPath("file/download", this.fullpath);
  }

  public get datauritoken(): string {
    return FSApi.urlForPath("file/download", this.fullpath, {token: config.token, track: AppPreferenceService.preserveFileHistory.value});
  }

  constructor(
    name: string,
    isdir: boolean,
    owner?: string,
    path?: string,
    length?: number,
    modified?: number,
    apathinf: number[] = []
  ) {
    this.name = name;
    this.path = path;
    this.length = length;
    this.isdir = isdir;
    this.owner = owner;
    this.modified = modified;
    this.apathinf = apathinf;
  }
}
