import i18next from "i18next";
import I18NextVue from "i18next-vue";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { App } from "vue";
import formatter from "./util/formatter";
import { sizeFormatter } from "./util/fileutil";

export const i18nextPromise = i18next.use(Backend).use(LanguageDetector).init({
  debug: false,
  fallbackLng: "en",
  supportedLngs: ["en","pl"],
  load: 'languageOnly'
}).then(() => {
  if(i18next.services.formatter) {
    i18next.services.formatter.add('filesize', (val) => {
      if(val == undefined) return "---";
      const r = sizeFormatter(val);
      return typeof r == "string" ? r : "---";
    });
    i18next.services.formatter.add('unixdate', (date) => {
      const r = formatter.formatDateDetail(date);
      return typeof r == "string" ? r : "---";
    });
  }
})

export default function (app: App): App {
  app.use(I18NextVue, {
    i18next,
    rerenderOn: ['added', 'loaded', 'languageChanged', 'removed'],
  });
  return app;
}

const langs = new Map<string, {nativeName: string}>([
  ['pl', { nativeName: 'Polski'}],
  ['en', { nativeName: 'English'}]
]);

export {langs};