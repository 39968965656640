
import {defineComponent} from "vue";
import {i18nextPromise} from "./i18n";
import {ModalsContainer, useVfm} from "vue-final-modal";
import FSApi from "./api/file-system-api";
import RouterButton from "./components/util/RouterButton.vue";
import ThemeManager from "./util/theme-manager";
import {useTranslation} from "i18next-vue";
import SmallLogo from "@/components/logo/SmallLogo.vue";
import AccountIcon from "@/components/account/AccountIcon.vue";
import ProgressModal from "@/components/modal/ProgressModal.vue";
import {ProgressService} from "@/util/progress-service";

export default defineComponent({
  name: "App",
  computed: {
    ProgressService() {
      return ProgressService
    }
  },
  data() {
    return {
      t: ((a: string) => a) as (a: string) => string,
      overflow: false as boolean,
    };
  },
  async setup() {
    await i18nextPromise;
    return {FSApi};
  },
  mounted() {
    const {t} = useTranslation();
    ProgressService.init(useVfm())
    this.t = t;
    ThemeManager.init();
    const mediaQuery = window.matchMedia("(max-width: 330px)");
    mediaQuery.addEventListener(
        "change",
        (e) => (this.overflow = e.matches)
    );
    this.overflow = mediaQuery.matches;
  },
  components: {ProgressModal, AccountIcon, SmallLogo, ModalsContainer, RouterButton},
});
