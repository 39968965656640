
import {useTranslation} from "i18next-vue";
import {defineComponent} from "vue";
import WebFSFileMeta from "@/api/file-meta";
import FSApi from "@/api/file-system-api";
import PathDisplay from "./PathDisplay.vue";
import FileItem from '@/components/fileitems/FileItem.vue';
import DirectoryNav from "@/components/nav/DirectoryNav.vue";
import {WebFSUtil} from "ksancloud-commons";
import {useVfm} from "vue-final-modal";
import APIUploadManager from "@/api/upload-manager";
import UploadInfo from "@/api/upload-info";
import keyNotify from '@/util/notify-util';
import {getColor} from "@/util/color-var-util";
import FileList from "./FileList.vue";
import NavPlaceholder from "../util/NavPlaceholder.vue";
import fileutil from "@/util/fileutil";
import {MediaType} from "@/util/media-type";
import {AppPreferenceService} from "@/util/app-preference-service";
import FileIcon from "@/components/util/FileIcon.vue";

export default defineComponent({
  name: "DirectoryDisplay",
  props: {
    directory: WebFSFileMeta,
  },
  data: () => {
    return {
      files: [] as WebFSFileMeta[] | undefined,
      message: "" as string | undefined,
      loading: false as boolean,
      uploadListener: undefined as ((info: UploadInfo) => void) | undefined,
    };
  },
  setup: () => {
    const {t, i18next} = useTranslation();
    const vfm = useVfm();

    return {
      t: t,
      i18next: i18next,
      vfm,
      fileItem: FileItem,
      FSApi,
      getColor
    };
  },
  mounted() {
    this.uploadListener = (info: UploadInfo) => {
      if (info) this.doRefresh(this.directory);
    };
    APIUploadManager.emitter.on("success", this.uploadListener);
  },
  unmounted() {
    if (this.uploadListener) APIUploadManager.emitter.off("success", this.uploadListener);
  },
  watch: {
    directory: {
      async handler(val?: WebFSFileMeta) {
        this.doRefresh(val);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    isInArchive() {
      return this.directory && this.directory.apathinf && this.directory.apathinf.length > 0
    },
    displayMode(): "grid" | "list" {
      return AppPreferenceService.fileListDisplayMode.value as "grid" | "list"
    }
  },
  methods: {
    async dropHandler(ev: DragEvent) {
      if (!this.directory) return;
      if (!FSApi.hasPermission(this.directory.fullpath, "vw")) return;

      let files = [] as File[];
      if (ev.dataTransfer && ev.dataTransfer.items) {
        files = [...ev.dataTransfer.items]
            .filter((item) => item.kind === "file")
            .map((item) => item.getAsFile())
            .filter((file) => file) as File[];
      } else if (ev.dataTransfer && ev.dataTransfer.files) {
        files = [...ev.dataTransfer.files].filter((item) => item);
      }

      if (files.length == 0) return;
      APIUploadManager.upload(this.directory, files);
      this.vfm.open("uploadModalId");
    },
    async doRefresh(val?: WebFSFileMeta) {
      if (!val || (val.isfile && fileutil.getMediaTypeForFile(val.name, false) !== MediaType.ARCHIVE)) return;

      this.loading = true;
      const [err, files] = await FSApi.dir(val.fullpath);
      this.loading = false;
      this.$nextTick(() => {
        if (err || (!err && !files)) {
          this.message = "Failed to load files";
          this.files = undefined;
        } else if (files) {
          this.files = files;
          this.message = files.length > 0 ? "" : this.t("directory.empty");
        }
      });
    },
    async mkdir(dir: string) {
      if (!this.directory) return;
      const error = await FSApi.mkdir(WebFSUtil.join(this.directory.fullpath, dir));
      if (error)
        keyNotify('mkdir-fail', 'error');
      else await this.doRefresh(this.directory);
      this.vfm.close("mkdirModalId");
      this.vfm.close('mkfileModalId')
    },
    async mkfile(file: string) {
      if (!this.directory) return;
      const path = WebFSUtil.join(this.directory.fullpath, file);
      if (!path) return;
      const f = WebFSFileMeta.fromPath(path);
      APIUploadManager.uploadText(f, "");
      this.vfm.close("mkfileModalId");
      await this.doRefresh(this.directory);
    },
  },
  components: {
    FileIcon,
    PathDisplay,
    DirectoryNav,
    FileList,
    NavPlaceholder
  },
});
