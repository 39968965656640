import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "list-file-item",
  ref: "body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileIcon = _resolveComponent("FileIcon")!
  const _component_HighlightedSpan = _resolveComponent("HighlightedSpan")!
  const _component_UserDisplayname = _resolveComponent("UserDisplayname")!
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_component_FileIcon, {
      filename: _ctx.file?.name || '',
      isdir: _ctx.file.isdir
    }, null, 8, ["filename", "isdir"]),
    _createVNode(_component_HighlightedSpan, {
      class: "name-span clickable",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('bodyclick', $event)), ["prevent"])),
      text: _ctx.file ? _ctx.file.name : '---',
      pattern: _ctx.highlight
    }, null, 8, ["text", "pattern"]),
    (_ctx.showPath)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('pathclick')), ["prevent"])),
          class: "path-span"
        }, _toDisplayString(_ctx.file ? _ctx.file.locationName : "---"), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_UserDisplayname, {
      uuid: _ctx.file ? _ctx.file.owner : undefined,
      class: "owner-span detailspan clickable",
      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('chown')), ["stop","prevent"]))
    }, null, 8, ["uuid"]),
    _createElementVNode("span", {
      class: _normalizeClass(["size-span detailspan", { 'span-empty': _ctx.fileSize == undefined }])
    }, _toDisplayString(_ctx.fileSize || "---"), 3),
    _createElementVNode("span", {
      class: _normalizeClass(["modified-span detailspan", { 'span-empty': !_ctx.modifiedDate }])
    }, _toDisplayString(_ctx.modifiedDate || "---"), 3),
    _createVNode(_component_IconButton, {
      ref: "contextElem",
      class: "option-button",
      icon: "menu.svg",
      transparent: "",
      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('buttoncontextmenu', $event)), ["stop","prevent"]))
    }, null, 512)
  ], 512))
}