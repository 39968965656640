
import {useTranslation} from "i18next-vue";
import FSApi from "@/api/file-system-api";
import AccountInfo from "@/components/account/AccountInfo.vue";
import AccountLogins from "@/components/account/AccountLogins.vue";
import AccountSettings from "@/components/account/AccountSettings.vue";
import LoginPrompt from "@/components/account/LoginPrompt.vue";
import AccountNav from "@/components/nav/AccountNav.vue";
import Separator from "@/components/util/Separator.vue";
import {defineComponent} from "vue";
import ThemeSwitcher from "@/components/util/selector/ThemeSwitcher.vue";
import LanguageSwitcher from "@/components/util/selector/LanguageSwitcher.vue";
import NavPlaceholder from "@/components/util/NavPlaceholder.vue";
import IconButton from "@/components/util/IconButton.vue";
import DateTypeSwitcher from "@/components/util/selector/DateTypeSwitcher.vue";
import {AppPreferenceService} from "@/util/app-preference-service";

export default defineComponent({
  name: "AccountView",
  computed: {
    AppPreferenceService() {
      return AppPreferenceService
    }
  },
  data() {
    return {
      loginHistoryShown: false as boolean,
    }
  },
  components: {
    DateTypeSwitcher,
    IconButton,
    AccountNav,
    LoginPrompt,
    AccountInfo,
    AccountSettings,
    AccountLogins,
    Separator,
    ThemeSwitcher,
    LanguageSwitcher,
    NavPlaceholder
},
  setup() {
    const { t } = useTranslation();
    return { FSApi, t };
  },
});
