
import { defineComponent } from "vue";
import IconButton from "../util/IconButton.vue";
import SmallLogo from "../logo/SmallLogo.vue";
import AccountIcon from "../account/AccountIcon.vue";
import FSApi from "@/api/file-system-api";
import CollapsableTextfield from "../util/CollapsableTextField.vue";
import { useTranslation } from "i18next-vue";
import { WebFSUtil } from "ksancloud-commons";
import { useVfm } from "vue-final-modal";
import FilenameInput from "../util/FilenameInput.vue";
import ClosableModal from "../modal/ClosableModal.vue";
import UploadManager from "../util/UploadManager.vue";
import {AppPreferenceService} from "@/util/app-preference-service";
import SearchSettings from "@/components/account/SearchSettings.vue";

export default defineComponent({
  name: "DirectoryNav",
  components: {
    SearchSettings,
    IconButton,
    SmallLogo,
    AccountIcon,
    CollapsableTextfield,
    FilenameInput,
    ClosableModal,
    UploadManager,
  },
  data() {
    return {
      showGrid: false,
    };
  },
  emits: ["search", "mkdir", "home", "forward", "mkfile"],
  props: ["defaultNav", "directory"],
  setup() {
    const { t } = useTranslation();
    const vfm = useVfm();
    return { FSApi, t, vfm };
  },
  mounted() {
    this.showGrid = AppPreferenceService.fileListDisplayMode.value == "grid";
  },
  methods: {
    navForward() {
      if (!this.$props.defaultNav) return;
      this.$router.forward();
    },
    navBack() {
      if (!this.$props.defaultNav || !this.displayedPath) return;
      const parent = WebFSUtil.subPath(this.displayedPath, 0, -1);
      this.$router.push(WebFSUtil.absolutePath(WebFSUtil.encodeFilePath(WebFSUtil.join("files", parent) || "")));
    },
    changeDisplayMode() {
      this.showGrid = !this.showGrid;
      AppPreferenceService.fileListDisplayMode.value = this.showGrid ? "grid" : "list"
    },
    searchClickHandler(event: {buttonName: string, modalId: string}) {
      if (event.buttonName === 'settings') {
        this.vfm.open(event.modalId)
      }
    }
  },
  computed: {
    isInArchive() {
      return this.directory && this.directory.apathinf && this.directory.apathinf.length > 0
    },
    returnDisabled() {
      return this.$route.params.filepath == "";
    },
    forwardDisabled() {
      return false;
    },
    displayedPath() {
      return WebFSUtil.join(...this.$route.params.filepath);
    },
  },
});
