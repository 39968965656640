import {ref} from "vue";
import {Vfm} from "vue-final-modal";

export class ProgressService {
    public static readonly progressModalId = "progressModalId";

    public static readonly progressMax = ref<number>(100);
    public static readonly progressValue = ref<number>(0);
    public static readonly progressMessage = ref<string>("");

    private static vfm: Vfm;
    private static cancelAction: () => void;

    public static init(vfm: Vfm) {
        this.vfm = vfm;
    }

    public static doCancel() {
        if (ProgressService.cancelAction)
            ProgressService.cancelAction()
        ProgressService.vfm.close(ProgressService.progressModalId)
    }

    public static start(max: number, message: string, cancelAction: () => void) {
        this.progressMax.value = max;
        this.progressValue.value = 0;
        this.progressMessage.value = message;
        this.cancelAction = cancelAction;
        this.vfm.open(this.progressModalId);
    }

    public static update(val: number) {
        this.progressValue.value = val
    }

    public static finish() {
        this.vfm.close(this.progressModalId);
    }

    public static async processAsync<T>(message: string, items: T[], processor: (elem: T, index: number) => Promise<void>) {
        let run = true;
        ProgressService.start(items.length, message, () => {
            run = false;
        });

        const concurrencyLimit = 1;
        const results: Promise<void>[] = [];
        let i = 0;
        let processed = 0;

        async function processNextItem(){
            while (items.length > i && run) {
                await processor(items[i], i++)
                ProgressService.update(++processed);
            }
        }

        const concurrencyPromises: Promise<void>[] = [];
        for (let i = 0; i < concurrencyLimit && run; i++) {
            concurrencyPromises.push(processNextItem());
        }

        await Promise.all(concurrencyPromises);
        await Promise.all(results);

        ProgressService.finish()
    }

}