export function getColor(name: string): string {
    return getComputedStyle(document.documentElement)
        .getPropertyValue(name);
}

export function createChartColors(n: number): string[] {
    const res = [] as string[];
    const piece = 360 / n;
    const offset = 200;
    for(let i = 0; i < n; i++) {
        res.push(`hsl(${piece * i + offset}, ${100}%, ${60}%)`)
    }
    return res;
}

export const graphPeriods = [60, 5 * 60, 10 * 60]