export const programmingLaunguageExtensionMap = new Map<string, string>([
    ["1", "groff"],
    ["2", "groff"],
    ["3", "groff"],
    ["4", "groff"],
    ["5", "groff"],
    ["6", "groff"],
    ["7", "groff"],
    ["8", "groff"],
    ["9", "groff"],
    ["abap", "abap"],
    ["asc", "public key"],
    ["ash", "ags script"],
    ["ampl", "ampl"],
    ["mod", "xml"],
    ["g4", "antlr"],
    ["apib", "api blueprint"],
    ["apl", "apl"],
    ["dyalog", "apl"],
    ["asp", "asp"],
    ["asax", "asp"],
    ["ascx", "asp"],
    ["ashx", "asp"],
    ["asmx", "asp"],
    ["aspx", "asp"],
    ["axd", "asp"],
    ["dats", "ats"],
    ["hats", "ats"],
    ["sats", "ats"],
    ["as", "actionscript"],
    ["adb", "ada"],
    ["ada", "ada"],
    ["ads", "ada"],
    ["agda", "agda"],
    ["als", "alloy"],
    ["apacheconf", "apacheconf"],
    ["vhost", "nginx"],
    ["cls", "visual basic"],
    ["applescript", "applescript"],
    ["scpt", "applescript"],
    ["arc", "arc"],
    ["ino", "arduino"],
    ["asciidoc", "asciidoc"],
    ["adoc", "asciidoc"],
    ["aj", "aspectj"],
    ["asm", "assembly"],
    ["a51", "assembly"],
    ["inc", "sourcepawn"],
    ["nasm", "assembly"],
    ["aug", "augeas"],
    ["ahk", "autohotkey"],
    ["ahkl", "autohotkey"],
    ["au3", "autoit"],
    ["awk", "awk"],
    ["auk", "awk"],
    ["gawk", "awk"],
    ["mawk", "awk"],
    ["nawk", "awk"],
    ["bat", "batchfile"],
    ["cmd", "batchfile"],
    ["befunge", "befunge"],
    ["bison", "bison"],
    ["bb", "blitzbasic"],
    ["decls", "blitzbasic"],
    ["bmx", "blitzmax"],
    ["bsv", "bluespec"],
    ["boo", "boo"],
    ["b", "limbo"],
    ["bf", "hyphy"],
    ["brs", "brightscript"],
    ["bro", "bro"],
    ["c", "c"],
    ["cats", "c"],
    ["h", "objective-c"],
    ["idc", "c"],
    ["w", "c"],
    ["cs", "c#"],
    ["cake", "coffeescript"],
    ["cshtml", "c#"],
    ["csx", "c#"],
    ["cpp", "c++"],
    ["c++", "c++"],
    ["cc", "c++"],
    ["cp", "component pascal"],
    ["cxx", "c++"],
    ["h++", "c++"],
    ["hh", "hack"],
    ["hpp", "c++"],
    ["hxx", "c++"],
    ["inl", "c++"],
    ["ipp", "c++"],
    ["tcc", "c++"],
    ["tpp", "c++"],
    ["chs", "c2hs haskell"],
    ["clp", "clips"],
    ["cmake", "cmake"],
    ["cmake.in", "cmake"],
    ["cob", "cobol"],
    ["cbl", "cobol"],
    ["ccp", "cobol"],
    ["cobol", "cobol"],
    ["cpy", "cobol"],
    ["css", "css"],
    ["csv", "csv"],
    ["capnp", "cap'n proto"],
    ["mss", "cartocss"],
    ["ceylon", "ceylon"],
    ["chpl", "chapel"],
    ["ch", "xbase"],
    ["ck", "chuck"],
    ["cirru", "cirru"],
    ["clw", "clarion"],
    ["icl", "clean"],
    ["dcl", "clean"],
    ["click", "click"],
    ["clj", "clojure"],
    ["boot", "clojure"],
    ["cl2", "clojure"],
    ["cljc", "clojure"],
    ["cljs", "clojure"],
    ["cljs.hl", "clojure"],
    ["cljscm", "clojure"],
    ["cljx", "clojure"],
    ["hic", "clojure"],
    ["coffee", "coffeescript"],
    ["_coffee", "coffeescript"],
    ["cjsx", "coffeescript"],
    ["cson", "coffeescript"],
    ["iced", "coffeescript"],
    ["cfm", "coldfusion"],
    ["cfml", "coldfusion"],
    ["cfc", "coldfusion cfc"],
    ["lisp", "newlisp"],
    ["asd", "common lisp"],
    ["cl", "opencl"],
    ["l", "picolisp"],
    ["lsp", "newlisp"],
    ["ny", "common lisp"],
    ["podsl", "common lisp"],
    ["sexp", "common lisp"],
    ["cps", "component pascal"],
    ["coq", "coq"],
    ["v", "verilog"],
    ["cppobjdump", "cpp-objdump"],
    ["c++-objdump", "cpp-objdump"],
    ["c++objdump", "cpp-objdump"],
    ["cpp-objdump", "cpp-objdump"],
    ["cxx-objdump", "cpp-objdump"],
    ["creole", "creole"],
    ["cr", "crystal"],
    ["feature", "cucumber"],
    ["cu", "cuda"],
    ["cuh", "cuda"],
    ["cy", "cycript"],
    ["pyx", "cython"],
    ["pxd", "cython"],
    ["pxi", "cython"],
    ["d", "makefile"],
    ["di", "d"],
    ["d-objdump", "d-objdump"],
    ["com", "digital command language"],
    ["dm", "dm"],
    ["zone", "dns zone"],
    ["arpa", "dns zone"],
    ["darcspatch", "darcs patch"],
    ["dpatch", "darcs patch"],
    ["dart", "dart"],
    ["diff", "diff"],
    ["patch", "diff"],
    ["dockerfile", "dockerfile"],
    ["djs", "dogescript"],
    ["dylan", "dylan"],
    ["dyl", "dylan"],
    ["intr", "dylan"],
    ["lid", "dylan"],
    ["E", "e"],
    ["ecl", "eclipse"],
    ["eclxml", "ecl"],
    ["sch", "kicad"],
    ["brd", "kicad"],
    ["epj", "ecere projects"],
    ["e", "eiffel"],
    ["ex", "elixir"],
    ["exs", "elixir"],
    ["elm", "elm"],
    ["el", "emacs lisp"],
    ["emacs", "emacs lisp"],
    ["emacs.desktop", "emacs lisp"],
    ["em", "emberscript"],
    ["emberscript", "emberscript"],
    ["erl", "erlang"],
    ["es", "javascript"],
    ["escript", "erlang"],
    ["hrl", "erlang"],
    ["xrl", "erlang"],
    ["yrl", "erlang"],
    ["fs", "glsl"],
    ["fsi", "f#"],
    ["fsx", "f#"],
    ["fx", "hlsl"],
    ["flux", "flux"],
    ["f90", "fortran"],
    ["f", "forth"],
    ["f03", "fortran"],
    ["f08", "fortran"],
    ["f77", "fortran"],
    ["f95", "fortran"],
    ["for", "forth"],
    ["fpp", "fortran"],
    ["factor", "factor"],
    ["fy", "fancy"],
    ["fancypack", "fancy"],
    ["fan", "fantom"],
    ["eam.fs", "formatted"],
    ["fth", "forth"],
    ["4th", "forth"],
    ["forth", "forth"],
    ["fr", "text"],
    ["frt", "forth"],
    ["ftl", "freemarker"],
    ["g", "gap"],
    ["gco", "g-code"],
    ["gcode", "g-code"],
    ["gms", "gams"],
    ["gap", "gap"],
    ["gd", "gdscript"],
    ["gi", "gap"],
    ["tst", "scilab"],
    ["s", "gas"],
    ["ms", "maxscript"],
    ["glsl", "glsl"],
    ["fp", "glsl"],
    ["frag", "javascript"],
    ["frg", "glsl"],
    ["fsh", "glsl"],
    ["fshader", "glsl"],
    ["geo", "glsl"],
    ["geom", "glsl"],
    ["glslv", "glsl"],
    ["gshader", "glsl"],
    ["shader", "glsl"],
    ["vert", "glsl"],
    ["vrx", "glsl"],
    ["vsh", "glsl"],
    ["vshader", "glsl"],
    ["gml", "xml"],
    ["kid", "genshi"],
    ["ebuild", "gentoo ebuild"],
    ["eclass", "gentoo eclass"],
    ["po", "gettext catalog"],
    ["pot", "gettext catalog"],
    ["glf", "glyph"],
    ["gp", "gnuplot"],
    ["gnu", "gnuplot"],
    ["gnuplot", "gnuplot"],
    ["plot", "gnuplot"],
    ["plt", "gnuplot"],
    ["go", "go"],
    ["golo", "golo"],
    ["gs", "javascript"],
    ["gst", "gosu"],
    ["gsx", "gosu"],
    ["vark", "gosu"],
    ["grace", "grace"],
    ["gradle", "gradle"],
    ["gf", "grammatical framework"],
    ["graphql", "graphql"],
    ["dot", "graphviz (dot)"],
    ["gv", "graphviz (dot)"],
    ["man", "groff"],
    ["1in", "groff"],
    ["1m", "groff"],
    ["1x", "groff"],
    ["3in", "groff"],
    ["3m", "groff"],
    ["3qt", "groff"],
    ["3x", "groff"],
    ["me", "groff"],
    ["n", "nemerle"],
    ["rno", "groff"],
    ["roff", "groff"],
    ["groovy", "groovy"],
    ["grt", "groovy"],
    ["gtpl", "groovy"],
    ["gvy", "groovy"],
    ["gsp", "groovy server pages"],
    ["hcl", "hcl"],
    ["tf", "hcl"],
    ["hlsl", "hlsl"],
    ["fxh", "hlsl"],
    ["hlsli", "hlsl"],
    ["html", "html"],
    ["htm", "html"],
    ["html.hl", "html"],
    ["st", "smalltalk"],
    ["xht", "html"],
    ["xhtml", "html"],
    ["mustache", "html+django"],
    ["jinja", "html+django"],
    ["eex", "html+eex"],
    ["erb", "html+erb"],
    ["erb.deface", "html+erb"],
    ["phtml", "html+php"],
    ["http", "http"],
    ["php", "php"],
    ["haml", "haml"],
    ["haml.deface", "haml"],
    ["handlebars", "handlebars"],
    ["hbs", "handlebars"],
    ["hb", "harbour"],
    ["hs", "haskell"],
    ["hsc", "haskell"],
    ["hx", "haxe"],
    ["hxsl", "haxe"],
    ["hy", "hy"],
    ["pro", "qmake"],
    ["dlm", "idl"],
    ["ipf", "igor pro"],
    ["ini", "ini"],
    ["cfg", "ini"],
    ["prefs", "ini"],
    ["properties", "ini"],
    ["irclog", "irc log"],
    ["weechatlog", "irc log"],
    ["idr", "idris"],
    ["lidr", "idris"],
    ["ni", "inform 7"],
    ["i7x", "inform 7"],
    ["iss", "inno setup"],
    ["io", "io"],
    ["ik", "ioke"],
    ["thy", "isabelle"],
    ["ijs", "j"],
    ["flex", "jflex"],
    ["jflex", "jflex"],
    ["json", "json"],
    ["geojson", "json"],
    ["lock", "json"],
    ["topojson", "json"],
    ["json5", "json5"],
    ["jsonld", "jsonld"],
    ["jq", "jsoniq"],
    ["jsx", "jsx"],
    ["jade", "jade"],
    ["j", "objective-j"],
    ["java", "java"],
    ["jsp", "java server pages"],
    ["js", "javascript"],
    ["_js", "javascript"],
    ["bones", "javascript"],
    ["es6", "javascript"],
    ["jake", "javascript"],
    ["jsb", "javascript"],
    ["jscad", "javascript"],
    ["jsfl", "javascript"],
    ["jsm", "javascript"],
    ["jss", "javascript"],
    ["njs", "javascript"],
    ["pac", "javascript"],
    ["sjs", "javascript"],
    ["ssjs", "javascript"],
    ["sublime-build", "javascript"],
    ["sublime-commands", "javascript"],
    ["sublime-completions", "javascript"],
    ["sublime-keymap", "javascript"],
    ["sublime-macro", "javascript"],
    ["sublime-menu", "javascript"],
    ["sublime-mousemap", "javascript"],
    ["sublime-project", "javascript"],
    ["sublime-settings", "javascript"],
    ["sublime-theme", "javascript"],
    ["sublime-workspace", "javascript"],
    ["sublime_metrics", "javascript"],
    ["sublime_session", "javascript"],
    ["xsjs", "javascript"],
    ["xsjslib", "javascript"],
    ["jl", "julia"],
    ["ipynb", "jupyter notebook"],
    ["krl", "krl"],
    ["kicad_pcb", "kicad"],
    ["kit", "kit"],
    ["kt", "kotlin"],
    ["ktm", "kotlin"],
    ["kts", "kotlin"],
    ["lfe", "lfe"],
    ["ll", "llvm"],
    ["lol", "lolcode"],
    ["lsl", "lsl"],
    ["lslp", "lsl"],
    ["lvproj", "labview"],
    ["lasso", "lasso"],
    ["las", "lasso"],
    ["lasso8", "lasso"],
    ["lasso9", "lasso"],
    ["ldml", "lasso"],
    ["latte", "latte"],
    ["lean", "lean"],
    ["hlean", "lean"],
    ["less", "less"],
    ["lex", "lex"],
    ["ly", "lilypond"],
    ["ily", "lilypond"],
    ["m", "objective-c"],
    ["ld", "linker script"],
    ["lds", "linker script"],
    ["liquid", "liquid"],
    ["lagda", "literate agda"],
    ["litcoffee", "literate coffeescript"],
    ["lhs", "literate haskell"],
    ["ls", "loomscript"],
    ["_ls", "livescript"],
    ["xm", "logos"],
    ["x", "logos"],
    ["xi", "logos"],
    ["lgt", "logtalk"],
    ["logtalk", "logtalk"],
    ["lookml", "lookml"],
    ["lua", "lua"],
    ["fcgi", "shell"],
    ["nse", "lua"],
    ["pd_lua", "lua"],
    ["rbxs", "lua"],
    ["wlua", "lua"],
    ["mumps", "m"],
    ["m4", "m4sugar"],
    ["mcr", "maxscript"],
    ["mtml", "mtml"],
    ["muf", "muf"],
    ["mak", "makefile"],
    ["mk", "makefile"],
    ["mkfile", "makefile"],
    ["mako", "mako"],
    ["mao", "mako"],
    ["md", "markdown"],
    ["markdown", "markdown"],
    ["mkd", "markdown"],
    ["mkdn", "markdown"],
    ["mkdown", "markdown"],
    ["ron", "markdown"],
    ["mask", "mask"],
    ["mathematica", "mathematica"],
    ["cdf", "mathematica"],
    ["ma", "mathematica"],
    ["mt", "mathematica"],
    ["nb", "text"],
    ["nbp", "mathematica"],
    ["wl", "mathematica"],
    ["wlt", "mathematica"],
    ["matlab", "matlab"],
    ["maxpat", "max"],
    ["maxhelp", "max"],
    ["maxproj", "max"],
    ["mxt", "max"],
    ["pat", "max"],
    ["mediawiki", "mediawiki"],
    ["wiki", "mediawiki"],
    ["moo", "moocode"],
    ["metal", "metal"],
    ["minid", "minid"],
    ["druby", "mirah"],
    ["duby", "mirah"],
    ["mir", "mirah"],
    ["mirah", "mirah"],
    ["mo", "modelica"],
    ["mms", "module management system"],
    ["mmk", "module management system"],
    ["monkey", "monkey"],
    ["moon", "moonscript"],
    ["myt", "myghty"],
    ["ncl", "text"],
    ["nl", "newlisp"],
    ["nsi", "nsis"],
    ["nsh", "nsis"],
    ["axs", "netlinx"],
    ["axi", "netlinx"],
    ["axs.erb", "netlinx+erb"],
    ["axi.erb", "netlinx+erb"],
    ["nlogo", "netlogo"],
    ["nginxconf", "nginx"],
    ["nim", "nimrod"],
    ["nimrod", "nimrod"],
    ["ninja", "ninja"],
    ["nit", "nit"],
    ["nix", "nix"],
    ["nu", "nu"],
    ["numpy", "numpy"],
    ["numpyw", "numpy"],
    ["numsc", "numpy"],
    ["ml", "ocaml"],
    ["eliom", "ocaml"],
    ["eliomi", "ocaml"],
    ["ml4", "ocaml"],
    ["mli", "ocaml"],
    ["mll", "ocaml"],
    ["mly", "ocaml"],
    ["objdump", "objdump"],
    ["mm", "xml"],
    ["sj", "objective-j"],
    ["omgrofl", "omgrofl"],
    ["opa", "opa"],
    ["opal", "opal"],
    ["opencl", "opencl"],
    ["p", "openedge abl"],
    ["scad", "openscad"],
    ["org", "org"],
    ["ox", "ox"],
    ["oxh", "ox"],
    ["oxo", "ox"],
    ["oxygene", "oxygene"],
    ["oz", "oz"],
    ["pwn", "pawn"],
    ["aw", "php"],
    ["ctp", "php"],
    ["php3", "php"],
    ["php4", "php"],
    ["php5", "php"],
    ["phps", "php"],
    ["phpt", "php"],
    ["pls", "plsql"],
    ["pck", "plsql"],
    ["pkb", "plsql"],
    ["pks", "plsql"],
    ["plb", "plsql"],
    ["plsql", "plsql"],
    ["sql", "sqlpl"],
    ["pov", "pov-ray sdl"],
    ["pan", "pan"],
    ["psc", "papyrus"],
    ["parrot", "parrot"],
    ["pasm", "parrot assembly"],
    ["pir", "parrot internal representation"],
    ["pas", "pascal"],
    ["dfm", "pascal"],
    ["dpr", "pascal"],
    ["lpr", "pascal"],
    ["pp", "puppet"],
    ["pl", "prolog"],
    ["al", "perl"],
    ["cgi", "shell"],
    ["perl", "perl"],
    ["ph", "perl"],
    ["plx", "perl"],
    ["pm", "perl6"],
    ["pod", "pod"],
    ["psgi", "perl"],
    ["t", "turing"],
    ["6pl", "perl6"],
    ["6pm", "perl6"],
    ["nqp", "perl6"],
    ["p6", "perl6"],
    ["p6l", "perl6"],
    ["p6m", "perl6"],
    ["pl6", "perl6"],
    ["pm6", "perl6"],
    ["pkl", "pickle"],
    ["pig", "piglatin"],
    ["pike", "pike"],
    ["pmod", "pike"],
    ["pogo", "pogoscript"],
    ["pony", "pony"],
    ["ps", "postscript"],
    ["eps", "postscript"],
    ["ps1", "powershell"],
    ["psd1", "powershell"],
    ["psm1", "powershell"],
    ["pde", "processing"],
    ["prolog", "prolog"],
    ["yap", "prolog"],
    ["spin", "propeller spin"],
    ["proto", "protocol buffer"],
    ["pub", "public key"],
    ["pd", "pure data"],
    ["pb", "purebasic"],
    ["pbi", "purebasic"],
    ["purs", "purescript"],
    ["py", "python"],
    ["bzl", "python"],
    ["gyp", "python"],
    ["lmi", "python"],
    ["pyde", "python"],
    ["pyp", "python"],
    ["pyt", "python"],
    ["pyw", "python"],
    ["rpy", "ren'py"],
    ["tac", "python"],
    ["wsgi", "python"],
    ["xpy", "python"],
    ["pytb", "python traceback"],
    ["qml", "qml"],
    ["qbs", "qml"],
    ["pri", "qmake"],
    ["r", "rebol"],
    ["rd", "r"],
    ["rsx", "r"],
    ["raml", "raml"],
    ["rdoc", "rdoc"],
    ["rbbas", "realbasic"],
    ["rbfrm", "realbasic"],
    ["rbmnu", "realbasic"],
    ["rbres", "realbasic"],
    ["rbtbar", "realbasic"],
    ["rbuistate", "realbasic"],
    ["rhtml", "rhtml"],
    ["rmd", "rmarkdown"],
    ["rkt", "racket"],
    ["rktd", "racket"],
    ["rktl", "racket"],
    ["scrbl", "racket"],
    ["rl", "ragel in ruby host"],
    ["raw", "raw token data"],
    ["reb", "rebol"],
    ["r2", "rebol"],
    ["r3", "rebol"],
    ["rebol", "rebol"],
    ["red", "red"],
    ["reds", "red"],
    ["cw", "redcode"],
    ["rs", "rust"],
    ["rsh", "renderscript"],
    ["robot", "robotframework"],
    ["rg", "rouge"],
    ["rb", "ruby"],
    ["builder", "ruby"],
    ["gemspec", "ruby"],
    ["god", "ruby"],
    ["irbrc", "ruby"],
    ["jbuilder", "ruby"],
    ["mspec", "ruby"],
    ["pluginspec", "xml"],
    ["podspec", "ruby"],
    ["rabl", "ruby"],
    ["rake", "ruby"],
    ["rbuild", "ruby"],
    ["rbw", "ruby"],
    ["rbx", "ruby"],
    ["ru", "ruby"],
    ["ruby", "ruby"],
    ["thor", "ruby"],
    ["watchr", "ruby"],
    ["rs.in", "rust"],
    ["sas", "sas"],
    ["scss", "scss"],
    ["smt2", "smt"],
    ["smt", "smt"],
    ["sparql", "sparql"],
    ["rq", "sparql"],
    ["sqf", "sqf"],
    ["hqf", "sqf"],
    ["cql", "sql"],
    ["ddl", "sql"],
    ["prc", "sql"],
    ["tab", "sql"],
    ["udf", "sql"],
    ["viw", "sql"],
    ["db2", "sqlpl"],
    ["ston", "ston"],
    ["svg", "svg"],
    ["sage", "sage"],
    ["sagews", "sage"],
    ["sls", "scheme"],
    ["sass", "sass"],
    ["scala", "scala"],
    ["sbt", "scala"],
    ["sc", "supercollider"],
    ["scaml", "scaml"],
    ["scm", "scheme"],
    ["sld", "scheme"],
    ["sps", "scheme"],
    ["ss", "scheme"],
    ["sci", "scilab"],
    ["sce", "scilab"],
    ["self", "self"],
    ["sh", "shell"],
    ["bash", "shell"],
    ["bats", "shell"],
    ["command", "shell"],
    ["ksh", "shell"],
    ["sh.in", "shell"],
    ["tmux", "shell"],
    ["tool", "shell"],
    ["zsh", "shell"],
    ["sh-session", "shellsession"],
    ["shen", "shen"],
    ["sl", "slash"],
    ["slim", "slim"],
    ["smali", "smali"],
    ["tpl", "smarty"],
    ["twojastara", "twj"],
    ["sp", "sourcepawn"],
    ["sma", "sourcepawn"],
    ["nut", "squirrel"],
    ["stan", "stan"],
    ["ML", "standard ml"],
    ["fun", "standard ml"],
    ["sig", "standard ml"],
    ["sml", "standard ml"],
    ["do", "stata"],
    ["ado", "stata"],
    ["doh", "stata"],
    ["ihlp", "stata"],
    ["mata", "stata"],
    ["matah", "stata"],
    ["sthlp", "stata"],
    ["styl", "stylus"],
    ["scd", "supercollider"],
    ["swift", "swift"],
    ["sv", "systemverilog"],
    ["svh", "systemverilog"],
    ["vh", "systemverilog"],
    ["toml", "toml"],
    ["txl", "txl"],
    ["tcl", "tcl"],
    ["adp", "tcl"],
    ["tm", "tcl"],
    ["tcsh", "tcsh"],
    ["csh", "tcsh"],
    ["tex", "tex"],
    ["aux", "tex"],
    ["bbx", "tex"],
    ["bib", "tex"],
    ["cbx", "tex"],
    ["dtx", "tex"],
    ["ins", "tex"],
    ["lbx", "tex"],
    ["ltx", "tex"],
    ["mkii", "tex"],
    ["mkiv", "tex"],
    ["mkvi", "tex"],
    ["sty", "tex"],
    ["toc", "tex"],
    ["tea", "tea"],
    ["txt", "text"],
    ["no", "text"],
    ["textile", "textile"],
    ["thrift", "thrift"],
    ["tu", "turing"],
    ["ttl", "turtle"],
    ["twig", "twig"],
    ["ts", "typescript"],
    ["tsx", "xml"],
    ["upc", "unified parallel c"],
    ["anim", "unity3d asset"],
    ["asset", "unity3d asset"],
    ["mat", "unity3d asset"],
    ["meta", "unity3d asset"],
    ["prefab", "unity3d asset"],
    ["unity", "unity3d asset"],
    ["uno", "uno"],
    ["uc", "unrealscript"],
    ["ur", "urweb"],
    ["urs", "urweb"],
    ["vcl", "vcl"],
    ["vhdl", "vhdl"],
    ["vhd", "vhdl"],
    ["vhf", "vhdl"],
    ["vhi", "vhdl"],
    ["vho", "vhdl"],
    ["vhs", "vhdl"],
    ["vht", "vhdl"],
    ["vhw", "vhdl"],
    ["vala", "vala"],
    ["vapi", "vala"],
    ["veo", "verilog"],
    ["vim", "viml"],
    ["vb", "visual basic"],
    ["bas", "visual basic"],
    ["frm", "visual basic"],
    ["frx", "visual basic"],
    ["vba", "visual basic"],
    ["vbhtml", "visual basic"],
    ["vbs", "visual basic"],
    ["volt", "volt"],
    ["vue", "vue"],
    ["owl", "web ontology language"],
    ["webidl", "webidl"],
    ["x10", "x10"],
    ["xc", "xc"],
    ["xml", "xml"],
    ["ant", "xml"],
    ["axml", "xml"],
    ["ccxml", "xml"],
    ["clixml", "xml"],
    ["cproject", "xml"],
    ["csl", "xml"],
    ["csproj", "xml"],
    ["ct", "xml"],
    ["dita", "xml"],
    ["ditamap", "xml"],
    ["ditaval", "xml"],
    ["dll.config", "xml"],
    ["dotsettings", "xml"],
    ["filters", "xml"],
    ["fsproj", "xml"],
    ["fxml", "xml"],
    ["glade", "xml"],
    ["grxml", "xml"],
    ["iml", "xml"],
    ["ivy", "xml"],
    ["jelly", "xml"],
    ["jsproj", "xml"],
    ["kml", "xml"],
    ["launch", "xml"],
    ["mdpolicy", "xml"],
    ["mxml", "xml"],
    ["nproj", "xml"],
    ["nuspec", "xml"],
    ["odd", "xml"],
    ["osm", "xml"],
    ["plist", "xml"],
    ["props", "xml"],
    ["ps1xml", "xml"],
    ["psc1", "xml"],
    ["pt", "xml"],
    ["rdf", "xml"],
    ["rss", "xml"],
    ["scxml", "xml"],
    ["srdf", "xml"],
    ["storyboard", "xml"],
    ["stTheme", "xml"],
    ["sublime-snippet", "xml"],
    ["targets", "xml"],
    ["tmCommand", "xml"],
    ["tml", "xml"],
    ["tmLanguage", "xml"],
    ["tmPreferences", "xml"],
    ["tmSnippet", "xml"],
    ["tmTheme", "xml"],
    ["ui", "xml"],
    ["urdf", "xml"],
    ["ux", "xml"],
    ["vbproj", "xml"],
    ["vcxproj", "xml"],
    ["vssettings", "xml"],
    ["vxml", "xml"],
    ["wsdl", "xml"],
    ["wsf", "xml"],
    ["wxi", "xml"],
    ["wxl", "xml"],
    ["wxs", "xml"],
    ["x3d", "xml"],
    ["xacro", "xml"],
    ["xaml", "xml"],
    ["xib", "xml"],
    ["xlf", "xml"],
    ["xliff", "xml"],
    ["xmi", "xml"],
    ["xml.dist", "xml"],
    ["xproj", "xml"],
    ["xsd", "xml"],
    ["xul", "xml"],
    ["zcml", "xml"],
    ["xsp-config", "xpages"],
    ["xsp.metadata", "xpages"],
    ["xpl", "xproc"],
    ["xproc", "xproc"],
    ["xquery", "xquery"],
    ["xq", "xquery"],
    ["xql", "xquery"],
    ["xqm", "xquery"],
    ["xqy", "xquery"],
    ["xs", "xs"],
    ["log", "log"],
    ["xslt", "xslt"],
    ["xsl", "xslt"],
    ["xojo_code", "xojo"],
    ["xojo_menu", "xojo"],
    ["xojo_report", "xojo"],
    ["xojo_script", "xojo"],
    ["xojo_toolbar", "xojo"],
    ["xojo_window", "xojo"],
    ["xtend", "xtend"],
    ["yml", "yaml"],
    ["reek", "yaml"],
    ["rviz", "yaml"],
    ["sublime-syntax", "yaml"],
    ["syntax", "yaml"],
    ["yaml", "yaml"],
    ["yaml-tmlanguage", "yaml"],
    ["yang", "yang"],
    ["y", "yacc"],
    ["yacc", "yacc"],
    ["yy", "yacc"],
    ["zep", "zephir"],
    ["zimpl", "zimpl"],
    ["zmpl", "zimpl"],
    ["zpl", "zimpl"],
    ["desktop", "desktop"],
    ["desktop.in", "desktop"],
    ["ec", "ec"],
    ["eh", "ec"],
    ["edn", "edn"],
    ["fish", "fish"],
    ["mu", "mupad"],
    ["nc", "nesc"],
    ["ooc", "ooc"],
    ["rst", "restructuredtext"],
    ["rest", "restructuredtext"],
    ["rest.txt", "restructuredtext"],
    ["rst.txt", "restructuredtext"],
    ["wisp", "wisp"],
    ["prg", "xbase"],
    ["prw", "xbase"],
    ["sk", "skript"],
  ]);