
import WebFSFileMeta from "@/api/file-meta";
import { defineComponent } from "vue";
import { WebFSUtil } from "ksancloud-commons";
import FSApi from "@/api/file-system-api";
import { notify } from "@kyvg/vue3-notification";
import { useTranslation } from "i18next-vue";
import TileFileItem from "./tile/TileFileItem.vue";
import ListFileItem from "./list/ListFileItem.vue";

export default defineComponent({
	name: "FileItem",
	setup() {
		const { t } = useTranslation();
		return { FSApi, t };
	},
	emits: ["chown", "buttoncontextmenu", "refresh", "select"],
	props: {
		file: { type: WebFSFileMeta, required: true },
		showPath: { type: Boolean, default: false },
		highlight: { type: String, required: false },
		type: { types: String, required: true },
    dateType: { type: String, required: true},
    clickable: { type: Boolean, default: false }
	},
	data() {
		return {
			appropriateDraggableElementOver: false as boolean,
			dragReferance: 0 as number,
		}
	},
	methods: {
		bodyClick(e: MouseEvent) {
			if (e && e.ctrlKey) this.$emit("select");
			else if (this.file && this.file.routerpath && (this.file.isdir || !this.clickable))
				this.$router.push({
					path: WebFSUtil.absolutePath(
						WebFSUtil.encodeFilePath(this.file.routerpath)
					),
				});
		},
		pathClick() {
			if (this.file && this.file.path) {
				const path = WebFSUtil.join("files", this.file.path) as string;
				this.$router.push({
					path: WebFSUtil.absolutePath(
						WebFSUtil.encodeFilePath(path)
					),
				});
			}
		},
		checkDragConditions(e: DragEvent): e is DragEvent & { dataTransfer: DataTransfer } {
			return (
				this.file != undefined &&
				this.file.isdir &&
				FSApi.hasPermission(this.file, "vw") &&
				e.dataTransfer != undefined &&
				e.dataTransfer.types.every((v) => v === "ksancloud/file")
			);
		},
		startDrag(e: DragEvent) {
			if (!this.file || !e.dataTransfer || !FSApi.hasPermission(this.file, "vm")) return;
			e.dataTransfer.setData("ksancloud/file", JSON.stringify(this.file));
			this.dragReferance = 0;
		},
		dragLeave(e: DragEvent) {
			if (!this.checkDragConditions(e)) return;
			this.dragReferance--;
			if(this.dragReferance <= 0)
				this.appropriateDraggableElementOver = false;
		},
		dragEnter(e: DragEvent) {
			if (!this.checkDragConditions(e)) return;
			this.dragReferance++;
			this.appropriateDraggableElementOver = true;
		},
		handleDrop(e: DragEvent) {
			if (!this.checkDragConditions(e)) return;
			this.dragReferance = 0;
			e.preventDefault();
			e.stopImmediatePropagation();

			const toMove: WebFSFileMeta[] = [];
			let expected = 0;
			for (let i = 0; i < e.dataTransfer.items.length; i++) {
				if (e.dataTransfer.items[i].kind === "string") {
					expected++;
					e.dataTransfer.items[i].getAsString((s) => {
						toMove.push(WebFSFileMeta.fromJson(JSON.parse(s)));
						if (expected == toMove.length) this.doDropMove(toMove);
					});
				}
			}
		},
		async doDropMove(toMove: WebFSFileMeta[]) {
			let failed = 0,
				moved = 0;
			for (const item of toMove) {
				const err = await FSApi.move(item, this.file.fullpath);
				if (err) failed++;
				else moved++;
			}
			await this.$nextTick();
			if (moved > 0) this.$emit("refresh");
			if (failed > 0)
				notify({
					title: this.t("notification.move-fail.title"),
					text: this.t("notification.move-fail.text", {
						count: failed,
					}),
					type: "error",
				});
		},
	},
	components: { ListFileItem, TileFileItem },
});
