import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e215310e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmallLogo = _resolveComponent("SmallLogo")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_SearchSettings = _resolveComponent("SearchSettings")!
  const _component_CollapsableTextfield = _resolveComponent("CollapsableTextfield")!
  const _component_AccountIcon = _resolveComponent("AccountIcon")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createVNode(_component_SmallLogo),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_IconButton, {
        icon: "arrow_back.svg",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.navBack()), ["prevent"])),
        disabled: _ctx.returnDisabled
      }, null, 8, ["disabled"]),
      _createVNode(_component_CollapsableTextfield, {
        icon: "search.svg",
        buttons: [{name:'settings', icon:'settings.svg'}],
        onClick: _ctx.searchClickHandler,
        placeholder: _ctx.t('directory-nav.search-placeholder'),
        onSubmit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('search', $event)))
      }, {
        "extra-content": _withCtx(() => [
          _createVNode(_component_SearchSettings)
        ]),
        _: 1
      }, 8, ["buttons", "onClick", "placeholder"]),
      _createVNode(_component_IconButton, {
        icon: _ctx.showGrid ? 'view_list.svg' : 'grid_view.svg',
        onClick: _withModifiers(_ctx.changeDisplayMode, ["prevent"])
      }, null, 8, ["icon", "onClick"])
    ]),
    _createVNode(_component_AccountIcon, {
      clickable: true,
      user: _ctx.FSApi.userInfo.value?.uuid
    }, null, 8, ["user"])
  ]))
}