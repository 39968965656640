import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-row flex-center flex-gap"
}
const _hoisted_2 = { class: "flex-grow flex flex-row flex-center flex-gap" }
const _hoisted_3 = {
  key: 1,
  class: "version-info"
}
const _hoisted_4 = { class: "subspan" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmallLogo = _resolveComponent("SmallLogo")!
  const _component_RouterButton = _resolveComponent("RouterButton")!
  const _component_AccountIcon = _resolveComponent("AccountIcon")!
  const _component_ModalsContainer = _resolveComponent("ModalsContainer")!
  const _component_notifications = _resolveComponent("notifications")!
  const _component_ProgressModal = _resolveComponent("ProgressModal")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
      !['login','files','account','search','api-down','account-disabled'].includes(_ctx.$route.name)
		)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
          _createVNode(_component_SmallLogo),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_RouterButton, {
              icon: "home_storage.svg",
              to: `/files${_ctx.FSApi.userInfo.value?.entryFolder}`,
              highlight:  _ctx.$route.name==
      'files'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.overflow ? "" : _ctx.t("nav.files")), 1)
              ]),
              _: 1
            }, 8, ["to", "highlight"]),
            (!_ctx.FSApi.loggedIn())
              ? (_openBlock(), _createBlock(_component_RouterButton, {
                  key: 0,
                  icon: "account.svg",
                  to: "/login",
                  highlight: _ctx.$route.name == 'login'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.overflow ? "" : _ctx.t('nav.login')), 1)
                  ]),
                  _: 1
                }, 8, ["highlight"]))
              : _createCommentVNode("", true),
            (_ctx.FSApi.isAdminUser())
              ? (_openBlock(), _createBlock(_component_RouterButton, {
                  key: 1,
                  icon: "cpu.svg",
                  to: "/dashboard/stat",
                  highlight: _ctx.$route.name == 'stat-dashboard'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.overflow ? "" : _ctx.t("nav.stats")), 1)
                  ]),
                  _: 1
                }, 8, ["highlight"]))
              : _createCommentVNode("", true),
            (_ctx.FSApi.isAdminUser())
              ? (_openBlock(), _createBlock(_component_RouterButton, {
                  key: 2,
                  icon: "admin_panel.svg",
                  to: "/dashboard/admin",
                  highlight: _ctx.$route.name == 'admin-dashboard'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.overflow ? "" : _ctx.t("nav.admin")), 1)
                  ]),
                  _: 1
                }, 8, ["highlight"]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_AccountIcon, {
            clickable: "",
            user: _ctx.FSApi.userInfo.value?.uuid
          }, null, 8, ["user"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.$route.name == 'home')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, "KsanCloud v" + _toDisplayString(require('../package.json').version), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ModalsContainer),
    _createVNode(_component_notifications, {
      position: "bottom right",
      classes: "notification",
      width: "min(90vw, 300px)"
    }),
    _createVNode(_component_ProgressModal, {
      message: _ctx.ProgressService.progressMessage.value,
      value: _ctx.ProgressService.progressValue.value,
      max: _ctx.ProgressService.progressMax.value,
      modalId: _ctx.ProgressService.progressModalId,
      onCancel: _ctx.ProgressService.doCancel
    }, null, 8, ["message", "value", "max", "modalId", "onCancel"]),
    _createVNode(_component_router_view, { class: "router-view" })
  ], 64))
}