
import { defineComponent } from "vue";
import { ViewState } from "@/util/view-state";
import ApiError, { ApiErrorType } from "@/api/api-error";
import { WebFSUtil } from "ksancloud-commons";
import FSApi from "../api/file-system-api";
import ErrorDisplay from "@/components/displays/ErrorDisplay.vue";
import SearchDisplay from "@/components/displays/SearchDisplay.vue";

export default defineComponent({
  data: () => {
    return {
      viewState: ViewState.LOADING,
      viewError: new ApiError("", ""),
      currentPath: "",
    };
  },
  computed: {
    path() {
      return WebFSUtil.join(...this.currentPath);
    },
    query() {
      const q = this.$route.query.q;
      return (Array.isArray(q) ? q[0] : q || "") as string;
    },
  },
  watch: {
    currentPath: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      handler(val, oldVal) {
        if (!FSApi.hasPermission(this.path)) {
          this.viewState = ViewState.ERROR;
          this.viewError = ApiError.ofType(ApiErrorType.UNAUTHORIZED);
        } else {
          this.viewState = ViewState.DIR_VIEW;
          this.viewError = new ApiError("", "");
        }
      },
      immediate: true,
    },
  },
  name: "SearchView",
  components: {
    ErrorDisplay,
    SearchDisplay,
  },
});
