
import App from "./App.vue";
import LargeLogo from '@/components/logo/LargeLogo.vue';

const element = document.getElementById("suspenser-logo") as Element;
if(element)
   element.remove();

export default {
  name: "app-suspenser",
  components: {
    App, LargeLogo
  },
};
