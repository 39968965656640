
import WebFSFileMeta from "@/api/file-meta";
import fileutil from "@/util/fileutil";
import formatter from "@/util/formatter";
import { defineComponent } from "vue";
import IconButton from "./util/IconButton.vue";
import ConfirmButton from "./util/ConfirmButton.vue";
import { sizeFormatter } from "@/util/fileutil";
import { useTranslation } from "i18next-vue";
import FileIcon from "@/components/util/FileIcon.vue";

export default defineComponent({
  name: "FileItem",
  data() {
    type DataType = {
      keyListener?: (event: KeyboardEvent) => void;
      leaveListener?: (event: BeforeUnloadEvent) => void;
    };
    return {} as DataType;
  },
  props: {
    file: { type: WebFSFileMeta, required: false },
    editing: { type: Boolean, default: false },
    canEdit: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
    unsaved: { type: Boolean, default: false },
  },
  computed: {
    icon() {
      return this.file ? fileutil.getIconForMeta(this.file) : "file.svg";
    },
  },
  emits: ["return", "download", "embed", "editEnable", "save"],
  setup() {
    const { t } = useTranslation();
    return { sizeFormatter, formatter, t, fileutil };
  },
  mounted() {
    this.keyListener = (e) => {
      if (e.ctrlKey && e.key === "s") {
        if (this.editing && !this.saving) this.$emit("save");
        e.preventDefault();
      } else if (e.ctrlKey && e.key === "q") {
        if (!this.editing) this.$emit('editEnable');
        e.preventDefault();
      } else if (e.key === "Escape") {
        this.$router.back();
        e.preventDefault();
      }
    };
    this.leaveListener = (e) => {
      if (this.unsaved) {
        e.preventDefault();
        e.returnValue = this.t("text-editor.beforeleave.leave-prompt");
      }
    };
    window.addEventListener("keydown", this.keyListener);
    window.addEventListener("beforeunload", this.leaveListener);
  },
  unmounted() {
    if (this.keyListener) window.removeEventListener("keydown", this.keyListener);
    if (this.leaveListener)
      window.removeEventListener("beforeunload", this.leaveListener);
  },
  components: {FileIcon, IconButton, ConfirmButton },
});
