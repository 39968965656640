
import { defineComponent } from "vue";
import IconButton from "../util/IconButton.vue";
import SmallLogo from "../logo/SmallLogo.vue";
import AccountIcon from "../account/AccountIcon.vue";
import FSApi from "@/api/file-system-api";
import CollapsableTextfield from "../util/CollapsableTextField.vue";
import { useTranslation } from "i18next-vue";
import { WebFSUtil } from "ksancloud-commons";
import { useVfm } from "vue-final-modal";
import SearchSettings from "@/components/account/SearchSettings.vue";

export default defineComponent({
  name: "DirectoryNav",
  data() {
    return {
      initialQuary: "",
      showGrid: false,
    };
  },
  components: {
    SearchSettings,
    IconButton,
    SmallLogo,
    AccountIcon,
    CollapsableTextfield,
  },
  emits: ["search", "home", "mkfile", "displayMode"],
  props: ["defaultNav", "directory"],
  setup() {
    const { t } = useTranslation();
    const vfm = useVfm();
    return { FSApi, t, vfm };
  },
  mounted() {
    this.initialQuary =
      (Array.isArray(this.$route.query.q)
        ? this.$route.query.q[0]
        : this.$route.query.q) || "";
    let m = localStorage.getItem("fileListDisplayMode");
    if (!m) {
      this.showGrid = false;
      m = this.showGrid ? "grid" : "list";
      localStorage.setItem("fileListDisplayMode", m);
    }
    this.showGrid = m == "grid";
    this.$emit("displayMode", m);
  },
  methods: {
    navForward() {
      if (!this.$props.defaultNav) return;
      this.$router.forward();
    },
    navBack() {
      if (!this.$props.defaultNav) return;
      this.$router.back();
    },
    changeDisplayMode() {
      this.showGrid = !this.showGrid;
      const m = this.showGrid ? "grid" : "list";
      localStorage.setItem("fileListDisplayMode", m);
      this.$emit("displayMode", m);
    },
    searchClickHandler(event: {buttonName: string, modalId: string}) {
      if (event.buttonName === 'settings') {
        this.vfm.open(event.modalId)
      }
    }
  },
  computed: {
    returnDisabled() {
      return this.$route.params.filepath == "";
    },
    forwardDisabled() {
      return false;
    },
    displayedPath() {
      return WebFSUtil.join(...this.$route.params.filepath);
    },
  },
});
