<template>
  <div class="logo-large">
    <svg
          id="svg5"
          version="1.1"
          viewBox="0 0 512 512"
          height="224"
          width="224"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:svg="http://www.w3.org/2000/svg">
       <defs
          id="defs2" />
       <g
          id="layer1"
          style="display:inline">
          <path
             id="path288-71"
             style="display:inline;fill:#de66ff;fill-opacity:1;stroke-width:1.26348"
             d="m 206.80078,116.49414 a 95.335251,95.335251 0 0 0 -95.33594,95.33594 95.335251,95.335251 0 0 0 2.96875,22.75 64.646385,64.6465 0 0 0 -29.099606,8.14258 141.15895,141.15894 0 0 0 127.509766,80.88281 141.15895,141.15894 0 0 0 141.16016,-141.1582 141.15895,141.15894 0 0 0 -0.42383,-10.3711 74.815468,74.815468 0 0 0 -42.77539,-13.46875 74.815468,74.815468 0 0 0 -22.61719,3.60352 95.335251,95.335251 0 0 0 -81.38672,-45.7168 z" />
          <path
             id="path288-9"
             style="display:inline;fill:#ff83fd;fill-opacity:1;stroke-width:1.13211"
             d="m 310.80469,158.60742 a 74.815468,74.815468 0 0 0 -20.92578,3.19922 126.48157,126.48157 0 0 0 -11.72657,53.08594 126.48157,126.48157 0 0 0 126.48243,126.48047 126.48157,126.48157 0 0 0 32.28711,-4.39453 71.026001,71.026001 0 0 0 15.48632,-44.26954 71.026001,71.026001 0 0 0 -67.71484,-70.94531 74.815468,74.815468 0 0 0 -73.88867,-63.15625 z" />
          <path
             id="path288-6"
             style="display:inline;fill:#ec64ff;fill-opacity:1;stroke-width:0.828097"
             d="m 344.26367,166.875 a 92.516808,92.516808 0 0 0 -87.50781,92.16016 92.516808,92.516808 0 0 0 92.51562,92.51562 92.516808,92.516808 0 0 0 92.51758,-92.51562 92.516808,92.516808 0 0 0 -0.0742,-2.9961 71.026001,71.026001 0 0 0 -57.02148,-34.27539 74.815468,74.815468 0 0 0 -40.42969,-54.88867 z" />
          <path
             id="path288-63-8"
             style="display:inline;fill:#389eff;fill-opacity:1;stroke-width:0.86844"
             d="m 378.36914,279.69922 a 97.173592,96.87468 0 0 0 -96.25391,84.03516 h 99.26758 a 71.026001,71.026001 0 0 0 68.40625,-52.82422 97.173592,96.87468 0 0 0 -71.41992,-31.21094 z" />
          <path
             id="path288-71-4"
             style="fill:#f378ff;fill-opacity:1;stroke-width:0.822782"
             d="m 208.68555,172.00977 a 91.923019,91.923019 0 0 0 -88.46289,67.23437 95.335251,95.335251 0 0 0 2.21093,11.83594 64.646385,64.6465 0 0 0 -4.79882,0.67578 91.923019,91.923019 0 0 0 -0.8711,12.17578 91.923019,91.923019 0 0 0 91.92188,91.92383 91.923019,91.923019 0 0 0 91.92383,-91.92383 91.923019,91.923019 0 0 0 -91.92383,-91.92187 z" />
          <path
             id="path288-2-0"
             style="display:inline;fill:#4b9fff;fill-opacity:1;stroke-width:0.778764"
             d="m 166.0017,251.95871 a 87.005157,87.005157 0 0 0 -12.38352,-0.88511 87.005157,87.005157 0 0 0 -87.003795,87.00514 87.005157,87.005157 0 0 0 10.76141,41.45074 l 137.113535,20.69163 a 87.005157,87.005157 0 0 0 26.13591,-62.14343 87.005157,87.005157 0 0 0 -74.62354,-86.11897 z"
             transform="rotate(-8.5816786)" />
          <path
             id="path288-2"
             style="display:inline;fill:#4faaff;fill-opacity:1;stroke-width:0.826052"
             d="m 83.388826,207.56394 a 95.335251,95.335251 0 0 0 -4.781033,18.52721 95.335251,95.335251 0 0 0 -0.459224,22.93829 64.646385,64.6465 8.5816786 0 0 -71.6211177,54.42996 64.646385,64.6465 8.5816786 0 0 54.2762177,73.5692 l 26.506517,4.00007 a 92.28833,92.28833 0 0 0 59.159884,-86.10893 92.28833,92.28833 0 0 0 -63.081244,-87.3558 z"
             transform="rotate(-8.5816786)" />
          <path
             id="path288-2-0-8"
             style="display:inline;fill:#40b1ff;fill-opacity:1;stroke-width:0.671354"
             d="m 269.25733,289.32778 a 75.005157,75.005157 0 0 0 -10.67356,-0.76335 75.005157,75.005157 0 0 0 -75.00681,75.00668 75.005157,75.005157 0 0 0 7.88018,33.17424 l 121.60167,18.35075 a 75.005157,75.005157 0 0 0 20.52972,-51.52516 75.005157,75.005157 0 0 0 -64.3312,-74.24316 z"
             transform="rotate(-8.5816786)" />
       </g>
       </svg>
    <h1>KsanCloud</h1>
  </div>
</template>

<style scoped lang="scss">
h1 {
  margin-top: -4rem;
  font-size: 2.5rem;
}
</style>
