import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6dfb6280"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "file-item file-header",
  ref: "body"
}
const _hoisted_2 = { class: "name-span" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmButton = _resolveComponent("ConfirmButton")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_FileIcon = _resolveComponent("FileIcon")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createVNode(_component_ConfirmButton, {
      "cancel-message": _ctx.t('text-editor.modal.stay'),
      "confirm-message": _ctx.t('text-editor.modal.leave'),
      icon: "arrow_back.svg",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('return'))),
      armed: !_ctx.unsaved
    }, {
      modal: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.t("text-editor.modal.leave-prompt")), 1)
      ]),
      _: 1
    }, 8, ["cancel-message", "confirm-message", "armed"]),
    _createVNode(_component_IconButton, {
      icon: "file_download.svg",
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('download')), ["prevent"]))
    }),
    (_ctx.canEdit && !_ctx.editing)
      ? (_openBlock(), _createBlock(_component_IconButton, {
          key: 0,
          icon: "edit_note.svg",
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('editEnable')), ["prevent"]))
        }))
      : (_ctx.editing)
        ? (_openBlock(), _createBlock(_component_IconButton, {
            key: 1,
            class: "ok-button",
            icon: "save.svg",
            loading: _ctx.saving,
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('save')), ["prevent"]))
          }, null, 8, ["loading"]))
        : _createCommentVNode("", true),
    _createVNode(_component_FileIcon, {
      filename: _ctx.file?.name || '',
      isdir: _ctx.file ? _ctx.file.isdir : false
    }, null, 8, ["filename", "isdir"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.file ? _ctx.file.name : "---"), 1),
    _createElementVNode("span", {
      class: _normalizeClass(["size-span", { 'span-empty': !_ctx.file || !_ctx.file.length }])
    }, _toDisplayString(_ctx.file && _ctx.file.length ? _ctx.sizeFormatter(_ctx.file.length) : "---"), 3),
    _createElementVNode("span", {
      class: _normalizeClass(["modified-span", { 'span-empty': !_ctx.file || !_ctx.file.modified }])
    }, _toDisplayString(_ctx.file && _ctx.file.modified ? _ctx.formatter.formatDate(_ctx.file.modified) : "---"), 3)
  ], 512))
}