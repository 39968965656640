import {Ref, ref, watch} from "vue";
import {FileListSortType} from "@/api/file-list-sort";

export type PreferencePair = {
    value: Ref<string>;
    key: string;
    validate(value: string | null): string;
}

const doesNotThrow = (f: () => object) => {
    try {
        const v = f();
        return typeof v === 'object' &&
            !Array.isArray(v) &&
            v !== null;
    } catch (e) {
        return false;
    }
}
const allowedSet = (set: string[]): PreferencePair['validate'] => (v: string | null) => (v != null && set.includes(v)) ? v : set[0]
const jsonValid = (): PreferencePair['validate'] => (v: string | null) => (v != null && doesNotThrow(() => JSON.parse(v))) ? v : "{}"

export class AppPreferenceService {

    public static readonly dateType = ref<string>("");
    public static readonly fileListDisplayMode = ref<string>("");
    public static readonly fileListSortBy = ref<string>("");
    public static readonly fileListSortReversed = ref<string>("");
    public static readonly searchMatcher = ref<string>("");
    public static readonly preserveFileHistory = ref<string>("");
    public static readonly selectedHomeTab = ref<string>("");
    public static readonly graphTimingOptions = ref<string>("");


    private static preferenceList: PreferencePair[] = [
        {value: this.dateType, key: 'fileListDateType', validate: allowedSet(['relative', 'absolute'])},
        {value: this.fileListDisplayMode, key: 'fileListDisplayMode', validate: allowedSet(['list', 'grid'])},
        {value: this.searchMatcher, key: 'searchMatcher', validate: allowedSet(['plain', 'glob'])},
        {value: this.fileListSortBy, key: 'fileListSortBy', validate: allowedSet(Object.values(FileListSortType))},
        {value: this.fileListSortReversed, key: 'fileListSortReversed', validate: allowedSet([false.toString(), true.toString()])},
        {value: this.preserveFileHistory, key: 'preserveFileHistory', validate: allowedSet(['1', '0'])},
        {value: this.selectedHomeTab, key: 'selectedHomeTab', validate: allowedSet(['1', '2'])},
        {value: this.graphTimingOptions, key: 'graphTimingOptions', validate: jsonValid()},
    ];

    public static init() {
        for (const preference of AppPreferenceService.preferenceList) {
            if (localStorage.getItem(preference.key) == null)
                localStorage.setItem(preference.key, preference.validate(preference.value.value));

            preference.value.value = preference.validate(localStorage.getItem(preference.key))

            watch(preference.value, (v) => {
                localStorage.setItem(preference.key, preference.validate(v));
            })
        }
    }
}
