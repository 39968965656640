
import FSApi from "@/api/file-system-api";
import { WebFSUtil } from "ksancloud-commons";
import { useTranslation } from "i18next-vue";
import { defineComponent, PropType } from "vue";
import WebFSFileMeta from "@/api/file-meta";
import SubSpan from "@/components/util/SubSpan.vue";
import SideScroller from "@/components/util/SideScroller.vue";
import { notify } from "@kyvg/vue3-notification";
import fileutil from "@/util/fileutil";
export default defineComponent({
  name: "PathDisplay",
  data() {
    return {
      parts: [] as string[] | undefined,
    };
  },
  setup() {
    const { i18next } = useTranslation();
    return { t: i18next.t, i18next: i18next, fileutil };
  },
  props: {
    path: {type: String},
    itemCount: {type: Number},
    archivePathInfo: {type: Object as PropType<number[]>},
    itemMsgKey: {type: String, default:'items'},
    loading: {type: Boolean, default: false}
  },
  emits: ["refresh"],
  watch: {
    path: {
      handler(val) {
        this.parts = WebFSUtil.pathValid(val) ? WebFSUtil.splitPath(val) : undefined;
      },
      immediate: true,
    },
  },
  methods: {
    goto(index: number) {
      if (this.parts) {
        const s = WebFSUtil.join("files", ...this.parts.slice(0, index + 1));
        if (!s) return;
        this.$router.push(WebFSUtil.absolutePath(WebFSUtil.encodeFilePath(s)));
      }
    },
    handleDrop(e: DragEvent, index: number) {
      if (!this.parts) return;
      const movePath = WebFSUtil.join(...this.parts.slice(0, index + 1));
      if (
        !this.parts ||
        !movePath ||
        !FSApi.hasPermission(movePath, "vw") ||
        !e.dataTransfer ||
        !e.dataTransfer.types.every((v) => v === "ksancloud/file")
      )
        return;
      e.preventDefault();
      e.stopImmediatePropagation();

      const toMove: WebFSFileMeta[] = [];
      let expected = 0;
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === "string") {
          expected++;
          e.dataTransfer.items[i].getAsString((s) => {
            toMove.push(WebFSFileMeta.fromJson(JSON.parse(s)));
            if (expected == toMove.length) this.doDropMove(toMove, movePath);
          });
        }
      }
    },
    async doDropMove(toMove: WebFSFileMeta[], path: string) {
      let failed = 0,
        moved = 0;
      for (const item of toMove) {
        const err = await FSApi.move(item, path);
        if (err) failed++;
        else moved++;
      }
      await this.$nextTick();
      if (moved > 0) this.$emit("refresh");
      if (failed > 0)
        notify({
          title: this.t("notification.move-fail.title") || "",
          text: this.t("notification.move-fail.text", { count: failed }) || "",
          type: "error",
        });
    },
  },
  components: { SubSpan, SideScroller },
});
