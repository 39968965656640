import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34b38bab"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "vue-simple-context-menu__title"
}
const _hoisted_3 = {
  key: 1,
  class: "vue-simple-context-menu__divider"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideScroller = _resolveComponent("SideScroller")!
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _withDirectives((_openBlock(), _createElementBlock("ul", {
      id: _ctx.elementId,
      class: "vue-simple-context-menu"
    }, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
            _createVNode(_component_SideScroller, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("li", _hoisted_3))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          onClick: _withModifiers(($event: any) => (_ctx.optionClicked(option)), ["stop"]),
          class: _normalizeClass(["vue-simple-context-menu__item", [
          (option).class,
          (option).type === 'divider' ? 'vue-simple-context-menu__divider' : '',
        ]])
        }, [
          _createVNode(_component_IconLabel, {
            icon: (option).icon
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString((option).text), 1)
            ]),
            _: 2
          }, 1032, ["icon"])
        ], 10, _hoisted_4))
      }), 128))
    ], 8, _hoisted_1)), [
      [_directive_click_outside, _ctx.onClickOutside]
    ])
  ])), [
    [_directive_click_outside, _ctx.vcoConfig]
  ])
}