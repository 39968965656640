import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b0196b0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorDisplay = _resolveComponent("ErrorDisplay")!
  const _component_FileDisplay = _resolveComponent("FileDisplay")!
  const _component_DirectoryDisplay = _resolveComponent("DirectoryDisplay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.viewState == 'error')
      ? (_openBlock(), _createBlock(_component_ErrorDisplay, {
          key: 0,
          error: _ctx.viewError
        }, null, 8, ["error"]))
      : _createCommentVNode("", true),
    (_ctx.viewState == 'file')
      ? (_openBlock(), _createBlock(_component_FileDisplay, {
          key: 1,
          file: _ctx.currentFileMeta
        }, null, 8, ["file"]))
      : _createCommentVNode("", true),
    (_ctx.viewState == 'directory')
      ? (_openBlock(), _createBlock(_component_DirectoryDisplay, {
          key: 2,
          directory: _ctx.currentFileMeta
        }, null, 8, ["directory"]))
      : _createCommentVNode("", true)
  ]))
}