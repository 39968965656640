import axios from "axios";

export enum ApiErrorType {
  FILE_NOT_FOUND = "404",
  UNAUTHORIZED = "401",
  FORBIDDEN = "403",
  ERROR = "0",
  NETWORK = "1",
  SERVER_ERROR = "500",
  BAD_REQUEST = "400",
  RATE_LIMITED = "429",
}

export default class ApiError extends Error {
  public type?: ApiErrorType;

  constructor(name: string, message: string) {
    super(message);
    this.name = name;
    this.type = ApiErrorType.ERROR;
  }

  public static ofType(type: ApiErrorType, message = ""): ApiError {
    const err = new ApiError(type, message);
    err.type = type;
    return err;
  }

  public static fromErrorCode(code: number, message?: string): ApiError {
    if (code == 404) return ApiError.ofType(ApiErrorType.FILE_NOT_FOUND, message);
    if (code == 401) return ApiError.ofType(ApiErrorType.UNAUTHORIZED, message);
    if (code == 403) return ApiError.ofType(ApiErrorType.FORBIDDEN, message);
    if (code == 400) return ApiError.ofType(ApiErrorType.BAD_REQUEST, message);
    if (code == 429) return ApiError.ofType(ApiErrorType.RATE_LIMITED, message);
    if (code == 500) return ApiError.ofType(ApiErrorType.SERVER_ERROR, message);
    return ApiError.ofType(ApiErrorType.ERROR);
  }

  /**
   * Finds the appropriate ApiError to display
   * to the user.
   * @param err error to be processed
   * @returns The appropriate ApiError
   */
  public static handle(err: Error): ApiError {
    if (axios.isAxiosError(err) && err.response) {
      return ApiError.fromErrorCode(err.response.status, err.response.data.reason || "Unknown reason");
    } else if(axios.isAxiosError(err)) {
      return ApiError.ofType(ApiErrorType.NETWORK);
    } else {
      return new ApiError(ApiErrorType.ERROR, `${err}`);
    }
  }
}
