import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8bed88a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home flex-column flex-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LargeLogo = _resolveComponent("LargeLogo")!
  const _component_FileHistory = _resolveComponent("FileHistory")!
  const _component_FileBookmarks = _resolveComponent("FileBookmarks")!
  const _component_ChipPane = _resolveComponent("ChipPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LargeLogo),
    (_ctx.FSApi.loggedIn())
      ? (_openBlock(), _createBlock(_component_ChipPane, {
          key: 0,
          class: "user-quick-access flex-column flex-gap",
          chips: [{id:'1',name:_ctx.t('home.file-history'),icon:'history.svg'},{id:'2',name:_ctx.t('home.file-bookmarks'),icon:'bookmarks.svg'}],
          "persist-id-key": "selectedHomeTab"
        }, {
          "1": _withCtx(() => [
            _createVNode(_component_FileHistory, { class: "flex-grow" })
          ]),
          "2": _withCtx(() => [
            _createVNode(_component_FileBookmarks, { class: "flex-grow" })
          ]),
          _: 1
        }, 8, ["chips"]))
      : _createCommentVNode("", true)
  ]))
}