"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginAttempt = void 0;
class LoginAttempt {
    constructor(uid, ip, successful, date) {
        this.uuid = uid;
        this.ip = ip;
        this.successful = successful;
        this.date = date;
    }
}
exports.LoginAttempt = LoginAttempt;
