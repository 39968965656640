
import { defineComponent } from "vue";
import IconButton from "../util/IconButton.vue";
import SmallLogo from "../logo/SmallLogo.vue";
import FSApi from "@/api/file-system-api";
import AccountName from "../account/AccountName.vue";

export default defineComponent({
  name: "DirectoryNav",
  components: { IconButton, SmallLogo, AccountName },
  emits: ["return"],
  setup() {
    return { FSApi };
  },
});
