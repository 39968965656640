import i18next from "i18next";

export default class Formatter {

    private static dateTimeFormat = new Intl.DateTimeFormat(navigator.language, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    });

    private static detailDateTimeFormat = new Intl.DateTimeFormat(navigator.language, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        fractionalSecondDigits: 3,
        hour12: false,
    });

    private static hourDateFormat = new Intl.DateTimeFormat(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    })

    private static relativeDateFormat = new Intl.RelativeTimeFormat(navigator.language, {
        style: "short",
        numeric: "always"
    })

    public static parseDate(date: number) {
        return new Date(Math.floor(date * 1000) || 0);
    }

    public static formatDate(date: Date | number) {
        if (date == undefined || date === 0) return undefined;
        return this.dateTimeFormat.format(date);
    }

    public static formatDateDetail(date: Date | number) {
        if (date == undefined || date === 0) return undefined;
        return this.detailDateTimeFormat.format(date);
    }

    public static formatDateHour(date: Date | number) {
        if (date == undefined || date === 0) return undefined;
        return this.hourDateFormat.format(date);
    }

    private static floorTo0(num: number) {
        return num < 0 ? Math.ceil(num) : Math.floor(num);
    }

    private static readonly MINUTE_SECONDS = 60;
    private static readonly HOUR_SECONDS = 60 * this.MINUTE_SECONDS;
    private static readonly DAY_SECONDS = 24 * this.HOUR_SECONDS;
    private static readonly WEEK_SECONDS = 7 * this.DAY_SECONDS;
    private static readonly YEAR_SECONDS = 365 * this.DAY_SECONDS;
    private static readonly MONTH_SECONDS = this.YEAR_SECONDS / 12;

    public static findBestTimeUnit(seconds: number): [name: Intl.RelativeTimeFormatUnit, value: number, scalar: number] {
        const absoluteSeconds = Math.abs(seconds);

        if (absoluteSeconds < Formatter.MINUTE_SECONDS) {
            return [seconds === 1 ? 'second' : 'seconds', this.floorTo0(seconds), 1];
        } else if (absoluteSeconds < Formatter.HOUR_SECONDS) {
            const minutes = this.floorTo0(seconds / Formatter.MINUTE_SECONDS);
            return [minutes === 1 ? 'minute' : 'minutes', minutes, Formatter.MINUTE_SECONDS];
        } else if (absoluteSeconds < Formatter.DAY_SECONDS) {
            const hours = this.floorTo0(seconds / Formatter.HOUR_SECONDS);
            return [hours === 1 ? 'hour' : 'hours', hours, Formatter.HOUR_SECONDS];
        } else if (absoluteSeconds < Formatter.WEEK_SECONDS) {
            const days = this.floorTo0(seconds / Formatter.DAY_SECONDS);
            return [days === 1 ? 'day' : 'days', days, Formatter.DAY_SECONDS];
        } else if (absoluteSeconds < Formatter.MONTH_SECONDS) {
            const weeks = this.floorTo0(seconds / Formatter.WEEK_SECONDS);
            return [weeks === 1 ? 'week' : 'weeks', weeks, Formatter.WEEK_SECONDS];
        } else if (absoluteSeconds < Formatter.YEAR_SECONDS) {
            const months = this.floorTo0(seconds / Formatter.MONTH_SECONDS);
            return [months === 1 ? 'month' : 'months', months, Formatter.MONTH_SECONDS];
        } else {
            const years = this.floorTo0(seconds / Formatter.YEAR_SECONDS);
            return [years === 1 ? 'year' : 'years', years, Formatter.YEAR_SECONDS];
        }
    }

    public static formatDateRelative(date: Date | number) {
        if (date == undefined || date === 0) return undefined;
        if (typeof(date) !== 'number') {
            date = date.getTime()
        }
        const [unit, value] = this.findBestTimeUnit((date - new Date().getTime()) / 1000);
        if ((unit == "second" || unit == "seconds") && value > -5) return i18next.t("time.now")
        return this.relativeDateFormat.format(value, unit);
    }

    public static scheduleRelativeDateRefresh(date: number, refreshFunction: () => boolean, intervalListener: (interval: number | undefined) => void) {
        let deltaSeconds = (date - new Date().getTime()) / 1000;
        const scalar = Formatter.findBestTimeUnit(deltaSeconds)[2];
        if (scalar >= Formatter.DAY_SECONDS) return undefined;
        const interval = setInterval(() => {
            deltaSeconds = (date - new Date().getTime()) / 1000;
            const newScalar = Formatter.findBestTimeUnit(deltaSeconds)[2];
            if (!refreshFunction()) {
                clearInterval(interval);
                intervalListener(undefined);
                return;
            }
            if(newScalar != scalar) {
                clearInterval(interval);
                this.scheduleRelativeDateRefresh(date, refreshFunction, intervalListener)
            }
        }, scalar * 1000)
        intervalListener(interval)
    }
}
