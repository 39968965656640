import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc64669c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["draggable"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListFileItem = _resolveComponent("ListFileItem")!
  const _component_TileFileItem = _resolveComponent("TileFileItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["file-item", {dragover: _ctx.appropriateDraggableElementOver}]),
    ref: "body",
    draggable: !!_ctx.file && _ctx.FSApi.hasPermission(_ctx.file, 'vwm'),
    onDragstart: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.startDrag && _ctx.startDrag(...args))),
    onDragenter: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.dragEnter && _ctx.dragEnter(...args))),
    onDragleave: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.dragLeave && _ctx.dragLeave(...args))),
    onDrop: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args)))
  }, [
    (_ctx.type == 'list')
      ? (_openBlock(), _createBlock(_component_ListFileItem, {
          key: 0,
          ref: "item",
          file: _ctx.file,
          "show-path": _ctx.showPath,
          "date-type": _ctx.dateType,
          highlight: _ctx.highlight,
          "folder-open": _ctx.appropriateDraggableElementOver,
          onBodyclick: _ctx.bodyClick,
          onPathclick: _ctx.pathClick,
          onChown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('chown', $event))),
          onButtoncontextmenu: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('buttoncontextmenu', $event)))
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["file", "show-path", "date-type", "highlight", "folder-open", "onBodyclick", "onPathclick"]))
      : (_ctx.type == 'grid')
        ? (_openBlock(), _createBlock(_component_TileFileItem, {
            key: 1,
            ref: "item",
            file: _ctx.file,
            "show-path": _ctx.showPath,
            highlight: _ctx.highlight,
            "folder-open": _ctx.appropriateDraggableElementOver,
            onBodyclick: _ctx.bodyClick,
            onPathclick: _ctx.pathClick,
            onButtoncontextmenu: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('buttoncontextmenu', $event)))
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["file", "show-path", "highlight", "folder-open", "onBodyclick", "onPathclick"]))
        : _createCommentVNode("", true)
  ], 42, _hoisted_1))
}