"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListCache = void 0;
class ListCache {
    constructor(size) {
        this.stored = 0;
        this.index = 0;
        this.size = size;
        this.data = new Array(size);
    }
    insert(element) {
        this.index = this.fixIndex(this.index + 1);
        this.data[this.index] = element;
        this.stored = Math.min(this.stored + 1, this.size);
    }
    clear() {
        this.stored = 0;
    }
    getLast() {
        if (this.stored == 0)
            return undefined;
        return this.data[this.index];
    }
    get() {
        return Array.from(this.iterator());
    }
    at(index) {
        let ai = this.fixIndex(this.index - this.stored + 1 + index);
        return ai > 0 && ai < this.data.length ? this.data[ai] : undefined;
    }
    *reverseIterator() {
        let ai = this.index;
        for (let i = 0; i < this.stored; i++) {
            yield this.data[ai];
            ai = this.fixIndex(ai - 1);
        }
    }
    *iterator() {
        let ai = this.fixIndex(this.index - this.stored + 1);
        for (let i = 0; i < this.stored; i++) {
            yield this.data[ai];
            ai = this.fixIndex(ai + 1);
        }
    }
    fixIndex(index) {
        let a = index % this.size;
        return a < 0 ? this.size + a : a;
    }
}
exports.ListCache = ListCache;
