export const fileNameIconMap = new Map<string,string>([
  [".pug-lintrc", "pug"],
  [".pug-lintrc.js", "pug"],
  [".pug-lintrc.json", "pug"],
  [".jscsrc", "json"],
  [".jshintrc", "json"],
  ["composer.lock", "json"],
  [".jsbeautifyrc", "json"],
  [".esformatter", "json"],
  ["cdp.pid", "json"],
  [".lintstagedrc", "json"],
  ["playwright.config.js", "playwright"],
  ["playwright.config.mjs", "playwright"],
  ["playwright.config.ts", "playwright"],
  ["playwright-ct.config.js", "playwright"],
  ["playwright-ct.config.mjs", "playwright"],
  ["playwright-ct.config.ts", "playwright"],
  [".htaccess", "xml"],
  ["router.js", "routing"],
  ["router.jsx", "routing"],
  ["router.ts", "routing"],
  ["router.tsx", "routing"],
  ["routes.js", "routing"],
  ["routes.jsx", "routing"],
  ["routes.ts", "routing"],
  ["routes.tsx", "routing"],
  [".jshintignore", "settings"],
  [".buildignore", "settings"],
  [".mrconfig", "settings"],
  [".yardopts", "settings"],
  ["manifest.mf", "settings"],
  [".clang-format", "settings"],
  [".clang-tidy", "settings"],
  ["astro.config.js", "astro"],
  ["astro.config.mjs", "astro"],
  ["astro.config.cjs", "astro"],
  ["astro.config.ts", "astro"],
  ["astro.config.cts", "astro"],
  ["astro.config.mts", "astro"],
  ["go.mod", "go-mod"],
  ["go.sum", "go-mod"],
  ["go.work", "go-mod"],
  ["go.work.sum", "go-mod"],
  ["requirements.txt", "python-misc"],
  ["pipfile", "python-misc"],
  [".python-version", "python-misc"],
  ["manifest.in", "python-misc"],
  ["pylintrc", "python-misc"],
  [".pylintrc", "python-misc"],
  ["pyproject.toml", "python-misc"],
  ["commit-msg", "console"],
  ["pre-commit", "console"],
  ["pre-push", "console"],
  ["post-merge", "console"],
  ["gradle.properties", "gradle"],
  ["gradlew", "gradle"],
  ["gradle-wrapper.properties", "gradle"],
  ["copying", "certificate"],
  ["copying.md", "certificate"],
  ["copying.rst", "certificate"],
  ["copying.txt", "certificate"],
  ["copyright", "certificate"],
  ["copyright.md", "certificate"],
  ["copyright.rst", "certificate"],
  ["copyright.txt", "certificate"],
  ["license", "certificate"],
  ["license-agpl", "certificate"],
  ["license-apache", "certificate"],
  ["license-bsd", "certificate"],
  ["license-mit", "certificate"],
  ["license-gpl", "certificate"],
  ["license-lgpl", "certificate"],
  ["license.md", "certificate"],
  ["license.rst", "certificate"],
  ["license.txt", "certificate"],
  ["licence", "certificate"],
  ["licence-agpl", "certificate"],
  ["licence-apache", "certificate"],
  ["licence-bsd", "certificate"],
  ["licence-mit", "certificate"],
  ["licence-gpl", "certificate"],
  ["licence-lgpl", "certificate"],
  ["licence.md", "certificate"],
  ["licence.rst", "certificate"],
  ["licence.txt", "certificate"],
  [".htpasswd", "key"],
  ["gemfile", "gemfile"],
  [".rubocop.yml", "rubocop"],
  [".rubocop-todo.yml", "rubocop"],
  [".rubocop_todo.yml", "rubocop"],
  ["dockerfile", "docker"],
  ["dockerfile.prod", "docker"],
  ["dockerfile.production", "docker"],
  ["dockerfile.alpha", "docker"],
  ["dockerfile.beta", "docker"],
  ["dockerfile.stage", "docker"],
  ["dockerfile.staging", "docker"],
  ["dockerfile.dev", "docker"],
  ["dockerfile.development", "docker"],
  ["dockerfile.local", "docker"],
  ["dockerfile.test", "docker"],
  ["dockerfile.testing", "docker"],
  ["dockerfile.ci", "docker"],
  ["dockerfile.web", "docker"],
  ["dockerfile.worker", "docker"],
  ["docker-compose.yml", "docker"],
  ["docker-compose.override.yml", "docker"],
  ["docker-compose.prod.yml", "docker"],
  ["docker-compose.production.yml", "docker"],
  ["docker-compose.alpha.yml", "docker"],
  ["docker-compose.beta.yml", "docker"],
  ["docker-compose.stage.yml", "docker"],
  ["docker-compose.staging.yml", "docker"],
  ["docker-compose.dev.yml", "docker"],
  ["docker-compose.development.yml", "docker"],
  ["docker-compose.local.yml", "docker"],
  ["docker-compose.test.yml", "docker"],
  ["docker-compose.testing.yml", "docker"],
  ["docker-compose.ci.yml", "docker"],
  ["docker-compose.web.yml", "docker"],
  ["docker-compose.worker.yml", "docker"],
  ["docker-compose.yaml", "docker"],
  ["docker-compose.override.yaml", "docker"],
  ["docker-compose.prod.yaml", "docker"],
  ["docker-compose.production.yaml", "docker"],
  ["docker-compose.alpha.yaml", "docker"],
  ["docker-compose.beta.yaml", "docker"],
  ["docker-compose.stage.yaml", "docker"],
  ["docker-compose.staging.yaml", "docker"],
  ["docker-compose.dev.yaml", "docker"],
  ["docker-compose.development.yaml", "docker"],
  ["docker-compose.local.yaml", "docker"],
  ["docker-compose.test.yaml", "docker"],
  ["docker-compose.testing.yaml", "docker"],
  ["docker-compose.ci.yaml", "docker"],
  ["docker-compose.web.yaml", "docker"],
  ["docker-compose.worker.yaml", "docker"],
  ["compose.yaml", "docker"],
  ["compose.override.yaml", "docker"],
  ["compose.prod.yaml", "docker"],
  ["compose.production.yaml", "docker"],
  ["compose.alpha.yaml", "docker"],
  ["compose.beta.yaml", "docker"],
  ["compose.stage.yaml", "docker"],
  ["compose.staging.yaml", "docker"],
  ["compose.dev.yaml", "docker"],
  ["compose.development.yaml", "docker"],
  ["compose.local.yaml", "docker"],
  ["compose.test.yaml", "docker"],
  ["compose.testing.yaml", "docker"],
  ["compose.ci.yaml", "docker"],
  ["compose.web.yaml", "docker"],
  ["compose.worker.yaml", "docker"],
  ["compose.yml", "docker"],
  ["compose.override.yml", "docker"],
  ["compose.prod.yml", "docker"],
  ["compose.production.yml", "docker"],
  ["compose.alpha.yml", "docker"],
  ["compose.beta.yml", "docker"],
  ["compose.stage.yml", "docker"],
  ["compose.staging.yml", "docker"],
  ["compose.dev.yml", "docker"],
  ["compose.development.yml", "docker"],
  ["compose.local.yml", "docker"],
  ["compose.test.yml", "docker"],
  ["compose.testing.yml", "docker"],
  ["compose.ci.yml", "docker"],
  ["compose.web.yml", "docker"],
  ["compose.worker.yml", "docker"],
  [".mailmap", "email"],
  [".graphqlconfig", "graphql"],
  [".graphqlrc", "graphql"],
  [".graphqlrc.json", "graphql"],
  [".graphqlrc.js", "graphql"],
  [".graphqlrc.cjs", "graphql"],
  [".graphqlrc.ts", "graphql"],
  [".graphqlrc.toml", "graphql"],
  [".graphqlrc.yaml", "graphql"],
  [".graphqlrc.yml", "graphql"],
  ["graphql.config.json", "graphql"],
  ["graphql.config.js", "graphql"],
  ["graphql.config.cjs", "graphql"],
  ["graphql.config.ts", "graphql"],
  ["graphql.config.toml", "graphql"],
  ["graphql.config.yaml", "graphql"],
  ["graphql.config.yml", "graphql"],
  [".git", "git"],
  [".gitignore", "git"],
  [".gitignore-global", "git"],
  [".gitignore_global", "git"],
  [".gitattributes", "git"],
  [".gitattributes-global", "git"],
  [".gitattributes_global", "git"],
  [".gitconfig", "git"],
  [".gitmodules", "git"],
  [".gitkeep", "git"],
  [".gitinclude", "git"],
  [".git-blame-ignore", "git"],
  [".git-blame-ignore-revs", "git"],
  [".git-for-windows-updater", "git"],
  ["git-history", "git"],
  [".luacheckrc", "lua"],
  [".Rhistory", "r"],
  [".pubignore", "dart"],
  ["cmakelists.txt", "cmake"],
  ["cmakecache.txt", "cmake"],
  ["vue.config.js", "vue-config"],
  ["vue.config.ts", "vue-config"],
  ["vetur.config.js", "vue-config"],
  ["vetur.config.ts", "vue-config"],
  ["volar.config.js", "vue-config"],
  ["nuxt.config.js", "nuxt"],
  ["nuxt.config.ts", "nuxt"],
  [".nuxtignore", "nuxt"],
  ["security.md", "lock"],
  ["security.txt", "lock"],
  ["security", "lock"],
  ["angular-cli.json", "angular"],
  [".angular-cli.json", "angular"],
  ["angular.json", "angular"],
  ["ng-package.json", "angular"],
  [".mjmlconfig", "mjml"],
  ["vercel.json", "vercel"],
  [".vercelignore", "vercel"],
  ["now.json", "vercel"],
  [".nowignore", "vercel"],
  ["verdaccio.yml", "verdaccio"],
  ["next.config.js", "next"],
  ["next.config.mjs", "next"],
  ["next.config.ts", "next"],
  ["next.config.mts", "next"],
  ["remix.config.js", "remix"],
  ["remix.config.ts", "remix"],
  ["artisan", "laravel"],
  [".vfl", "vfl"],
  [".kl", "kl"],
  ["postcss.config.js", "postcss"],
  ["postcss.config.cjs", "postcss"],
  ["postcss.config.ts", "postcss"],
  ["postcss.config.cts", "postcss"],
  [".postcssrc.js", "postcss"],
  [".postcssrc.cjs", "postcss"],
  [".postcssrc.ts", "postcss"],
  [".postcssrc.cts", "postcss"],
  [".postcssrc", "postcss"],
  [".postcssrc.json", "postcss"],
  [".postcssrc.yaml", "postcss"],
  [".postcssrc.yml", "postcss"],
  ["posthtml.config.js", "posthtml"],
  [".posthtmlrc.js", "posthtml"],
  [".posthtmlrc", "posthtml"],
  [".posthtmlrc.json", "posthtml"],
  [".posthtmlrc.yml", "posthtml"],
  ["todo.md", "todo"],
  ["todos.md", "todo"],
  ["cabal.project", "cabal"],
  ["cabal.project.freeze", "cabal"],
  ["cabal.project.local", "cabal"],
  ["CNAME", "http"],
  ["project.graphcool", "graphcool"],
  ["webpack.js", "webpack"],
  ["webpack.cjs", "webpack"],
  ["webpack.mjs", "webpack"],
  ["webpack.ts", "webpack"],
  ["webpack.cts", "webpack"],
  ["webpack.mts", "webpack"],
  ["webpack.base.js", "webpack"],
  ["webpack.base.cjs", "webpack"],
  ["webpack.base.mjs", "webpack"],
  ["webpack.base.ts", "webpack"],
  ["webpack.base.cts", "webpack"],
  ["webpack.base.mts", "webpack"],
  ["webpack.config.js", "webpack"],
  ["webpack.config.cjs", "webpack"],
  ["webpack.config.mjs", "webpack"],
  ["webpack.config.ts", "webpack"],
  ["webpack.config.cts", "webpack"],
  ["webpack.config.mts", "webpack"],
  ["webpack.common.js", "webpack"],
  ["webpack.common.cjs", "webpack"],
  ["webpack.common.mjs", "webpack"],
  ["webpack.common.ts", "webpack"],
  ["webpack.common.cts", "webpack"],
  ["webpack.common.mts", "webpack"],
  ["webpack.config.common.js", "webpack"],
  ["webpack.config.common.cjs", "webpack"],
  ["webpack.config.common.mjs", "webpack"],
  ["webpack.config.common.ts", "webpack"],
  ["webpack.config.common.cts", "webpack"],
  ["webpack.config.common.mts", "webpack"],
  ["webpack.config.common.babel.js", "webpack"],
  ["webpack.config.common.babel.ts", "webpack"],
  ["webpack.dev.js", "webpack"],
  ["webpack.dev.cjs", "webpack"],
  ["webpack.dev.mjs", "webpack"],
  ["webpack.dev.ts", "webpack"],
  ["webpack.dev.cts", "webpack"],
  ["webpack.dev.mts", "webpack"],
  ["webpack.development.js", "webpack"],
  ["webpack.development.cjs", "webpack"],
  ["webpack.development.mjs", "webpack"],
  ["webpack.development.ts", "webpack"],
  ["webpack.development.cts", "webpack"],
  ["webpack.development.mts", "webpack"],
  ["webpack.config.dev.js", "webpack"],
  ["webpack.config.dev.cjs", "webpack"],
  ["webpack.config.dev.mjs", "webpack"],
  ["webpack.config.dev.ts", "webpack"],
  ["webpack.config.dev.cts", "webpack"],
  ["webpack.config.dev.mts", "webpack"],
  ["webpack.config.dev.babel.js", "webpack"],
  ["webpack.config.dev.babel.ts", "webpack"],
  ["webpack.mix.js", "webpack"],
  ["webpack.mix.cjs", "webpack"],
  ["webpack.mix.mjs", "webpack"],
  ["webpack.mix.ts", "webpack"],
  ["webpack.mix.cts", "webpack"],
  ["webpack.mix.mts", "webpack"],
  ["webpack.prod.js", "webpack"],
  ["webpack.prod.cjs", "webpack"],
  ["webpack.prod.mjs", "webpack"],
  ["webpack.prod.ts", "webpack"],
  ["webpack.prod.cts", "webpack"],
  ["webpack.prod.mts", "webpack"],
  ["webpack.prod.config.js", "webpack"],
  ["webpack.prod.config.cjs", "webpack"],
  ["webpack.prod.config.mjs", "webpack"],
  ["webpack.prod.config.ts", "webpack"],
  ["webpack.prod.config.cts", "webpack"],
  ["webpack.prod.config.mts", "webpack"],
  ["webpack.production.js", "webpack"],
  ["webpack.production.cjs", "webpack"],
  ["webpack.production.mjs", "webpack"],
  ["webpack.production.ts", "webpack"],
  ["webpack.production.cts", "webpack"],
  ["webpack.production.mts", "webpack"],
  ["webpack.server.js", "webpack"],
  ["webpack.server.cjs", "webpack"],
  ["webpack.server.mjs", "webpack"],
  ["webpack.server.ts", "webpack"],
  ["webpack.server.cts", "webpack"],
  ["webpack.server.mts", "webpack"],
  ["webpack.client.js", "webpack"],
  ["webpack.client.cjs", "webpack"],
  ["webpack.client.mjs", "webpack"],
  ["webpack.client.ts", "webpack"],
  ["webpack.client.cts", "webpack"],
  ["webpack.client.mts", "webpack"],
  ["webpack.config.server.js", "webpack"],
  ["webpack.config.server.cjs", "webpack"],
  ["webpack.config.server.mjs", "webpack"],
  ["webpack.config.server.ts", "webpack"],
  ["webpack.config.server.cts", "webpack"],
  ["webpack.config.server.mts", "webpack"],
  ["webpack.config.client.js", "webpack"],
  ["webpack.config.client.cjs", "webpack"],
  ["webpack.config.client.mjs", "webpack"],
  ["webpack.config.client.ts", "webpack"],
  ["webpack.config.client.cts", "webpack"],
  ["webpack.config.client.mts", "webpack"],
  ["webpack.config.production.babel.js", "webpack"],
  ["webpack.config.production.babel.ts", "webpack"],
  ["webpack.config.prod.babel.js", "webpack"],
  ["webpack.config.prod.babel.cjs", "webpack"],
  ["webpack.config.prod.babel.mjs", "webpack"],
  ["webpack.config.prod.babel.ts", "webpack"],
  ["webpack.config.prod.babel.cts", "webpack"],
  ["webpack.config.prod.babel.mts", "webpack"],
  ["webpack.config.prod.js", "webpack"],
  ["webpack.config.prod.cjs", "webpack"],
  ["webpack.config.prod.mjs", "webpack"],
  ["webpack.config.prod.ts", "webpack"],
  ["webpack.config.prod.cts", "webpack"],
  ["webpack.config.prod.mts", "webpack"],
  ["webpack.config.production.js", "webpack"],
  ["webpack.config.production.cjs", "webpack"],
  ["webpack.config.production.mjs", "webpack"],
  ["webpack.config.production.ts", "webpack"],
  ["webpack.config.production.cts", "webpack"],
  ["webpack.config.production.mts", "webpack"],
  ["webpack.config.staging.js", "webpack"],
  ["webpack.config.staging.cjs", "webpack"],
  ["webpack.config.staging.mjs", "webpack"],
  ["webpack.config.staging.ts", "webpack"],
  ["webpack.config.staging.cts", "webpack"],
  ["webpack.config.staging.mts", "webpack"],
  ["webpack.config.babel.js", "webpack"],
  ["webpack.config.babel.ts", "webpack"],
  ["webpack.config.base.babel.js", "webpack"],
  ["webpack.config.base.babel.ts", "webpack"],
  ["webpack.config.base.js", "webpack"],
  ["webpack.config.base.cjs", "webpack"],
  ["webpack.config.base.mjs", "webpack"],
  ["webpack.config.base.ts", "webpack"],
  ["webpack.config.base.cts", "webpack"],
  ["webpack.config.base.mts", "webpack"],
  ["webpack.config.staging.babel.js", "webpack"],
  ["webpack.config.staging.babel.ts", "webpack"],
  ["webpack.config.coffee", "webpack"],
  ["webpack.config.test.js", "webpack"],
  ["webpack.config.test.cjs", "webpack"],
  ["webpack.config.test.mjs", "webpack"],
  ["webpack.config.test.ts", "webpack"],
  ["webpack.config.test.cts", "webpack"],
  ["webpack.config.test.mts", "webpack"],
  ["webpack.config.vendor.js", "webpack"],
  ["webpack.config.vendor.cjs", "webpack"],
  ["webpack.config.vendor.mjs", "webpack"],
  ["webpack.config.vendor.ts", "webpack"],
  ["webpack.config.vendor.cts", "webpack"],
  ["webpack.config.vendor.mts", "webpack"],
  ["webpack.config.vendor.production.js", "webpack"],
  ["webpack.config.vendor.production.cjs", "webpack"],
  ["webpack.config.vendor.production.mjs", "webpack"],
  ["webpack.config.vendor.production.ts", "webpack"],
  ["webpack.config.vendor.production.cts", "webpack"],
  ["webpack.config.vendor.production.mts", "webpack"],
  ["webpack.test.js", "webpack"],
  ["webpack.test.cjs", "webpack"],
  ["webpack.test.mjs", "webpack"],
  ["webpack.test.ts", "webpack"],
  ["webpack.test.cts", "webpack"],
  ["webpack.test.mts", "webpack"],
  ["webpack.dist.js", "webpack"],
  ["webpack.dist.cjs", "webpack"],
  ["webpack.dist.mjs", "webpack"],
  ["webpack.dist.ts", "webpack"],
  ["webpack.dist.cts", "webpack"],
  ["webpack.dist.mts", "webpack"],
  ["webpackfile.js", "webpack"],
  ["webpackfile.cjs", "webpack"],
  ["webpackfile.mjs", "webpack"],
  ["webpackfile.ts", "webpack"],
  ["webpackfile.cts", "webpack"],
  ["webpackfile.mts", "webpack"],
  ["ionic.config.json", "ionic"],
  [".io-config.json", "ionic"],
  ["gulpfile.js", "gulp"],
  ["gulpfile.mjs", "gulp"],
  ["gulpfile.ts", "gulp"],
  ["gulpfile.cts", "gulp"],
  ["gulpfile.mts", "gulp"],
  ["gulpfile.babel.js", "gulp"],
  ["package.json", "nodejs"],
  ["package-lock.json", "nodejs"],
  [".nvmrc", "nodejs"],
  [".esmrc", "nodejs"],
  [".node-version", "nodejs"],
  [".npmignore", "npm"],
  [".npmrc", "npm"],
  [".yarnrc", "yarn"],
  ["yarn.lock", "yarn"],
  [".yarnclean", "yarn"],
  [".yarn-integrity", "yarn"],
  ["yarn-error.log", "yarn"],
  [".yarnrc.yml", "yarn"],
  [".yarnrc.yaml", "yarn"],
  ["androidmanifest.xml", "android"],
  [".env.defaults", "tune"],
  [".env.example", "tune"],
  [".env.sample", "tune"],
  [".env.template", "tune"],
  [".env.schema", "tune"],
  [".env.local", "tune"],
  [".env.dev", "tune"],
  [".env.development", "tune"],
  [".env.alpha", "tune"],
  [".env.e2e", "tune"],
  [".env.qa", "tune"],
  [".env.dist", "tune"],
  [".env.prod", "tune"],
  [".env.production", "tune"],
  [".env.stage", "tune"],
  [".env.staging", "tune"],
  [".env.preview", "tune"],
  [".env.test", "tune"],
  [".env.testing", "tune"],
  [".env.development.local", "tune"],
  [".env.qa.local", "tune"],
  [".env.production.local", "tune"],
  [".env.staging.local", "tune"],
  [".env.test.local", "tune"],
  [".env.uat", "tune"],
  ["turbo.json", "turborepo"],
  [".babelrc", "babel"],
  [".babelrc.cjs", "babel"],
  [".babelrc.js", "babel"],
  [".babelrc.mjs", "babel"],
  [".babelrc.json", "babel"],
  ["babel.config.cjs", "babel"],
  ["babel.config.js", "babel"],
  ["babel.config.mjs", "babel"],
  ["babel.config.json", "babel"],
  ["babel-transform.js", "babel"],
  [".babel-plugin-macrosrc", "babel"],
  [".babel-plugin-macrosrc.json", "babel"],
  [".babel-plugin-macrosrc.yaml", "babel"],
  [".babel-plugin-macrosrc.yml", "babel"],
  [".babel-plugin-macrosrc.js", "babel"],
  ["babel-plugin-macros.config.js", "babel"],
  ["blitz.config.js", "blitz"],
  ["blitz.config.ts", "blitz"],
  [".blitz.config.compiled.js", "blitz"],
  ["contributing.md", "contributing"],
  ["contributing.rst", "contributing"],
  ["contributing.txt", "contributing"],
  ["contributing", "contributing"],
  ["readme.md", "readme"],
  ["readme.rst", "readme"],
  ["readme.txt", "readme"],
  ["readme", "readme"],
  ["changelog", "changelog"],
  ["changelog.md", "changelog"],
  ["changelog.rst", "changelog"],
  ["changelog.txt", "changelog"],
  ["changes", "changelog"],
  ["changes.md", "changelog"],
  ["changes.rst", "changelog"],
  ["changes.txt", "changelog"],
  ["architecture.md", "architecture"],
  ["architecture.rst", "architecture"],
  ["architecture.txt", "architecture"],
  ["architecture", "architecture"],
  ["credits.md", "credits"],
  ["credits.rst", "credits"],
  ["credits.txt", "credits"],
  ["credits", "credits"],
  ["authors.md", "authors"],
  ["authors.rst", "authors"],
  ["authors.txt", "authors"],
  ["authors", "authors"],
  ["contributors.md", "authors"],
  ["contributors.rst", "authors"],
  ["contributors.txt", "authors"],
  ["contributors", "authors"],
  [".flowconfig", "flow"],
  ["favicon.ico", "favicon"],
  ["karma.conf.js", "karma"],
  ["karma.conf.ts", "karma"],
  ["karma.conf.coffee", "karma"],
  ["karma.config.js", "karma"],
  ["karma.config.ts", "karma"],
  ["karma-main.js", "karma"],
  ["karma-main.ts", "karma"],
  [".bithoundrc", "bithound"],
  ["svgo.config.js", "svgo"],
  ["svgo.config.cjs", "svgo"],
  ["svgo.config.mjs", "svgo"],
  [".appveyor.yml", "appveyor"],
  ["appveyor.yml", "appveyor"],
  [".travis.yml", "travis"],
  [".codecov.yml", "codecov"],
  ["codecov.yml", "codecov"],
  ["sonar-project.properties", "sonarcloud"],
  [".sonarcloud.properties", "sonarcloud"],
  ["sonarcloud.yaml", "sonarcloud"],
  ["protractor.conf.js", "protractor"],
  ["protractor.conf.ts", "protractor"],
  ["protractor.conf.coffee", "protractor"],
  ["protractor.config.js", "protractor"],
  ["protractor.config.ts", "protractor"],
  ["fuse.js", "fusebox"],
  ["procfile", "heroku"],
  ["procfile.windows", "heroku"],
  [".editorconfig", "editorconfig"],
  [".bowerrc", "bower"],
  ["bower.json", "bower"],
  [".eslintrc.js", "eslint"],
  [".eslintrc.cjs", "eslint"],
  [".eslintrc.yaml", "eslint"],
  [".eslintrc.yml", "eslint"],
  [".eslintrc.json", "eslint"],
  [".eslintrc-md.js", "eslint"],
  [".eslintrc-jsdoc.js", "eslint"],
  [".eslintrc", "eslint"],
  [".eslintignore", "eslint"],
  [".eslintcache", "eslint"],
  ["eslint.config.js", "eslint"],
  ["eslint.config.mjs", "eslint"],
  ["code_of_conduct.md", "conduct"],
  ["code_of_conduct.txt", "conduct"],
  ["code_of_conduct", "conduct"],
  [".watchmanconfig", "watchman"],
  ["aurelia.json", "aurelia"],
  [".autorc", "auto"],
  ["auto.config.js", "auto"],
  ["auto.config.ts", "auto"],
  ["auto-config.json", "auto"],
  ["auto-config.yaml", "auto"],
  ["auto-config.yml", "auto"],
  ["auto-config.ts", "auto"],
  ["auto-config.js", "auto"],
  ["mocha.opts", "mocha"],
  [".mocharc.yml", "mocha"],
  [".mocharc.yaml", "mocha"],
  [".mocharc.js", "mocha"],
  [".mocharc.json", "mocha"],
  [".mocharc.jsonc", "mocha"],
  ["jenkinsfile", "jenkins"],
  ["firebase.json", "firebase"],
  [".firebaserc", "firebase"],
  ["firestore.rules", "firebase"],
  ["firestore.indexes.json", "firebase"],
  ["rollup.config.js", "rollup"],
  ["rollup.config.mjs", "rollup"],
  ["rollup.config.ts", "rollup"],
  ["rollup-config.js", "rollup"],
  ["rollup-config.mjs", "rollup"],
  ["rollup-config.ts", "rollup"],
  ["rollup.config.common.js", "rollup"],
  ["rollup.config.common.mjs", "rollup"],
  ["rollup.config.common.ts", "rollup"],
  ["rollup.config.base.js", "rollup"],
  ["rollup.config.base.mjs", "rollup"],
  ["rollup.config.base.ts", "rollup"],
  ["rollup.config.prod.js", "rollup"],
  ["rollup.config.prod.mjs", "rollup"],
  ["rollup.config.prod.ts", "rollup"],
  ["rollup.config.dev.js", "rollup"],
  ["rollup.config.dev.mjs", "rollup"],
  ["rollup.config.dev.ts", "rollup"],
  ["rollup.config.prod.vendor.js", "rollup"],
  ["rollup.config.prod.vendor.mjs", "rollup"],
  ["rollup.config.prod.vendor.ts", "rollup"],
  [".hhconfig", "hack"],
  ["hardhat.config.js", "hardhat"],
  ["hardhat.config.ts", "hardhat"],
  [".stylelintrc", "stylelint"],
  ["stylelint.config.js", "stylelint"],
  ["stylelint.config.cjs", "stylelint"],
  [".stylelintrc.json", "stylelint"],
  [".stylelintrc.yaml", "stylelint"],
  [".stylelintrc.yml", "stylelint"],
  [".stylelintrc.js", "stylelint"],
  [".stylelintrc.cjs", "stylelint"],
  [".stylelintignore", "stylelint"],
  [".stylelintcache", "stylelint"],
  [".codeclimate.yml", "code-climate"],
  [".prettierrc", "prettier"],
  ["prettier.config.js", "prettier"],
  ["prettier.config.cjs", "prettier"],
  [".prettierrc.js", "prettier"],
  [".prettierrc.cjs", "prettier"],
  [".prettierrc.json", "prettier"],
  [".prettierrc.json5", "prettier"],
  [".prettierrc.yaml", "prettier"],
  [".prettierrc.yml", "prettier"],
  [".prettierignore", "prettier"],
  [".prettierrc.toml", "prettier"],
  [".renovaterc", "renovate"],
  [".renovaterc.json", "renovate"],
  ["renovate-config.json", "renovate"],
  ["renovate.json", "renovate"],
  ["renovate.json5", "renovate"],
  ["apollo.config.js", "apollo"],
  ["nodemon.json", "nodemon"],
  ["nodemon-debug.json", "nodemon"],
  [".hintrc", "webhint"],
  ["browserslist", "browserlist"],
  [".browserslistrc", "browserlist"],
  [".snyk", "snyk"],
  [".drone.yml", "drone"],
  [".sequelizerc", "sequelize"],
  ["gatsby-config.js", "gatsby"],
  ["gatsby-config.mjs", "gatsby"],
  ["gatsby-config.ts", "gatsby"],
  ["gatsby-node.js", "gatsby"],
  ["gatsby-node.mjs", "gatsby"],
  ["gatsby-node.ts", "gatsby"],
  ["gatsby-browser.js", "gatsby"],
  ["gatsby-browser.tsx", "gatsby"],
  ["gatsby-ssr.js", "gatsby"],
  ["gatsby-ssr.tsx", "gatsby"],
  [".wakatime-project", "wakatime"],
  ["circle.yml", "circleci"],
  [".cfignore", "cloudfoundry"],
  ["gruntfile.js", "grunt"],
  ["gruntfile.ts", "grunt"],
  ["gruntfile.coffee", "grunt"],
  ["gruntfile.babel.js", "grunt"],
  ["gruntfile.babel.ts", "grunt"],
  ["gruntfile.babel.coffee", "grunt"],
  ["jest.config.js", "jest"],
  ["jest.config.cjs", "jest"],
  ["jest.config.mjs", "jest"],
  ["jest.config.ts", "jest"],
  ["jest.config.cts", "jest"],
  ["jest.config.mts", "jest"],
  ["jest.config.json", "jest"],
  ["jest.e2e.config.js", "jest"],
  ["jest.e2e.config.cjs", "jest"],
  ["jest.e2e.config.mjs", "jest"],
  ["jest.e2e.config.ts", "jest"],
  ["jest.e2e.config.cts", "jest"],
  ["jest.e2e.config.mts", "jest"],
  ["jest.e2e.config.json", "jest"],
  ["jest.e2e.json", "jest"],
  ["jest-unit.config.js", "jest"],
  ["jest-e2e.config.js", "jest"],
  ["jest-e2e.config.cjs", "jest"],
  ["jest-e2e.config.mjs", "jest"],
  ["jest-e2e.config.ts", "jest"],
  ["jest-e2e.config.cts", "jest"],
  ["jest-e2e.config.mts", "jest"],
  ["jest-e2e.config.json", "jest"],
  ["jest-e2e.json", "jest"],
  ["jest-github-actions-reporter.js", "jest"],
  ["jest.setup.js", "jest"],
  ["jest.setup.ts", "jest"],
  ["jest.json", "jest"],
  [".jestrc", "jest"],
  [".jestrc.js", "jest"],
  [".jestrc.json", "jest"],
  ["jest.teardown.js", "jest"],
  ["fastfile", "fastlane"],
  ["appfile", "fastlane"],
  [".helmignore", "helm"],
  ["wallaby.js", "wallaby"],
  ["wallaby.conf.js", "wallaby"],
  ["stencil.config.js", "stencil"],
  ["stencil.config.ts", "stencil"],
  ["makefile", "makefile"],
  ["gnumakefile", "makefile"],
  ["kbuild", "makefile"],
  [".releaserc", "semantic-release"],
  [".releaserc.yaml", "semantic-release"],
  [".releaserc.yml", "semantic-release"],
  [".releaserc.json", "semantic-release"],
  [".releaserc.js", "semantic-release"],
  ["release.config.js", "semantic-release"],
  ["release.config.cjs", "semantic-release"],
  ["bitbucket-pipelines.yaml", "bitbucket"],
  ["bitbucket-pipelines.yml", "bitbucket"],
  [".bazelignore", "bazel"],
  [".bazelrc", "bazel"],
  [".bazelversion", "bazel"],
  [".gdignore", "godot-assets"],
  ["._sc_", "godot-assets"],
  ["_sc_", "godot-assets"],
  ["azure-pipelines.yml", "azure-pipelines"],
  ["azure-pipelines.yaml", "azure-pipelines"],
  ["vagrantfile", "vagrant"],
  ["prisma.yml", "prisma"],
  [".nycrc", "istanbul"],
  [".nycrc.json", "istanbul"],
  ["tailwind.js", "tailwindcss"],
  ["tailwind.ts", "tailwindcss"],
  ["tailwind.config.js", "tailwindcss"],
  ["tailwind.config.cjs", "tailwindcss"],
  ["tailwind.config.ts", "tailwindcss"],
  ["tailwind.config.cts", "tailwindcss"],
  ["buildkite.yml", "buildkite"],
  ["buildkite.yaml", "buildkite"],
  ["netlify.json", "netlify"],
  ["netlify.yml", "netlify"],
  ["netlify.yaml", "netlify"],
  ["netlify.toml", "netlify"],
  ["svelte.config.js", "svelte"],
  ["svelte.config.cjs", "svelte"],
  ["nest-cli.json", "nest"],
  [".nest-cli.json", "nest"],
  ["nestconfig.json", "nest"],
  [".nestconfig.json", "nest"],
  [".percy.yml", "percy"],
  [".gitpod.yml", "gitpod"],
  ["codeowners", "codeowners"],
  [".gcloudignore", "gcp"],
  [".huskyrc", "husky"],
  ["husky.config.js", "husky"],
  [".huskyrc.json", "husky"],
  [".huskyrc.js", "husky"],
  [".huskyrc.yaml", "husky"],
  [".huskyrc.yml", "husky"],
  ["tiltfile", "tilt"],
  ["capacitor.config.json", "capacitor"],
  ["capacitor.config.ts", "capacitor"],
  [".adonisrc.json", "adonis"],
  ["ace", "adonis"],
  ["meson.build", "meson"],
  ["meson_options.txt", "meson"],
  [".commitlintrc", "commitlint"],
  [".commitlintrc.js", "commitlint"],
  [".commitlintrc.cjs", "commitlint"],
  [".commitlintrc.ts", "commitlint"],
  [".commitlintrc.cts", "commitlint"],
  [".commitlintrc.json", "commitlint"],
  [".commitlintrc.yaml", "commitlint"],
  [".commitlintrc.yml", "commitlint"],
  [".commitlint.yaml", "commitlint"],
  [".commitlint.yml", "commitlint"],
  ["commitlint.config.js", "commitlint"],
  ["commitlint.config.cjs", "commitlint"],
  ["commitlint.config.ts", "commitlint"],
  ["commitlint.config.cts", "commitlint"],
  [".buckconfig", "buck"],
  ["nx.json", "nrwl"],
  [".nxignore", "nrwl"],
  ["dune", "dune"],
  ["dune-project", "dune"],
  ["dune-workspace", "dune"],
  ["dune-workspace.dev", "dune"],
  ["roadmap.md", "roadmap"],
  ["roadmap.txt", "roadmap"],
  ["timeline.md", "roadmap"],
  ["timeline.txt", "roadmap"],
  ["milestones.md", "roadmap"],
  ["milestones.txt", "roadmap"],
  ["nuget.config", "nuget"],
  [".nuspec", "nuget"],
  ["nuget.exe", "nuget"],
  ["stryker.conf.json", "stryker"],
  ["stryker.conf.js", "stryker"],
  ["stryker.conf.cjs", "stryker"],
  ["stryker.conf.mjs", "stryker"],
  [".stryker.conf.json", "stryker"],
  [".stryker.conf.js", "stryker"],
  [".stryker.conf.cjs", "stryker"],
  [".stryker.conf.mjs", "stryker"],
  [".modernizrrc", "modernizr"],
  [".modernizrrc.js", "modernizr"],
  [".modernizrrc.json", "modernizr"],
  [".slugignore", "slug"],
  ["stitches.config.js", "stitches"],
  ["stitches.config.ts", "stitches"],
  ["nginx.conf", "nginx"],
  [".mcattributes", "minecraft"],
  [".mcdefinitions", "minecraft"],
  [".mcignore", "minecraft"],
  [".replit", "replit"],
  ["snowpack.config.js", "snowpack"],
  ["snowpack.config.cjs", "snowpack"],
  ["snowpack.config.mjs", "snowpack"],
  ["snowpack.config.ts", "snowpack"],
  ["snowpack.config.cts", "snowpack"],
  ["snowpack.config.mts", "snowpack"],
  ["snowpack.deps.json", "snowpack"],
  ["snowpack.config.json", "snowpack"],
  ["quasar.conf.js", "quasar"],
  ["quasar.config.js", "quasar"],
  ["dependabot.yml", "dependabot"],
  ["dependabot.yaml", "dependabot"],
  ["vite.config.js", "vite"],
  ["vite.config.mjs", "vite"],
  ["vite.config.cjs", "vite"],
  ["vite.config.ts", "vite"],
  ["vite.config.cts", "vite"],
  ["vite.config.mts", "vite"],
  ["vitest.config.ts", "vitest"],
  ["vitest.config.mts", "vitest"],
  ["vitest.config.cts", "vitest"],
  ["vitest.config.js", "vitest"],
  ["vitest.config.mjs", "vitest"],
  ["vitest.config.cjs", "vitest"],
  ["lerna.json", "lerna"],
  ["windi.config.js", "windicss"],
  ["windi.config.cjs", "windicss"],
  ["windi.config.ts", "windicss"],
  ["windi.config.cts", "windicss"],
  ["windi.config.json", "windicss"],
  [".textlintrc", "textlint"],
  ["vpkg.json", "vlang"],
  ["v.mod", "vlang"],
  [".sentryclirc", "sentry"],
  [".phpunit.result.cache", "phpunit"],
  [".phpunit-watcher.yml", "phpunit"],
  ["phpunit.xml", "phpunit"],
  ["phpunit.xml.dist", "phpunit"],
  ["phpunit-watcher.yml", "phpunit"],
  ["phpunit-watcher.yml.dist", "phpunit"],
  [".php_cs", "php-cs-fixer"],
  [".php_cs.dist", "php-cs-fixer"],
  [".php_cs.php", "php-cs-fixer"],
  [".php_cs.dist.php", "php-cs-fixer"],
  [".php-cs-fixer.php", "php-cs-fixer"],
  [".php-cs-fixer.dist.php", "php-cs-fixer"],
  ["robots.txt", "robots"],
  ["tsconfig.json", "tsconfig"],
  ["tsconfig.app.json", "tsconfig"],
  ["tsconfig.editor.json", "tsconfig"],
  ["tsconfig.spec.json", "tsconfig"],
  ["tsconfig.base.json", "tsconfig"],
  ["tsconfig.build.json", "tsconfig"],
  ["tsconfig.eslint.json", "tsconfig"],
  ["tsconfig.lib.json", "tsconfig"],
  ["tsconfig.lib.prod.json", "tsconfig"],
  ["tsconfig.node.json", "tsconfig"],
  ["tsconfig.test.json", "tsconfig"],
  ["tsconfig.e2e.json", "tsconfig"],
  ["tsconfig.web.json", "tsconfig"],
  ["tsconfig.webworker.json", "tsconfig"],
  ["tsconfig.worker.json", "tsconfig"],
  ["tsconfig.config.json", "tsconfig"],
  ["tsconfig.vitest.json", "tsconfig"],
  ["tsconfig.cjs.json", "tsconfig"],
  ["tsconfig.esm.json", "tsconfig"],
  ["tsconfig.mjs.json", "tsconfig"],
  ["tsconfig.doc.json", "tsconfig"],
  ["tsconfig.paths.json", "tsconfig"],
  ["tauri.conf.json", "tauri"],
  ["tauri.config.json", "tauri"],
  ["tauri.linux.conf.json", "tauri"],
  ["tauri.windows.conf.json", "tauri"],
  ["tauri.macos.conf.json", "tauri"],
  ["jsconfig.json", "jsconfig"],
  ["maven.config", "maven"],
  ["jvm.config", "maven"],
  ["pom.xml", "maven"],
  ["serverless.yml", "serverless"],
  ["serverless.yaml", "serverless"],
  ["serverless.json", "serverless"],
  ["serverless.js", "serverless"],
  ["serverless.ts", "serverless"],
  ["supabase.js", "supabase"],
  ["supabase.py", "supabase"],
  [".ember-cli", "ember"],
  [".ember-cli.js", "ember"],
  ["ember-cli-builds.js", "ember"],
  ["horusec-config.json", "horusec"],
  ["poetry.lock", "poetry"],
  [".parcelrc", "parcel"],
  [".astylerc", "astyle"],
  [".lighthouserc.js", "lighthouse"],
  ["lighthouserc.js", "lighthouse"],
  [".lighthouserc.json", "lighthouse"],
  ["lighthouserc.json", "lighthouse"],
  [".lighthouserc.yml", "lighthouse"],
  ["lighthouserc.yml", "lighthouse"],
  [".lighthouserc.yaml", "lighthouse"],
  ["lighthouserc.yaml", "lighthouse"],
  [".svgrrc", "svgr"],
  ["svgr.config.js", "svgr"],
  [".svgrrc.js", "svgr"],
  [".svgrrc.yaml", "svgr"],
  [".svgrrc.yml", "svgr"],
  [".svgrrc.json", "svgr"],
  ["rome.json", "rome"],
  ["cypress.json", "cypress"],
  ["cypress.env.json", "cypress"],
  ["cypress.config.ts", "cypress"],
  ["cypress.config.js", "cypress"],
  ["cypress.config.cjs", "cypress"],
  ["cypress.config.mjs", "cypress"],
  ["plopfile.js", "plop"],
  ["plopfile.cjs", "plop"],
  ["plopfile.mjs", "plop"],
  ["plopfile.ts", "plop"],
  [".tobimake", "tobimake"],
  ["gleam.toml", "gleam"],
  ["pnpm-lock.yaml", "pnpm"],
  ["pnpm-workspace.yaml", "pnpm"],
  [".pnpmfile.cjs", "pnpm"],
  ["gridsome.config.js", "gridsome"],
  ["gridsome.server.js", "gridsome"],
  [".steadybit.yml", "steadybit"],
  ["steadybit.yml", "steadybit"],
  [".steadybit.yaml", "steadybit"],
  ["steadybit.yaml", "steadybit"],
  ["Caddyfile", "caddy"],
  ["bun.lockb", "bun"],
  [".nano-staged.js", "nano-staged"],
  ["nano-staged.js", "nano-staged"],
  [".nano-staged.cjs", "nano-staged"],
  ["nano-staged.cjs", "nano-staged"],
  [".nano-staged.mjs", "nano-staged"],
  ["nano-staged.mjs", "nano-staged"],
  [".nano-staged.json", "nano-staged"],
  ["nano-staged.json", "nano-staged"],
  [".nanostagedrc", "nano-staged"],
  ["craco.config.ts", "craco"],
  ["craco.config.js", "craco"],
  ["craco.config.cjs", "craco"],
  [".cracorc.ts", "craco"],
  [".cracorc.js", "craco"],
  [".cracorc", "craco"],
  [".hg", "mercurial"],
  [".hgignore", "mercurial"],
  [".hgflow", "mercurial"],
  [".hgrc", "mercurial"],
  ["hgrc", "mercurial"],
  ["mercurial.ini", "mercurial"],
  ["deno.json", "deno"],
  ["deno.jsonc", "deno"],
  ["deno.lock", "deno"],
  ["plastic.branchexplorer", "plastic"],
  ["plastic.selector", "plastic"],
  ["plastic.wktree", "plastic"],
  ["plastic.workspace", "plastic"],
  ["plastic.workspaces", "plastic"],
  [".mincloudrc", "ifanr-cloud"],
]);