export const fileExtensionIconMap = new Map<string,string>([
  ["htm", "html"],
  ["xhtml", "html"],
  ["html_vm", "html"],
  ["asp", "html"],
  ["jade", "pug"],
  ["pug", "pug"],
  ["md", "markdown"],
  ["markdown", "markdown"],
  ["rst", "markdown"],
  ["blink", "blink"],
  ["css", "css"],
  ["scss", "sass"],
  ["sass", "sass"],
  ["less", "less"],
  ["json", "json"],
  ["jsonc", "json"],
  ["tsbuildinfo", "json"],
  ["json5", "json"],
  ["jsonl", "json"],
  ["ndjson", "json"],
  ["jinja", "jinja"],
  ["jinja2", "jinja"],
  ["j2", "jinja"],
  ["jinja-html", "jinja"],
  ["proto", "proto"],
  ["sublime-project", "sublime"],
  ["sublime-workspace", "sublime"],
  ["tw", "twine"],
  ["twee", "twine"],
  ["yml", "yaml"],
  ["yaml", "yaml"],
  ["yml.dist", "yaml"],
  ["yaml.dist", "yaml"],
  ["YAML-tmLanguage", "yaml"],
  ["xml", "xml"],
  ["plist", "xml"],
  ["xsd", "xml"],
  ["dtd", "xml"],
  ["xsl", "xml"],
  ["xslt", "xml"],
  ["resx", "xml"],
  ["iml", "xml"],
  ["xquery", "xml"],
  ["tmLanguage", "xml"],
  ["manifest", "xml"],
  ["project", "xml"],
  ["xml.dist", "xml"],
  ["xml.dist.sample", "xml"],
  ["dmn", "xml"],
  ["jrxml", "xml"],
  ["png", "image"],
  ["jpeg", "image"],
  ["jpg", "image"],
  ["gif", "image"],
  ["ico", "image"],
  ["tif", "image"],
  ["tiff", "image"],
  ["psd", "image"],
  ["psb", "image"],
  ["ami", "image"],
  ["apx", "image"],
  ["avif", "image"],
  ["bmp", "image"],
  ["bpg", "image"],
  ["brk", "image"],
  ["cur", "image"],
  ["dds", "image"],
  ["dng", "image"],
  ["exr", "image"],
  ["fpx", "image"],
  ["gbr", "image"],
  ["img", "image"],
  ["jbig2", "image"],
  ["jb2", "image"],
  ["jng", "image"],
  ["jxr", "image"],
  ["pgf", "image"],
  ["pic", "image"],
  ["raw", "image"],
  ["webp", "image"],
  ["eps", "image"],
  ["afphoto", "image"],
  ["ase", "image"],
  ["aseprite", "image"],
  ["clip", "image"],
  ["cpt", "image"],
  ["heif", "image"],
  ["heic", "image"],
  ["kra", "image"],
  ["mdp", "image"],
  ["ora", "image"],
  ["pdn", "image"],
  ["reb", "image"],
  ["sai", "image"],
  ["tga", "image"],
  ["xcf", "image"],
  ["jfif", "image"],
  ["ppm", "image"],
  ["pbm", "image"],
  ["pgm", "image"],
  ["pnm", "image"],
  ["icns", "image"],
  ["esx", "javascript"],
  ["mjs", "javascript"],
  ["jsx", "react"],
  ["tsx", "react_ts"],
  ["routing.ts", "routing"],
  ["routing.tsx", "routing"],
  ["routing.js", "routing"],
  ["routing.jsx", "routing"],
  ["routes.ts", "routing"],
  ["routes.tsx", "routing"],
  ["routes.js", "routing"],
  ["routes.jsx", "routing"],
  ["ini", "settings"],
  ["dlc", "settings"],
  ["dll", "settings"],
  ["config", "settings"],
  ["conf", "settings"],
  ["properties", "settings"],
  ["prop", "settings"],
  ["settings", "settings"],
  ["option", "settings"],
  ["props", "settings"],
  ["toml", "settings"],
  ["prefs", "settings"],
  ["sln.dotsettings", "settings"],
  ["sln.dotsettings.user", "settings"],
  ["cfg", "settings"],
  ["d.ts", "typescript-def"],
  ["d.cts", "typescript-def"],
  ["d.mts", "typescript-def"],
  ["marko", "markojs"],
  ["astro", "astro"],
  ["pdf", "pdf"],
  ["xlsx", "table"],
  ["xlsm", "table"],
  ["xls", "table"],
  ["csv", "table"],
  ["tsv", "table"],
  ["psv", "table"],
  ["ods", "table"],
  ["vscodeignore", "vscode"],
  ["vsixmanifest", "vscode"],
  ["vsix", "vscode"],
  ["code-workplace", "vscode"],
  ["code-workspace", "vscode"],
  ["code-profile", "vscode"],
  ["code-snippets", "vscode"],
  ["csproj", "visualstudio"],
  ["ruleset", "visualstudio"],
  ["sln", "visualstudio"],
  ["suo", "visualstudio"],
  ["vb", "visualstudio"],
  ["vbs", "visualstudio"],
  ["vcxitems", "visualstudio"],
  ["vcxitems.filters", "visualstudio"],
  ["vcxproj", "visualstudio"],
  ["vcxproj.filters", "visualstudio"],
  ["pdb", "database"],
  ["sql", "database"],
  ["pks", "database"],
  ["pkb", "database"],
  ["accdb", "database"],
  ["mdb", "database"],
  ["sqlite", "database"],
  ["sqlite3", "database"],
  ["pgsql", "database"],
  ["postgres", "database"],
  ["psql", "database"],
  ["db", "database"],
  ["db3", "database"],
  ["kql", "kusto"],
  ["cs", "csharp"],
  ["csx", "csharp"],
  ["qs", "qsharp"],
  ["zip", "zip"],
  ["tar", "zip"],
  ["gz", "zip"],
  ["xz", "zip"],
  ["lzma", "zip"],
  ["lz4", "zip"],
  ["br", "zip"],
  ["bz2", "zip"],
  ["bzip2", "zip"],
  ["gzip", "zip"],
  ["brotli", "zip"],
  ["7z", "zip"],
  ["rar", "zip"],
  ["tz", "zip"],
  ["txz", "zip"],
  ["tgz", "zip"],
  ["vala", "vala"],
  ["zig", "zig"],
  ["exe", "exe"],
  ["msi", "exe"],
  ["dat", "hex"],
  ["bin", "hex"],
  ["hex", "hex"],
  ["java", "java"],
  ["jsp", "java"],
  ["jar", "jar"],
  ["class", "javaclass"],
  ["c", "c"],
  ["i", "c"],
  ["mi", "c"],
  ["h", "h"],
  ["cc", "cpp"],
  ["cpp", "cpp"],
  ["cxx", "cpp"],
  ["c++", "cpp"],
  ["cp", "cpp"],
  ["mii", "cpp"],
  ["ii", "cpp"],
  ["hh", "hpp"],
  ["hpp", "hpp"],
  ["hxx", "hpp"],
  ["h++", "hpp"],
  ["hp", "hpp"],
  ["tcc", "hpp"],
  ["inl", "hpp"],
  ["m", "objective-c"],
  ["mm", "objective-cpp"],
  ["rc", "rc"],
  ["go", "go"],
  ["py", "python"],
  ["pyc", "python-misc"],
  ["whl", "python-misc"],
  ["url", "url"],
  ["sh", "console"],
  ["ksh", "console"],
  ["csh", "console"],
  ["tcsh", "console"],
  ["zsh", "console"],
  ["bash", "console"],
  ["bat", "console"],
  ["cmd", "console"],
  ["awk", "console"],
  ["fish", "console"],
  ["exp", "console"],
  ["nu", "console"],
  ["ps1", "powershell"],
  ["psm1", "powershell"],
  ["psd1", "powershell"],
  ["ps1xml", "powershell"],
  ["psc1", "powershell"],
  ["pssc", "powershell"],
  ["gradle", "gradle"],
  ["doc", "word"],
  ["docx", "word"],
  ["rtf", "word"],
  ["odt", "word"],
  ["cer", "certificate"],
  ["cert", "certificate"],
  ["crt", "certificate"],
  ["pub", "key"],
  ["key", "key"],
  ["pem", "key"],
  ["asc", "key"],
  ["gpg", "key"],
  ["passwd", "key"],
  ["woff", "font"],
  ["woff2", "font"],
  ["ttf", "font"],
  ["eot", "font"],
  ["suit", "font"],
  ["otf", "font"],
  ["bmap", "font"],
  ["fnt", "font"],
  ["odttf", "font"],
  ["ttc", "font"],
  ["font", "font"],
  ["fonts", "font"],
  ["sui", "font"],
  ["ntf", "font"],
  ["mrf", "font"],
  ["lib", "lib"],
  ["bib", "lib"],
  ["rb", "ruby"],
  ["erb", "ruby"],
  ["fs", "fsharp"],
  ["fsx", "fsharp"],
  ["fsi", "fsharp"],
  ["fsproj", "fsharp"],
  ["swift", "swift"],
  ["ino", "arduino"],
  ["dockerignore", "docker"],
  ["dockerfile", "docker"],
  ["docker-compose.yml", "docker"],
  ["docker-compose.yaml", "docker"],
  ["compose.yaml", "docker"],
  ["compose.yml", "docker"],
  ["tex", "tex"],
  ["sty", "tex"],
  ["dtx", "tex"],
  ["ltx", "tex"],
  ["pptx", "powerpoint"],
  ["ppt", "powerpoint"],
  ["pptm", "powerpoint"],
  ["potx", "powerpoint"],
  ["potm", "powerpoint"],
  ["ppsx", "powerpoint"],
  ["ppsm", "powerpoint"],
  ["pps", "powerpoint"],
  ["ppam", "powerpoint"],
  ["ppa", "powerpoint"],
  ["odp", "powerpoint"],
  ["webm", "video"],
  ["mkv", "video"],
  ["flv", "video"],
  ["vob", "video"],
  ["ogv", "video"],
  ["ogg", "video"],
  ["gifv", "video"],
  ["avi", "video"],
  ["mov", "video"],
  ["qt", "video"],
  ["wmv", "video"],
  ["yuv", "video"],
  ["rm", "video"],
  ["rmvb", "video"],
  ["mp4", "video"],
  ["m4v", "video"],
  ["mpg", "video"],
  ["mp2", "video"],
  ["mpeg", "video"],
  ["mpe", "video"],
  ["mpv", "video"],
  ["m2v", "video"],
  ["vdi", "virtual"],
  ["vbox", "virtual"],
  ["vbox-prev", "virtual"],
  ["ics", "email"],
  ["mp3", "audio"],
  ["flac", "audio"],
  ["m4a", "audio"],
  ["wma", "audio"],
  ["aiff", "audio"],
  ["wav", "audio"],
  ["coffee", "coffee"],
  ["cson", "coffee"],
  ["iced", "coffee"],
  ["txt", "document"],
  ["graphql", "graphql"],
  ["gql", "graphql"],
  ["rs", "rust"],
  ["ron", "rust"],
  ["raml", "raml"],
  ["xaml", "xaml"],
  ["hs", "haskell"],
  ["kt", "kotlin"],
  ["kts", "kotlin"],
  ["otne", "otne"],
  ["patch", "git"],
  ["lua", "lua"],
  ["clj", "clojure"],
  ["cljs", "clojure"],
  ["cljc", "clojure"],
  ["groovy", "groovy"],
  ["r", "r"],
  ["rmd", "r"],
  ["dart", "dart"],
  ["freezed.dart", "dart_generated"],
  ["g.dart", "dart_generated"],
  ["as", "actionscript"],
  ["mxml", "mxml"],
  ["ahk", "autohotkey"],
  ["swf", "flash"],
  ["swc", "swc"],
  ["cmake", "cmake"],
  ["asm", "assembly"],
  ["a51", "assembly"],
  ["inc", "assembly"],
  ["nasm", "assembly"],
  ["s", "assembly"],
  ["ms", "assembly"],
  ["agc", "assembly"],
  ["ags", "assembly"],
  ["aea", "assembly"],
  ["argus", "assembly"],
  ["mitigus", "assembly"],
  ["binsource", "assembly"],
  ["vue", "vue"],
  ["ml", "ocaml"],
  ["mli", "ocaml"],
  ["cmx", "ocaml"],
  ["odin", "odin"],
  ["js.map", "javascript-map"],
  ["mjs.map", "javascript-map"],
  ["cjs.map", "javascript-map"],
  ["css.map", "css-map"],
  ["lock", "lock"],
  ["hbs", "handlebars"],
  ["mustache", "handlebars"],
  ["pm", "perl"],
  ["raku", "perl"],
  ["hx", "haxe"],
  ["spec.ts", "test-ts"],
  ["spec.cts", "test-ts"],
  ["spec.mts", "test-ts"],
  ["cy.ts", "test-ts"],
  ["e2e-spec.ts", "test-ts"],
  ["e2e-spec.cts", "test-ts"],
  ["e2e-spec.mts", "test-ts"],
  ["test.ts", "test-ts"],
  ["test.cts", "test-ts"],
  ["test.mts", "test-ts"],
  ["ts.snap", "test-ts"],
  ["spec-d.ts", "test-ts"],
  ["test-d.ts", "test-ts"],
  ["spec.tsx", "test-jsx"],
  ["test.tsx", "test-jsx"],
  ["tsx.snap", "test-jsx"],
  ["spec.jsx", "test-jsx"],
  ["test.jsx", "test-jsx"],
  ["jsx.snap", "test-jsx"],
  ["cy.jsx", "test-jsx"],
  ["cy.tsx", "test-jsx"],
  ["spec-d.tsx", "test-jsx"],
  ["test-d.tsx", "test-jsx"],
  ["spec.js", "test-js"],
  ["spec.cjs", "test-js"],
  ["spec.mjs", "test-js"],
  ["e2e-spec.js", "test-js"],
  ["e2e-spec.cjs", "test-js"],
  ["e2e-spec.mjs", "test-js"],
  ["test.js", "test-js"],
  ["test.cjs", "test-js"],
  ["test.mjs", "test-js"],
  ["js.snap", "test-js"],
  ["cy.js", "test-js"],
  ["module.ts", "angular"],
  ["module.js", "angular"],
  ["ng-template", "angular"],
  ["component.ts", "angular-component"],
  ["component.js", "angular-component"],
  ["guard.ts", "angular-guard"],
  ["guard.js", "angular-guard"],
  ["service.ts", "angular-service"],
  ["service.js", "angular-service"],
  ["pipe.ts", "angular-pipe"],
  ["pipe.js", "angular-pipe"],
  ["filter.js", "angular-pipe"],
  ["directive.ts", "angular-directive"],
  ["directive.js", "angular-directive"],
  ["resolver.ts", "angular-resolver"],
  ["resolver.js", "angular-resolver"],
  ["pp", "puppet"],
  ["ex", "elixir"],
  ["exs", "elixir"],
  ["eex", "elixir"],
  ["leex", "elixir"],
  ["heex", "elixir"],
  ["ls", "livescript"],
  ["erl", "erlang"],
  ["twig", "twig"],
  ["jl", "julia"],
  ["elm", "elm"],
  ["pure", "purescript"],
  ["purs", "purescript"],
  ["tpl", "smarty"],
  ["styl", "stylus"],
  ["re", "reason"],
  ["rei", "reason"],
  ["cmj", "bucklescript"],
  ["merlin", "merlin"],
  ["vhd", "verilog"],
  ["sv", "verilog"],
  ["svh", "verilog"],
  ["nb", "mathematica"],
  ["wl", "wolframlanguage"],
  ["wls", "wolframlanguage"],
  ["njk", "nunjucks"],
  ["nunjucks", "nunjucks"],
  ["robot", "robot"],
  ["sol", "solidity"],
  ["au3", "autoit"],
  ["haml", "haml"],
  ["yang", "yang"],
  ["mjml", "mjml"],
  ["sk", "mjml"],
  ["tf", "terraform"],
  ["tf.json", "terraform"],
  ["tfvars", "terraform"],
  ["tfstate", "terraform"],
  ["blade.php", "laravel"],
  ["inky.php", "laravel"],
  ["applescript", "applescript"],
  ["ipa", "applescript"],
  ["cake", "cake"],
  ["feature", "cucumber"],
  ["features", "cucumber"],
  ["nim", "nim"],
  ["nimble", "nim"],
  ["apib", "apiblueprint"],
  ["apiblueprint", "apiblueprint"],
  ["riot", "riot"],
  ["tag", "riot"],
  ["vfl", "vfl"],
  ["kl", "kl"],
  ["pcss", "postcss"],
  ["sss", "postcss"],
  ["todo", "todo"],
  ["cfml", "coldfusion"],
  ["cfc", "coldfusion"],
  ["lucee", "coldfusion"],
  ["cfm", "coldfusion"],
  ["cabal", "cabal"],
  ["nix", "nix"],
  ["slim", "slim"],
  ["http", "http"],
  ["rest", "http"],
  ["rql", "restql"],
  ["restql", "restql"],
  ["kv", "kivy"],
  ["graphcool", "graphcool"],
  ["sbt", "sbt"],
  ["apk", "android"],
  ["smali", "android"],
  ["dex", "android"],
  ["env", "tune"],
  ["gitlab-ci.yml", "gitlab"],
  ["jenkinsfile", "jenkins"],
  ["jenkins", "jenkins"],
  ["fig", "figma"],
  ["cr", "crystal"],
  ["ecr", "crystal"],
  ["drone.yml", "drone"],
  ["cu", "cuda"],
  ["cuh", "cuda"],
  ["log", "log"],
  ["def", "dotjs"],
  ["dot", "dotjs"],
  ["jst", "dotjs"],
  ["ejs", "ejs"],
  [".wakatime-project", "wakatime"],
  ["pde", "processing"],
  ["stories.js", "storybook"],
  ["stories.jsx", "storybook"],
  ["stories.mdx", "storybook"],
  ["story.js", "storybook"],
  ["story.jsx", "storybook"],
  ["stories.ts", "storybook"],
  ["stories.tsx", "storybook"],
  ["story.ts", "storybook"],
  ["story.tsx", "storybook"],
  ["stories.svelte", "storybook"],
  ["story.mdx", "storybook"],
  ["wpy", "wepy"],
  ["hcl", "hcl"],
  ["san", "san"],
  ["djt", "django"],
  ["red", "red"],
  ["mk", "makefile"],
  ["fxp", "foxpro"],
  ["prg", "foxpro"],
  ["pot", "i18n"],
  ["po", "i18n"],
  ["mo", "i18n"],
  ["lang", "i18n"],
  ["wat", "webassembly"],
  ["wasm", "webassembly"],
  ["ipynb", "jupyter"],
  ["d", "d"],
  ["mdx", "mdx"],
  ["svx", "mdsvex"],
  ["bal", "ballerina"],
  ["balx", "ballerina"],
  ["rkt", "racket"],
  ["bzl", "bazel"],
  ["bazel", "bazel"],
  ["mint", "mint"],
  ["vm", "velocity"],
  ["fhtml", "velocity"],
  ["vtl", "velocity"],
  ["gd", "godot"],
  ["godot", "godot-assets"],
  ["tres", "godot-assets"],
  ["tscn", "godot-assets"],
  ["gdns", "godot-assets"],
  ["gdnlib", "godot-assets"],
  ["gdshader", "godot-assets"],
  ["gdshaderinc", "godot-assets"],
  ["gdextension", "godot-assets"],
  ["azure-pipelines.yml", "azure-pipelines"],
  ["azure-pipelines.yaml", "azure-pipelines"],
  ["azcli", "azure"],
  ["vagrantfile", "vagrant"],
  ["prisma", "prisma"],
  ["cshtml", "razor"],
  ["vbhtml", "razor"],
  ["abc", "abc"],
  ["ad", "asciidoc"],
  ["adoc", "asciidoc"],
  ["asciidoc", "asciidoc"],
  ["edge", "edge"],
  ["ss", "scheme"],
  ["scm", "scheme"],
  ["lisp", "lisp"],
  ["lsp", "lisp"],
  ["cl", "lisp"],
  ["fast", "lisp"],
  ["stl", "3d"],
  ["stp", "3d"],
  ["obj", "3d"],
  ["ac", "3d"],
  ["blend", "3d"],
  ["fbx", "3d"],
  ["mesh", "3d"],
  ["mqo", "3d"],
  ["pmd", "3d"],
  ["pmx", "3d"],
  ["skp", "3d"],
  ["vac", "3d"],
  ["vdp", "3d"],
  ["vox", "3d"],
  ["svg", "svg"],
  ["svelte", "svelte"],
  ["vimrc", "vim"],
  ["gvimrc", "vim"],
  ["exrc", "vim"],
  ["vim", "vim"],
  ["viminfo", "vim"],
  ["moon", "moonscript"],
  ["prw", "advpl_prw"],
  ["prx", "advpl_prw"],
  ["ptm", "advpl_ptm"],
  ["tlpp", "advpl_tlpp"],
  ["ch", "advpl_include"],
  ["iso", "disc"],
  ["f", "fortran"],
  ["f77", "fortran"],
  ["f90", "fortran"],
  ["f95", "fortran"],
  ["f03", "fortran"],
  ["f08", "fortran"],
  ["tcl", "tcl"],
  ["liquid", "liquid"],
  ["p", "prolog"],
  ["pro", "prolog"],
  ["pl", "prolog"],
  ["coco", "coconut"],
  ["sketch", "sketch"],
  ["pwn", "pawn"],
  ["amx", "pawn"],
  ["4th", "forth"],
  ["fth", "forth"],
  ["frt", "forth"],
  ["iuml", "uml"],
  ["pu", "uml"],
  ["puml", "uml"],
  ["plantuml", "uml"],
  ["wsd", "uml"],
  ["wrap", "meson"],
  ["dhall", "dhall"],
  ["dhallb", "dhall"],
  ["sml", "sml"],
  ["mlton", "sml"],
  ["mlb", "sml"],
  ["sig", "sml"],
  ["fun", "sml"],
  ["cm", "sml"],
  ["lex", "sml"],
  ["use", "sml"],
  ["grm", "sml"],
  ["opam", "opam"],
  ["imba", "imba"],
  ["drawio", "drawio"],
  ["dio", "drawio"],
  ["pas", "pascal"],
  ["unity", "shaderlab"],
  ["sas", "sas"],
  ["sas7bdat", "sas"],
  ["sashdat", "sas"],
  ["astore", "sas"],
  ["ast", "sas"],
  ["sast", "sas"],
  ["nupkg", "nuget"],
  ["command", "command"],
  ["dsc", "denizenscript"],
  ["code-search", "search"],
  ["nginx", "nginx"],
  ["nginxconfig", "nginx"],
  ["mcfunction", "minecraft"],
  ["mcmeta", "minecraft"],
  ["mcr", "minecraft"],
  ["mca", "minecraft"],
  ["mcgame", "minecraft"],
  ["mclevel", "minecraft"],
  ["mcworld", "minecraft"],
  ["mine", "minecraft"],
  ["mus", "minecraft"],
  ["mcstructure", "minecraft"],
  ["mcpack", "minecraft"],
  ["mcaddon", "minecraft"],
  ["mctemplate", "minecraft"],
  ["mcproject", "minecraft"],
  ["res", "rescript"],
  ["resi", "rescript-interface"],
  ["b", "brainfuck"],
  ["bf", "brainfuck"],
  ["bicep", "bicep"],
  ["cob", "cobol"],
  ["cbl", "cobol"],
  ["gr", "grain"],
  ["lol", "lolcode"],
  ["idr", "idris"],
  ["ibc", "idris"],
  ["pipeline", "pipeline"],
  ["rego", "opa"],
  ["windi", "windicss"],
  ["scala", "scala"],
  ["sc", "scala"],
  ["ly", "lilypond"],
  ["v", "vlang"],
  ["pgn", "chess"],
  ["fen", "chess"],
  ["gmi", "gemini"],
  ["gemini", "gemini"],
  ["tsconfig.json", "tsconfig"],
  ["tauri", "tauri"],
  ["jsconfig.json", "jsconfig"],
  ["ada", "ada"],
  ["adb", "ada"],
  ["ads", "ada"],
  ["ali", "ada"],
  ["horusec-config.json", "horusec"],
  ["coarc", "coala"],
  ["coafile", "coala"],
  ["bubble", "dinophp"],
  ["html.bubble", "dinophp"],
  ["php.bubble", "dinophp"],
  ["tl", "teal"],
  ["template", "template"],
  ["glsl", "shader"],
  ["vert", "shader"],
  ["tesc", "shader"],
  ["tese", "shader"],
  ["geom", "shader"],
  ["frag", "shader"],
  ["comp", "shader"],
  ["vert.glsl", "shader"],
  ["tesc.glsl", "shader"],
  ["tese.glsl", "shader"],
  ["geom.glsl", "shader"],
  ["frag.glsl", "shader"],
  ["comp.glsl", "shader"],
  ["vertex.glsl", "shader"],
  ["geometry.glsl", "shader"],
  ["fragment.glsl", "shader"],
  ["compute.glsl", "shader"],
  ["ts.glsl", "shader"],
  ["gs.glsl", "shader"],
  ["vs.glsl", "shader"],
  ["fs.glsl", "shader"],
  ["shader", "shader"],
  ["vertexshader", "shader"],
  ["fragmentshader", "shader"],
  ["geometryshader", "shader"],
  ["computeshader", "shader"],
  ["hlsl", "shader"],
  ["pixel.hlsl", "shader"],
  ["geometry.hlsl", "shader"],
  ["compute.hlsl", "shader"],
  ["tessellation.hlsl", "shader"],
  ["px.hlsl", "shader"],
  ["geom.hlsl", "shader"],
  ["comp.hlsl", "shader"],
  ["tess.hlsl", "shader"],
  ["wgsl", "shader"],
  ["sy", "siyuan"],
  ["ndst.yml", "ndst"],
  ["ndst.yaml", "ndst"],
  ["ndst.json", "ndst"],
  ["tobi", "tobi"],
  ["gleam", "gleam"],
  ["steadybit.yml", "steadybit"],
  ["steadybit.yaml", "steadybit"],
  ["tree", "tree"],
  ["cdc", "cadence"],
  ["g4", "antlr"],
  ["st.css", "stylable"],
  ["pine", "pinejs"],
  ["gml", "gamemaker"],
  ["yy", "gamemaker"],
  ["yyp", "gamemaker"],
  ["yyz", "gamemaker"],
  ["tldr", "tldraw"],
  ["typ", "typst"],
  ["mmd", "mermaid"],
  ["mermaid", "mermaid"],
]);