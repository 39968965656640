import { sizeFormatter } from '@/util/fileutil';
import ApiError from './api-error';
import WebFSFileMeta from './file-meta';

export default class UploadInfo {
    public files?: File[];
    public uploadedBytes?: number;
    public totalBytes?: number;
    public error?: ApiError;
    public target?: WebFSFileMeta;
    public bytesPerSecond?: number;

    public get progress(): number {
        if(this.uploadedBytes == undefined || !this.totalBytes) return NaN;
        return this.uploadedBytes / this.totalBytes;
    }

    public get progressInfoFull(): string {
        if(this.uploadedBytes == undefined || !this.totalBytes) return 'N/A';
        return `${(this.progress * 100).toFixed(2)}% (${sizeFormatter(this.uploadedBytes)} / ${sizeFormatter(this.totalBytes)})${this.bytesPerSecond != undefined ? ' | '+sizeFormatter(this.bytesPerSecond)+'/s' : ''}`;
    }

    public get progressInfo(): string {
        if(this.uploadedBytes == undefined || !this.totalBytes) return 'N/A';
        return `${sizeFormatter(this.uploadedBytes)} / ${sizeFormatter(this.totalBytes)}${this.bytesPerSecond != undefined ? ' | '+sizeFormatter(this.bytesPerSecond)+'/s' : ''}`;
    }

    constructor(files?: File[], directory?: WebFSFileMeta) {
        this.files = files;
        this.target = directory;
    }
}