
import WebFSFileMeta from "@/api/file-meta";
import formatter from "@/util/formatter";
import {defineComponent} from "vue";
import {sizeFormatter} from "@/util/fileutil";
import IconButton from "@/components/util/IconButton.vue";
import FSApi from "@/api/file-system-api";
import HighlightedSpan from "@/components/util/HighlightedSpan.vue";
import UserDisplayname from "@/components/util/UserDisplayname.vue";
import FileIcon from "@/components/util/FileIcon.vue";

export default defineComponent({
  name: "FileItem",
  setup() {
    return {FSApi};
  },
  emits: ["chown", "buttoncontextmenu", "bodyclick", "pathclick"],
  props: {
    file: {type: WebFSFileMeta, required: true},
    showPath: {type: Boolean, default: false},
    folderOpen: {type: Boolean, default: false},
    highlight: {type: String, required: false},
    dateType: {type: String, required: true}
  },
  data() {
    return {
      interval: undefined as undefined | number,
      isRefreshingDate: false as boolean,
      modifiedDate: undefined as string | undefined
    }
  },
  computed: {
    fileSize(): string | undefined {
      if (!this.file || this.file.length == undefined) return undefined;
      return sizeFormatter(this.file.length) as string;
    },
  },
  unmounted() {
    this.isRefreshingDate = false;
    if (this.interval)
      clearInterval(this.interval);
  },
  watch: {
    "file": {
      async handler(v) {
        if (this.interval != undefined)
          clearInterval(this.interval)
        if (!v || !v.modified) {
          this.isRefreshingDate = false
        } else if (this.dateType == 'relative') {
          this.isRefreshingDate = true
          formatter.scheduleRelativeDateRefresh(v.modified, () => {
            this.modifiedDate = formatter.formatDateRelative(v.modified)
            return this.isRefreshingDate
          }, (interval) => {
            this.interval = interval;
          })
          this.modifiedDate = formatter.formatDateRelative(v.modified);
        } else {
          this.isRefreshingDate = false
          this.modifiedDate = formatter.formatDate(v.modified);
        }
      }, immediate: true
    }
  },
  components: {
    FileIcon,
    IconButton,
    HighlightedSpan,
    UserDisplayname
  },
});
