import UploadInfo from './upload-info';
import WebFSFileMeta from './file-meta';
import FSApi from './file-system-api';
import { config } from './file-system-api';
import { reactive } from 'vue';
import axios, { AxiosResponse } from 'axios';
import ApiError, { ApiErrorType } from './api-error';
import mitt from 'mitt'
import {WebFSUtil} from 'ksancloud-commons';

export type UploadManagerEvents = {
    success: UploadInfo;
    error: UploadInfo;
    begin: UploadInfo;
    progress: UploadInfo;
}
export default class APIUploadManager {

    public static uploads = reactive({
        current: [] as UploadInfo[]
    });

    public static emitter = mitt<UploadManagerEvents>();

    public static upload(directory: WebFSFileMeta, files: File[]): [UploadInfo, Promise<void | AxiosResponse>] {
        const formData = new FormData();
        [...files].forEach((file, i) => formData.append("uploaded"+i, file, encodeURIComponent(file.name)))

        const index = this.uploads.current.push(new UploadInfo(files, directory)) - 1;
        const info = this.uploads.current[index];

        return [info, this.doUpload(directory.fullpath, info, index, formData)];
    }

    public static uploadText(file: WebFSFileMeta, data: string):  [UploadInfo, Promise<void | AxiosResponse>] {
        const formData = new FormData();
        const blobData = new Blob([data], { type: 'text/plain' });
        formData.append('uploaded0', blobData, encodeURIComponent(file.name));
        
        const index = this.uploads.current.push(new UploadInfo([], file)) - 1;
        const info = this.uploads.current[index];

        const p = WebFSUtil.subPath(file.fullpath, 0, -1) || '';
        return [info, this.doUpload(p, info, index, formData)];
    }

    public static uploadProfilePicture(file: File, uuid?: string):  [UploadInfo, Promise<void | AxiosResponse>] {
        const formData = new FormData();
        formData.append("uploaded", file, "pfp");

        const index = this.uploads.current.push(new UploadInfo([file], undefined)) - 1;
        const info = this.uploads.current[index];

        return [info, this.doUpload(undefined, info, index, formData, uuid)];
    }

    static doUpload(path: string | undefined, info: UploadInfo, index: number, formData: FormData, uuid?: string): Promise<void | AxiosResponse> {
        this.emitter.emit('begin', info);
        let lastTime = performance.now();
        let lastBytes = 0;
        return axios.post(FSApi.urlForPath(path === undefined ? "user/profilePicture/"+(uuid||FSApi.userInfo.value?.uuid): "file/upload", path), formData, {
            onUploadProgress: (e) => {
                const ellapsedSeconds = (performance.now() - lastTime) / 1000;
                info.totalBytes = e.total;
                info.uploadedBytes = e.loaded;
                if(ellapsedSeconds >= 1) {
                    info.bytesPerSecond = (e.loaded - lastBytes) / ellapsedSeconds;
                    lastTime = performance.now();
                    lastBytes = e.loaded;
                }
                this.emitter.emit('progress', info);
            },
            headers: {
                "x-access-token": config.token,
            }
        }).then(res => {
            if(res.data.status === 'ok') {
                this.uploads.current.splice(index, 1);
                this.emitter.emit('success', info);
            } else {
                this.uploads.current[index].error = new ApiError(ApiErrorType.ERROR, res.data.reason);
                this.emitter.emit('error', info);
            }
            return res
        }).catch(error => {
            info.error = ApiError.handle(error);
            this.emitter.emit('error', info);
            return error
        })
    }
}