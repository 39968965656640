import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55e09d2b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "path-display flex-row" }
const _hoisted_2 = {
  key: 0,
  class: "path-div"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["onClick", "onDrop"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "detailspan itemcount"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubSpan = _resolveComponent("SubSpan")!
  const _component_SideScroller = _resolveComponent("SideScroller")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SideScroller, { class: "path-display" }, {
      default: _withCtx(() => [
        (_ctx.parts)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_SubSpan, null, {
                default: _withCtx(() => [
                  _createTextVNode(">")
                ]),
                _: 1
              }),
              _createElementVNode("span", {
                class: "part",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.goto(-1)), ["stop"])),
                onDrop: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleDrop($event, -1)))
              }, "root", 32),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parts, (part, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: part,
                  class: "flex-row"
                }, [
                  _createVNode(_component_SubSpan, { class: "path-separator" }, {
                    default: _withCtx(() => [
                      _createTextVNode("/")
                    ]),
                    _: 1
                  }),
                  (_ctx.archivePathInfo && _ctx.archivePathInfo.includes(index))
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: _ctx.fileutil.requireFileIcon(_ctx.fileutil.getIconForFile(part, !1)),
                        alt: "A",
                        loading: "lazy",
                        class: "path-icon"
                      }, null, 8, _hoisted_3))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    class: "part",
                    onClick: _withModifiers(($event: any) => (_ctx.goto(index)), ["stop"]),
                    onDrop: ($event: any) => (_ctx.handleDrop($event, index))
                  }, _toDisplayString(part), 41, _hoisted_4)
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("span", null, _toDisplayString(_ctx.t("error.badpath")), 1)
            ]))
      ]),
      _: 1
    }),
    (_ctx.itemCount != undefined && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.t(_ctx.itemMsgKey, {count: _ctx.itemCount})), 1))
      : _createCommentVNode("", true)
  ]))
}