
import {defineComponent} from "vue";
import FSApi from "@/api/file-system-api";
import WebFSFileMeta from "@/api/file-meta";
import {ViewState} from "@/util/view-state";
import ApiError, {ApiErrorType} from "@/api/api-error";
import ErrorDisplay from "@/components/displays/ErrorDisplay.vue";
import FileDisplay from "@/components/displays/FileDisplay.vue";
import DirectoryDisplay from "@/components/displays/DirectoryDisplay.vue";
import {WebFSUtil} from "ksancloud-commons";
import fileutil from "@/util/fileutil";
import {MediaType} from "@/util/media-type";

export default defineComponent({
  data: () => {
    return {
      currentFileMeta: WebFSFileMeta.EMPTY,
      viewState: ViewState.LOADING,
      viewError: new ApiError("", ""),
      currentPath: "",
    };
  },
  name: "HomeView",
  async beforeRouteEnter(to, from, next) {
    const fspath = WebFSUtil.join(...to.params.filepath);
    const [err, meta] = fspath
      ? await FSApi.stat(fspath)
      : [ApiError.ofType(ApiErrorType.ERROR), undefined];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore just die
    next((vm) => vm.setData(err, meta, undefined, to.fullPath));
  },
  async beforeRouteUpdate(to, from, next) {
    const fspath = WebFSUtil.join(...to.params.filepath);
    const [err, meta] = fspath
      ? await FSApi.stat(fspath)
      : [ApiError.ofType(ApiErrorType.ERROR), undefined];
    if (this.setData(err, meta, next, to.fullPath)) next();
  },
  methods: {
    setData(err: ApiError | undefined, meta: WebFSFileMeta | undefined, next?: (a: string) => void, errorRedirectTarget?: string): boolean {
      if (err) {
        if (err.type == ApiErrorType.BAD_REQUEST && err.message === "Account disabled") {
          if (next) next("/disabled");
          else this.$router.push({path:"/disabled"})
          return false;
        } else if (err.type == ApiErrorType.NETWORK) {
          if (next) next(`/down?prev=${errorRedirectTarget || '/'}`);
          else this.$router.push({path:"/down",query:{prev:errorRedirectTarget}})
          return false;
        }
        this.currentFileMeta = WebFSFileMeta.EMPTY;
        this.viewState = ViewState.ERROR;
        this.viewError = err;
      } else if (meta) {
        this.currentFileMeta = meta;
        this.viewState = (meta.isdir || fileutil.getMediaTypeForFile(meta.name, false) == MediaType.ARCHIVE) ? ViewState.DIR_VIEW : ViewState.FILE_VIEW;
      }
      return true;
    },
  },
  components: {
    ErrorDisplay,
    FileDisplay,
    DirectoryDisplay,
  },
});
