
import { defineComponent } from "vue";
import ApiError from "@/api/api-error";
import { useTranslation } from "i18next-vue";
import RouterButton from "../util/RouterButton.vue";
import FSApi from "@/api/file-system-api";

export default defineComponent({
  name: "ErrorDisplay",

  props: {
    error: ApiError,
  },

  setup() {
    const { i18next } = useTranslation();
    return { t: i18next.t, i18next: i18next, FSApi };
  },

  data: () => {
    return {
      errorMessage: "<init>",
    };
  },

  components: { RouterButton },
});
