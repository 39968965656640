export const folderNameIconMap = new Map<string,string>([
  ["src", "folder-src"],
  ["srcs", "folder-src"],
  ["source", "folder-src"],
  ["sources", "folder-src"],
  ["code", "folder-src"],
  ["dist", "folder-dist"],
  ["out", "folder-dist"],
  ["build", "folder-dist"],
  ["release", "folder-dist"],
  ["bin", "folder-dist"],
  ["css", "folder-css"],
  ["stylesheet", "folder-css"],
  ["stylesheets", "folder-css"],
  ["style", "folder-css"],
  ["styles", "folder-css"],
  ["sass", "folder-sass"],
  ["_sass", "folder-sass"],
  ["scss", "folder-sass"],
  ["_scss", "folder-sass"],
  ["_images", "folder-images"],
  ["_image", "folder-images"],
  ["_imgs", "folder-images"],
  ["_img", "folder-images"],
  ["images", "folder-images"],
  ["image", "folder-images"],
  ["imgs", "folder-images"],
  ["img", "folder-images"],
  ["icons", "folder-images"],
  ["icon", "folder-images"],
  ["icos", "folder-images"],
  ["ico", "folder-images"],
  ["figures", "folder-images"],
  ["figure", "folder-images"],
  ["figs", "folder-images"],
  ["fig", "folder-images"],
  ["screenshot", "folder-images"],
  ["screenshots", "folder-images"],
  ["screengrab", "folder-images"],
  ["screengrabs", "folder-images"],
  ["pic", "folder-images"],
  ["pics", "folder-images"],
  ["picture", "folder-images"],
  ["pictures", "folder-images"],
  ["script", "folder-scripts"],
  ["scripts", "folder-scripts"],
  ["node_modules", "folder-node"],
  ["js", "folder-javascript"],
  ["javascript", "folder-javascript"],
  ["javascripts", "folder-javascript"],
  ["json", "folder-json"],
  ["jsons", "folder-json"],
  ["font", "folder-font"],
  ["fonts", "folder-font"],
  ["bower_components", "folder-bower"],
  ["test", "folder-test"],
  ["tests", "folder-test"],
  ["testing", "folder-test"],
  ["__tests__", "folder-test"],
  ["__snapshots__", "folder-test"],
  ["__mocks__", "folder-test"],
  ["__fixtures__", "folder-test"],
  ["__test__", "folder-test"],
  ["spec", "folder-test"],
  ["specs", "folder-test"],
  ["jinja", "folder-jinja"],
  ["jinja2", "folder-jinja"],
  ["j2", "folder-jinja"],
  ["markdown", "folder-markdown"],
  ["md", "folder-markdown"],
  ["php", "folder-php"],
  ["phpmailer", "folder-phpmailer"],
  ["sublime", "folder-sublime"],
  ["_post", "folder-docs"],
  ["_posts", "folder-docs"],
  ["doc", "folder-docs"],
  ["docs", "folder-docs"],
  ["document", "folder-docs"],
  ["documents", "folder-docs"],
  ["documentation", "folder-docs"],
  ["post", "folder-docs"],
  ["posts", "folder-docs"],
  ["article", "folder-docs"],
  ["articles", "folder-docs"],
  [".git", "folder-git"],
  ["patches", "folder-git"],
  ["githooks", "folder-git"],
  [".githooks", "folder-git"],
  ["submodules", "folder-git"],
  [".submodules", "folder-git"],
  [".github", "folder-github"],
  ["github", "folder-github"],
  [".gitlab", "folder-gitlab"],
  [".vscode", "folder-vscode"],
  [".vscode-test", "folder-vscode"],
  ["view", "folder-views"],
  ["views", "folder-views"],
  ["screen", "folder-views"],
  ["screens", "folder-views"],
  ["page", "folder-views"],
  ["pages", "folder-views"],
  ["html", "folder-views"],
  ["vue", "folder-vue"],
  [".vuepress", "folder-vuepress"],
  [".expo", "folder-expo"],
  [".expo-shared", "folder-expo"],
  ["cfg", "folder-config"],
  ["cfgs", "folder-config"],
  ["conf", "folder-config"],
  ["confs", "folder-config"],
  ["config", "folder-config"],
  ["configs", "folder-config"],
  ["configuration", "folder-config"],
  ["configurations", "folder-config"],
  ["setting", "folder-config"],
  [".setting", "folder-config"],
  ["settings", "folder-config"],
  [".settings", "folder-config"],
  ["META-INF", "folder-config"],
  ["i18n", "folder-i18n"],
  ["internationalization", "folder-i18n"],
  ["lang", "folder-i18n"],
  ["langs", "folder-i18n"],
  ["language", "folder-i18n"],
  ["languages", "folder-i18n"],
  ["locale", "folder-i18n"],
  ["locales", "folder-i18n"],
  ["l10n", "folder-i18n"],
  ["localization", "folder-i18n"],
  ["translation", "folder-i18n"],
  ["translate", "folder-i18n"],
  ["translations", "folder-i18n"],
  [".tx", "folder-i18n"],
  ["components", "folder-components"],
  ["widget", "folder-components"],
  ["widgets", "folder-components"],
  ["fragments", "folder-components"],
  [".verdaccio", "folder-verdaccio"],
  ["verdaccio", "folder-verdaccio"],
  ["aurelia_project", "folder-aurelia"],
  ["resource", "folder-resource"],
  ["resources", "folder-resource"],
  ["res", "folder-resource"],
  ["asset", "folder-resource"],
  ["assets", "folder-resource"],
  ["static", "folder-resource"],
  ["report", "folder-resource"],
  ["reports", "folder-resource"],
  ["lib", "folder-lib"],
  ["libs", "folder-lib"],
  ["library", "folder-lib"],
  ["libraries", "folder-lib"],
  ["vendor", "folder-lib"],
  ["vendors", "folder-lib"],
  ["third-party", "folder-lib"],
  ["themes", "folder-theme"],
  ["theme", "folder-theme"],
  ["color", "folder-theme"],
  ["colors", "folder-theme"],
  ["design", "folder-theme"],
  ["designs", "folder-theme"],
  ["webpack", "folder-webpack"],
  [".webpack", "folder-webpack"],
  ["global", "folder-global"],
  ["_site", "folder-public"],
  ["public", "folder-public"],
  ["www", "folder-public"],
  ["wwwroot", "folder-public"],
  ["web", "folder-public"],
  ["website", "folder-public"],
  ["site", "folder-public"],
  ["browser", "folder-public"],
  ["browsers", "folder-public"],
  ["include", "folder-include"],
  ["includes", "folder-include"],
  ["_includes", "folder-include"],
  ["inc", "folder-include"],
  ["partials", "folder-include"],
  ["_partials", "folder-include"],
  ["docker", "folder-docker"],
  ["dockerfiles", "folder-docker"],
  [".docker", "folder-docker"],
  ["db", "folder-database"],
  ["database", "folder-database"],
  ["databases", "folder-database"],
  ["sql", "folder-database"],
  ["data", "folder-database"],
  ["_data", "folder-database"],
  ["log", "folder-log"],
  ["logs", "folder-log"],
  ["target", "folder-target"],
  ["temp", "folder-temp"],
  [".temp", "folder-temp"],
  ["tmp", "folder-temp"],
  [".tmp", "folder-temp"],
  ["cached", "folder-temp"],
  ["cache", "folder-temp"],
  [".cache", "folder-temp"],
  ["aws", "folder-aws"],
  [".aws", "folder-aws"],
  ["aud", "folder-audio"],
  ["auds", "folder-audio"],
  ["audio", "folder-audio"],
  ["audios", "folder-audio"],
  ["music", "folder-audio"],
  ["sound", "folder-audio"],
  ["sounds", "folder-audio"],
  ["vid", "folder-video"],
  ["vids", "folder-video"],
  ["video", "folder-video"],
  ["videos", "folder-video"],
  ["movie", "folder-video"],
  ["movies", "folder-video"],
  ["kubernetes", "folder-kubernetes"],
  [".kubernetes", "folder-kubernetes"],
  ["k8s", "folder-kubernetes"],
  [".k8s", "folder-kubernetes"],
  ["import", "folder-import"],
  ["imports", "folder-import"],
  ["imported", "folder-import"],
  ["export", "folder-export"],
  ["exports", "folder-export"],
  ["exported", "folder-export"],
  ["wakatime", "folder-wakatime"],
  [".circleci", "folder-circleci"],
  [".wordpress-org", "folder-wordpress"],
  ["wp-content", "folder-wordpress"],
  ["gradle", "folder-gradle"],
  [".gradle", "folder-gradle"],
  ["coverage", "folder-coverage"],
  [".nyc-output", "folder-coverage"],
  [".nyc_output", "folder-coverage"],
  ["e2e", "folder-coverage"],
  ["it", "folder-coverage"],
  ["integration-test", "folder-coverage"],
  ["integration-tests", "folder-coverage"],
  ["__integration-test__", "folder-coverage"],
  ["__integration-tests__", "folder-coverage"],
  ["class", "folder-class"],
  ["classes", "folder-class"],
  ["model", "folder-class"],
  ["models", "folder-class"],
  ["schemas", "folder-class"],
  ["schema", "folder-class"],
  ["other", "folder-other"],
  ["others", "folder-other"],
  ["misc", "folder-other"],
  ["miscellaneous", "folder-other"],
  ["extra", "folder-other"],
  ["extras", "folder-other"],
  ["etc", "folder-other"],
  ["lua", "folder-lua"],
  ["typescript", "folder-typescript"],
  ["ts", "folder-typescript"],
  ["typings", "folder-typescript"],
  ["@types", "folder-typescript"],
  ["types", "folder-typescript"],
  ["graphql", "folder-graphql"],
  ["gql", "folder-graphql"],
  ["routes", "folder-routes"],
  ["router", "folder-routes"],
  ["routers", "folder-routes"],
  [".ci", "folder-ci"],
  ["ci", "folder-ci"],
  ["benchmark", "folder-benchmark"],
  ["benchmarks", "folder-benchmark"],
  ["performance", "folder-benchmark"],
  ["measure", "folder-benchmark"],
  ["measures", "folder-benchmark"],
  ["measurement", "folder-benchmark"],
  ["messages", "folder-messages"],
  ["messaging", "folder-messages"],
  ["forum", "folder-messages"],
  ["chat", "folder-messages"],
  ["chats", "folder-messages"],
  ["conversation", "folder-messages"],
  ["conversations", "folder-messages"],
  ["less", "folder-less"],
  ["gulp", "folder-gulp"],
  ["gulp-tasks", "folder-gulp"],
  ["gulpfile.js", "folder-gulp"],
  ["gulpfile.mjs", "folder-gulp"],
  ["gulpfile.ts", "folder-gulp"],
  ["gulpfile.babel.js", "folder-gulp"],
  ["python", "folder-python"],
  ["__pycache__", "folder-python"],
  [".pytest_cache", "folder-python"],
  ["debug", "folder-debug"],
  ["debugging", "folder-debug"],
  ["fastlane", "folder-fastlane"],
  ["plugin", "folder-plugin"],
  ["plugins", "folder-plugin"],
  ["_plugins", "folder-plugin"],
  ["extension", "folder-plugin"],
  ["extensions", "folder-plugin"],
  ["addon", "folder-plugin"],
  ["addons", "folder-plugin"],
  ["module", "folder-plugin"],
  ["modules", "folder-plugin"],
  ["middleware", "folder-middleware"],
  ["middlewares", "folder-middleware"],
  ["controller", "folder-controller"],
  ["controllers", "folder-controller"],
  ["service", "folder-controller"],
  ["services", "folder-controller"],
  ["provider", "folder-controller"],
  ["providers", "folder-controller"],
  ["handler", "folder-controller"],
  ["handlers", "folder-controller"],
  ["ansible", "folder-ansible"],
  ["server", "folder-server"],
  ["servers", "folder-server"],
  ["backend", "folder-server"],
  ["client", "folder-client"],
  ["clients", "folder-client"],
  ["frontend", "folder-client"],
  ["pwa", "folder-client"],
  ["tasks", "folder-tasks"],
  ["tickets", "folder-tasks"],
  ["android", "folder-android"],
  ["ios", "folder-ios"],
  ["uploads", "folder-upload"],
  ["upload", "folder-upload"],
  ["downloads", "folder-download"],
  ["download", "folder-download"],
  ["tools", "folder-tools"],
  ["toolkit", "folder-tools"],
  ["toolkits", "folder-tools"],
  ["toolbox", "folder-tools"],
  ["toolboxes", "folder-tools"],
  ["helpers", "folder-helper"],
  ["helper", "folder-helper"],
  [".serverless", "folder-serverless"],
  ["serverless", "folder-serverless"],
  ["api", "folder-api"],
  ["apis", "folder-api"],
  ["restapi", "folder-api"],
  ["app", "folder-app"],
  ["apps", "folder-app"],
  ["apollo", "folder-apollo"],
  ["apollo-client", "folder-apollo"],
  ["apollo-cache", "folder-apollo"],
  ["apollo-config", "folder-apollo"],
  ["arc", "folder-archive"],
  ["arcs", "folder-archive"],
  ["archive", "folder-archive"],
  ["archives", "folder-archive"],
  ["archival", "folder-archive"],
  ["bkp", "folder-archive"],
  ["bkps", "folder-archive"],
  ["bak", "folder-archive"],
  ["baks", "folder-archive"],
  ["backup", "folder-archive"],
  ["backups", "folder-archive"],
  ["back-up", "folder-archive"],
  ["back-ups", "folder-archive"],
  ["batch", "folder-batch"],
  ["batchs", "folder-batch"],
  ["batches", "folder-batch"],
  ["buildkite", "folder-buildkite"],
  [".buildkite", "folder-buildkite"],
  ["cluster", "folder-cluster"],
  ["clusters", "folder-cluster"],
  ["command", "folder-command"],
  ["commands", "folder-command"],
  ["cmd", "folder-command"],
  ["cli", "folder-command"],
  ["clis", "folder-command"],
  ["constant", "folder-constant"],
  ["constants", "folder-constant"],
  ["container", "folder-container"],
  ["containers", "folder-container"],
  [".devcontainer", "folder-container"],
  ["content", "folder-content"],
  ["contents", "folder-content"],
  ["context", "folder-context"],
  ["contexts", "folder-context"],
  ["core", "folder-core"],
  ["delta", "folder-delta"],
  ["deltas", "folder-delta"],
  ["changes", "folder-delta"],
  ["dump", "folder-dump"],
  ["dumps", "folder-dump"],
  ["demo", "folder-examples"],
  ["demos", "folder-examples"],
  ["example", "folder-examples"],
  ["examples", "folder-examples"],
  ["sample", "folder-examples"],
  ["samples", "folder-examples"],
  ["sample-data", "folder-examples"],
  [".env", "folder-environment"],
  [".environment", "folder-environment"],
  ["env", "folder-environment"],
  ["envs", "folder-environment"],
  ["environment", "folder-environment"],
  ["environments", "folder-environment"],
  [".venv", "folder-environment"],
  ["func", "folder-functions"],
  ["funcs", "folder-functions"],
  ["function", "folder-functions"],
  ["functions", "folder-functions"],
  ["lambda", "folder-functions"],
  ["lambdas", "folder-functions"],
  ["logic", "folder-functions"],
  ["math", "folder-functions"],
  ["maths", "folder-functions"],
  ["calc", "folder-functions"],
  ["calcs", "folder-functions"],
  ["calculation", "folder-functions"],
  ["calculations", "folder-functions"],
  ["generator", "folder-generator"],
  ["generators", "folder-generator"],
  ["generated", "folder-generator"],
  ["cfn-gen", "folder-generator"],
  ["gen", "folder-generator"],
  ["gens", "folder-generator"],
  ["auto", "folder-generator"],
  ["hook", "folder-hook"],
  ["hooks", "folder-hook"],
  ["trigger", "folder-hook"],
  ["triggers", "folder-hook"],
  ["job", "folder-job"],
  ["jobs", "folder-job"],
  ["keys", "folder-keys"],
  ["key", "folder-keys"],
  ["token", "folder-keys"],
  ["tokens", "folder-keys"],
  ["jwt", "folder-keys"],
  ["layout", "folder-layout"],
  ["layouts", "folder-layout"],
  ["_layouts", "folder-layout"],
  ["mail", "folder-mail"],
  ["mails", "folder-mail"],
  ["email", "folder-mail"],
  ["emails", "folder-mail"],
  ["smtp", "folder-mail"],
  ["mailers", "folder-mail"],
  ["mappings", "folder-mappings"],
  ["mapping", "folder-mappings"],
  ["meta", "folder-meta"],
  [".changesets", "folder-changesets"],
  [".changeset", "folder-changesets"],
  ["package", "folder-packages"],
  ["packages", "folder-packages"],
  ["pkg", "folder-packages"],
  ["pkgs", "folder-packages"],
  ["shared", "folder-shared"],
  ["common", "folder-shared"],
  ["glsl", "folder-shader"],
  ["hlsl", "folder-shader"],
  ["shader", "folder-shader"],
  ["shaders", "folder-shader"],
  ["stack", "folder-stack"],
  ["stacks", "folder-stack"],
  ["template", "folder-template"],
  ["templates", "folder-template"],
  ["util", "folder-utils"],
  ["utils", "folder-utils"],
  ["utility", "folder-utils"],
  ["utilities", "folder-utils"],
  ["supabase", "folder-supabase"],
  [".supabase", "folder-supabase"],
  ["private", "folder-private"],
  [".private", "folder-private"],
  ["error", "folder-error"],
  ["errors", "folder-error"],
  ["err", "folder-error"],
  ["event", "folder-event"],
  ["events", "folder-event"],
  ["auth", "folder-secure"],
  ["authentication", "folder-secure"],
  ["secure", "folder-secure"],
  ["security", "folder-secure"],
  ["cert", "folder-secure"],
  ["certs", "folder-secure"],
  ["certificate", "folder-secure"],
  ["certificates", "folder-secure"],
  ["ssl", "folder-secure"],
  ["custom", "folder-custom"],
  ["customs", "folder-custom"],
  ["_draft", "folder-mock"],
  ["_drafts", "folder-mock"],
  ["mock", "folder-mock"],
  ["mocks", "folder-mock"],
  ["fixture", "folder-mock"],
  ["fixtures", "folder-mock"],
  ["draft", "folder-mock"],
  ["drafts", "folder-mock"],
  ["concept", "folder-mock"],
  ["concepts", "folder-mock"],
  ["sketch", "folder-mock"],
  ["sketches", "folder-mock"],
  ["syntax", "folder-syntax"],
  ["syntaxes", "folder-syntax"],
  ["spellcheck", "folder-syntax"],
  ["vm", "folder-vm"],
  ["vms", "folder-vm"],
  ["stylus", "folder-stylus"],
  ["flow-typed", "folder-flow"],
  ["rule", "folder-rules"],
  ["rules", "folder-rules"],
  ["validation", "folder-rules"],
  ["validations", "folder-rules"],
  ["validator", "folder-rules"],
  ["validators", "folder-rules"],
  ["review", "folder-review"],
  ["reviews", "folder-review"],
  ["revisal", "folder-review"],
  ["revisals", "folder-review"],
  ["reviewed", "folder-review"],
  ["anim", "folder-animation"],
  ["anims", "folder-animation"],
  ["animation", "folder-animation"],
  ["animations", "folder-animation"],
  ["animated", "folder-animation"],
  ["guard", "folder-guard"],
  ["guards", "folder-guard"],
  ["prisma", "folder-prisma"],
  ["pipe", "folder-pipe"],
  ["pipes", "folder-pipe"],
  ["svg", "folder-svg"],
  ["svgs", "folder-svg"],
  ["terraform", "folder-terraform"],
  [".terraform", "folder-terraform"],
  ["mobile", "folder-mobile"],
  ["mobiles", "folder-mobile"],
  ["portable", "folder-mobile"],
  ["portability", "folder-mobile"],
  [".stencil", "folder-stencil"],
  ["firebase", "folder-firebase"],
  [".firebase", "folder-firebase"],
  ["svelte", "folder-svelte"],
  [".svelte-kit", "folder-svelte"],
  ["update", "folder-update"],
  ["updates", "folder-update"],
  ["upgrade", "folder-update"],
  ["upgrades", "folder-update"],
  [".idea", "folder-intellij"],
  [".azure-pipelines", "folder-azure-pipelines"],
  [".azure-pipelines-ci", "folder-azure-pipelines"],
  ["mjml", "folder-mjml"],
  ["admin", "folder-admin"],
  ["manager", "folder-admin"],
  ["moderator", "folder-admin"],
  ["scala", "folder-scala"],
  ["connection", "folder-connection"],
  ["connections", "folder-connection"],
  ["integration", "folder-connection"],
  ["integrations", "folder-connection"],
  [".quasar", "folder-quasar"],
  [".next", "folder-next"],
  ["cobol", "folder-cobol"],
  ["yarn", "folder-yarn"],
  [".yarn", "folder-yarn"],
  ["husky", "folder-husky"],
  [".husky", "folder-husky"],
  [".storybook", "folder-storybook"],
  ["storybook", "folder-storybook"],
  ["stories", "folder-storybook"],
  ["__stories__", "folder-storybook"],
  ["base", "folder-base"],
  [".base", "folder-base"],
  ["bases", "folder-base"],
  ["cart", "folder-cart"],
  ["shopping-cart", "folder-cart"],
  ["shopping", "folder-cart"],
  ["shop", "folder-cart"],
  ["home", "folder-home"],
  [".home", "folder-home"],
  ["start", "folder-home"],
  [".start", "folder-home"],
  ["project", "folder-project"],
  ["projects", "folder-project"],
  [".project", "folder-project"],
  [".projects", "folder-project"],
  ["interface", "folder-interface"],
  ["interfaces", "folder-interface"],
  [".netlify", "folder-netlify"],
  ["pact", "folder-contract"],
  ["pacts", "folder-contract"],
  ["contract", "folder-contract"],
  [".contract", "folder-contract"],
  ["contracts", "folder-contract"],
  ["contract-testing", "folder-contract"],
  ["contract-test", "folder-contract"],
  ["contract-tests", "folder-contract"],
  ["queue", "folder-queue"],
  ["queues", "folder-queue"],
  ["bull", "folder-queue"],
  ["mq", "folder-queue"],
  ["vercel", "folder-vercel"],
  [".vercel", "folder-vercel"],
  ["now", "folder-vercel"],
  [".now", "folder-vercel"],
  ["cypress", "folder-cypress"],
  [".cypress", "folder-cypress"],
  ["decorator", "folder-decorators"],
  ["decorators", "folder-decorators"],
  ["java", "folder-java"],
  ["resolver", "folder-resolver"],
  ["resolvers", "folder-resolver"],
  ["angular", "folder-angular"],
  [".angular", "folder-angular"],
  ["unity", "folder-unity"],
  ["pdf", "folder-pdf"],
  ["pdfs", "folder-pdf"],
  ["protobufs", "folder-proto"],
  ["proto", "folder-proto"],
  ["plastic", "folder-plastic"],
  [".plastic", "folder-plastic"],
  ["gamemaker", "folder-gamemaker"],
  ["gamemaker2", "folder-gamemaker"],
  [".hg", "folder-mercurial"],
  ["hghooks", "folder-mercurial"],
  [".hghooks", "folder-mercurial"],
  [".hgext", "folder-mercurial"],
  ["godot", "folder-godot"],
  [".godot", "folder-godot"],
  ["godot-cpp", "folder-godot"],
  [".godot-cpp", "folder-godot"],
]);