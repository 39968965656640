
import { useTranslation } from "i18next-vue";
import { defineComponent } from "vue";
import Separator from "./util/Separator.vue";
import FSApi from "@/api/file-system-api";
import IconButton from "./util/IconButton.vue";
import LoginLogo from "./util/LoginLogo.vue";
import { WebFSUtil } from "ksancloud-commons";

export default defineComponent({
  name: "LoginForm",
  setup() {
    const { t, i18next } = useTranslation();
    return { t: t, i18next: i18next, FSApi };
  },
  data() {
    return {
      error: undefined as string | undefined,
      login: "" as string | undefined,
      password: "" as string | undefined,
      showPassword: false as boolean,
    };
  },
  methods: {
    asPublic() {
      this.$router.push("/files/public/");
    },
    doContinue() {
      this.$router.push(
        WebFSUtil.absolutePath(
          WebFSUtil.encodeFilePath(
            WebFSUtil.join("files", FSApi.userInfo.value?.homedir) || ""
          )
        )
      );
    },
    async doLogin() {
      if (!this.password || !this.login) {
        this.error = this.t("login.error.empty-field");
        return;
      }
      const err = await FSApi.dologin(this.login, this.password);
      if (!err) {
        this.$router.push("/files/public/");
      } else {
        this.error = this.i18next.t(`error.${err.type == "400" ? "400-login" : err.type}`, "login.error.invalid-credentials") || 'error'
      }
      this.password = "";
      this.login = "";
    },
    doLogout() {
      FSApi.logout();
      this.$router.go(0);
    },
  },
  components: { Separator, IconButton, LoginLogo },
});
