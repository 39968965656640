
import { defineAsyncComponent, defineComponent } from "vue";
import { MediaType } from "@/util/media-type";
import { useTranslation } from "i18next-vue";
import WebFSFileMeta from "@/api/file-meta";
import FSApi from "@/api/file-system-api";
import FileHeader from "@/components/FileHeader.vue";
import ImageDisplay from "@/components/displays/ImageDisplay.vue";
import VideoDisplay from "@/components/displays/VideoDisplay.vue";
import keyNotify from "@/util/notify-util";
import NavPlaceholder from "../util/NavPlaceholder.vue";
import IconButton from "@/components/util/IconButton.vue";
const AudioDisplay = defineAsyncComponent(
	() => import(/* webpackChunkName: "mp3-disp" */ "./AudioDisplay.vue")
);
const TextDisplay = defineAsyncComponent(
	() => import(/* webpackChunkName: "txt-disp" */ "./TextDisplay.vue")
);
const PdfDisplay = defineAsyncComponent(
	() => import(/* webpackChunkName: "pdf-disp" */ "./PdfDisplay.vue")
);

export default defineComponent({
	name: "FileDisplay",
	data: () => {
		return {
			mediaType: MediaType.NONE as MediaType,
			editingOverride: false as boolean,
			saving: false as boolean,
			codeEdited: false as boolean,
			headerFile: undefined as WebFSFileMeta | undefined,
		};
	},
	setup() {
		const { t } = useTranslation();
		return { t };
	},
	props: {
		file: WebFSFileMeta,
	},
	watch: {
		file: {
			async handler(newVal) {
				this.codeEdited = false;
				if (newVal == undefined) {
					this.mediaType = MediaType.NONE;
					return;
				}
				this.mediaType = (newVal as WebFSFileMeta).mediaType;
				this.headerFile = newVal;
			},
			immediate: true,
		},
	},
	methods: {
		returnHandler() {
			this.codeEdited = false;
			this.$router.back();
		},
		doDownload() {
			if (this.file) FSApi.download([this.file]);
		},
		async doSave() {
			this.saving = true;
			await (this.$refs.textDisplay as typeof TextDisplay).saveFile();
			this.saving = false;
			this.codeEdited = false;
			if (!this.file) return;
			FSApi.stat(this.file.fullpath).then((res) => {
				if (res[1]) this.headerFile = res[1];
			});
			keyNotify("save-success", "success");
		},
	},
	computed: {
    FSApi() {
      return FSApi
    },
		editing() {
			return this.$route.query.edit == "1" || this.editingOverride;
		},
		canEdit() {
			return (
				this.file &&
				this.file?.mediaType == MediaType.TEXT &&
				FSApi.hasPermission(this.file, "vwm")
			);
		},
	},
	components: {
    IconButton,
		ImageDisplay,
		FileHeader,
		VideoDisplay,
		AudioDisplay,
		TextDisplay,
		PdfDisplay,
		NavPlaceholder,
	},
});
