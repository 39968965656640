
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import vClickOutside from "click-outside-vue3";
import {defineComponent} from "vue";
import {ContextMenuOption} from "@/util/context-menu-option";
import IconLabel from "./IconLabel.vue";
import SideScroller from "@/components/util/SideScroller.vue";

export default defineComponent({
  name: "VueSimpleContextMenu",
  props: {
    elementId: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    bindElement: {
      type: Element,
      required: false,
    },
    title: {
      type: String,
      required: false
    }
  },
  components: {SideScroller, IconLabel},
  emits: ["menu-closed", "option-clicked"],
  directives: {
    "click-outside": vClickOutside.directive,
  },
  data() {
    return {
      item: undefined as unknown | undefined,
      menuHeight: undefined as number | undefined,
      menuWidth: undefined as number | undefined,
      vcoConfig: {
        handler: this.onClickOutside,
        events: ['dblclick', 'click'],
      }
    };
  },
  created () {
    window.addEventListener('scroll', this.onClickOutside);
  },
  unmounted () {
    window.removeEventListener('scroll', this.onClickOutside);
  },
  methods: {
    showMenu(
        event: MouseEvent,
        item: unknown,
        overrideBindItem: Element,
        refreshSize = false
    ) {
      this.item = item;
      const bindElement = this.bindElement || overrideBindItem;
      const menu = document.getElementById(this.elementId);
      const x = bindElement ? bindElement.getBoundingClientRect().left : event.pageX - window.scrollX,
          y = bindElement ? bindElement.getBoundingClientRect().bottom : event.pageY - window.scrollY;

      if (!menu || !this.options || this.options.length == 0) {
        return;
      }
      if (!this.menuWidth || !this.menuHeight || refreshSize) {
        menu.style.visibility = "hidden";
        menu.style.display = "block";
        this.menuWidth = menu.offsetWidth;
        this.menuHeight = menu.offsetHeight;
        menu.removeAttribute("style");
      }
      if (this.menuWidth + x >= window.innerWidth) {
        menu.style.left = Math.max(x - this.menuWidth, 0) + 2 + "px";
      } else {
        menu.style.left = x - 2 + "px";
      }

      if (this.menuHeight + y >= window.innerHeight) {
        menu.style.top = Math.max(y - this.menuHeight) + 2 + "px";
      } else {
        menu.style.top = y - 2 + "px";
      }

      menu.classList.add("fade-in");
      menu.classList.add("vue-simple-context-menu--active");
      setTimeout(() => {
        if (menu) menu.classList.remove("fade-in");
      }, 100);
    },
    hideContextMenu() {
      const element = document.getElementById(this.elementId);
      if (element) {
        element.classList.remove("vue-simple-context-menu--active");
        this.$emit("menu-closed");
      }
    },
    onClickOutside() {
      this.hideContextMenu();
    },
    optionClicked(option: ContextMenuOption) {
      this.hideContextMenu();
      this.$emit("option-clicked", {
        item: this.item,
        option: option,
      });
    },
    onEscKeyRelease(event: KeyboardEvent) {
      if (event.keyCode === 27) {
        this.hideContextMenu();
      }
    },
  },
  mounted() {
    document.body.addEventListener("keyup", this.onEscKeyRelease);
  },
  beforeUnmount() {
    document.removeEventListener("keyup", this.onEscKeyRelease);
  },
});
