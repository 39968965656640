import { createApp, watch } from "vue";
import i18n from "./i18n";
import App from "./Suspenser.vue";
import router from "./router";
import FSApi, { config } from "./api/file-system-api";
import { createVfm } from 'vue-final-modal'
import Notifications from '@kyvg/vue3-notification'
const vfm = createVfm();
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vClickOutside from "click-outside-vue3";
import 'vue-final-modal/style.css'
import { clientConfig } from "./config";
import {AppPreferenceService} from "@/util/app-preference-service";

// File system api config
config.port = clientConfig.apiPort || (location.port ? parseInt(location.port) : 0);
config.endpoint = `${location.protocol}//${location.hostname}${config.port ? ':' + config.port : ''}/`;
config.token = localStorage.getItem('api-access-token') || '';

window.addEventListener('storage', (event) => {
    if(event.key == 'api-access-token') {
        config.token = event.newValue || '';
    }
});

class DummyTouchEvent extends Event {
    constructor(type: string) {
        super(type);
    }
}

if (window.TouchEvent == undefined) {
    console.debug("Injecting a dummy TouchEvent to silence VFM errors")
   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
   // @ts-ignore
   window.TouchEvent = DummyTouchEvent;
}

watch(config, async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, newUserInfo] = await FSApi.fetchUserInformation();
    if(newUserInfo) 
        FSApi.userInfo.value = newUserInfo;
})

AppPreferenceService.init()

i18n(createApp(App)).use(router).use(vfm).use(Notifications).use(vClickOutside).mount("#app");
