import {createRouter, createWebHistory, NavigationGuardNext, RouteRecordRaw} from "vue-router";
import HomeView from "../views/HomeView.vue";
import FileView from "@/views/FileView.vue";
import LoginView from "@/views/LoginView.vue";
import FSApi from "../api/file-system-api";
import AccountView from "@/views/AccountView.vue";
import SearchView from "@/views/SearchView.vue";
import {ApiErrorType} from "@/api/api-error";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/files/:filepath(.*)*",
        name: "files",
        component: FileView,
        props: true,
    },
    {
        path: "/login",
        name: "login",
        component: LoginView,
    },
    {
        path: "/search",
        name: "search",
        component: SearchView,
    },
    {
        path: "/account",
        name: "account",
        component: AccountView,
    },
    {
        path: "/dashboard/stat",
        name: "stat-dashboard",
        component: () =>
            import(/* webpackChunkName: "stat-dashboard" */ "../views/StatView.vue"),
    },
    {
        path: "/disabled",
        name: "account-disabled",
        component: () =>
            import(/* webpackChunkName: "stat-dashboard" */ "../views/DisabledAccountView.vue"),
    },
    {
        path: "/down",
        name: "api-down",
        component: () =>
            import(/* webpackChunkName: "stat-dashboard" */ "../views/ApiDownView.vue"),
    },
    {
        path: "/dashboard/admin",
        name: "admin-dashboard",
        component: () =>
            import(/* webpackChunkName: "admin-dashboard" */ "../views/WebAdminView.vue"),
    },
    {
        path: "/:catchAll(.*)",
        redirect: '/404'
    },
    {
        path: "/404",
        component: () =>
            import(/* webpackChunkName: "stat-dashboard" */ "../views/NotFound.vue")
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});


router.beforeEach(async (to, from, next: NavigationGuardNext) => {
    if (FSApi.userInfo.value == undefined && to.redirectedFrom == undefined) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [error, newUserInfo] = await FSApi.fetchUserInformation('', true);

        if (newUserInfo) {
            FSApi.userInfo.value = newUserInfo;
        } else if (error) {
            if (error.name == ApiErrorType.UNAUTHORIZED) {
                next('/login')
            } else if (error.name == ApiErrorType.BAD_REQUEST) {
                next('/disabled')
            } else {
                next(`/down?prev=${to.path}`)
            }
            return;
        }
    }
    next();
})

export default router;
