export default class ThemeManager {
    
    public static init() {
        document.documentElement.dataset.theme = ThemeManager.getSelectedTheme();
    }
    
    public static getSelectedTheme(): string {
        return localStorage.getItem("theme") || document.documentElement.dataset.theme || "dark";
    }
    
    public static setTheme(newTheme: string) {
        if(!this.getThemes().includes(newTheme)) return;
        document.documentElement.dataset.theme = newTheme;
        localStorage.setItem("theme", newTheme);
    }

    public static getThemes(): string[] {
        return ['dark-plus', 'dark', 'light'];
    }
}

