"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Permission = void 0;
const webfsutils_1 = require("./webfsutils");
class Permission {
    constructor(parts, mode) {
        this.parts = parts;
        this.mode = mode;
    }
    match(path, mode) {
        return this.matchMode(mode) && this.matchPath(path);
    }
    matchMode(mode) {
        return (this.mode & mode) == mode;
    }
    matchPath(path) {
        var splitPath = webfsutils_1.WebFSUtil.splitPath(path);
        if (splitPath == undefined)
            return false;
        return this.matchPathSplit(splitPath);
    }
    matchPathSplit(path) {
        if (path.length < this.parts.length)
            return false;
        for (let i = 0; i < Math.min(path.length, this.parts.length); i++)
            if (path[i] != this.parts[i])
                return false;
        return true;
    }
    toString() {
        return `${this.parts.join(Permission.PERMISSION_PATH_SEPARATOR)}${Permission.PERMISSION_PART_SEPARATOR}${Permission.modeToString(this.mode)}`;
    }
    static modeToString(mode) {
        const decoded = this.decodeAccessMode(mode);
        return `${decoded.view ? "v" : Permission.PERMISSION_MODE_FILLER}${decoded.write ? "w" : Permission.PERMISSION_MODE_FILLER}${decoded.modify ? "m" : (decoded.modifyOwned ? "o" : Permission.PERMISSION_MODE_FILLER)}`;
    }
    get pathstring() {
        // @ts-ignore
        return webfsutils_1.WebFSUtil.absolutePath(webfsutils_1.WebFSUtil.join(...this.parts));
    }
    /**
     * parses a permission in the following format: <path>:<mode>
     * /home/user/:vwdm
     * @param {string} permissionString permission
     * @returns {Permission} parsed permission or undefiend if the permission is not valid
     */
    static parse(permissionString) {
        var a = permissionString.split(":");
        if (a.length < 2)
            return undefined;
        return new Permission(this.parsePermission(a.slice(0, -1).join(":")), this.parseMode(a[a.length - 1]));
    }
    static parsePermission(permssion) {
        return permssion
            .split(Permission.PERMISSION_PATH_SEPARATOR)
            .filter((e) => e != "");
    }
    /**
     * v - view
     * w - write
     * o - modifyOwned
     * m - modify
     * @param mode string repr. of the access mode
     * @returns PermissionMode
     */
    static parseMode(mode) {
        return this.encodeAccessMode({
            view: /v/i.test(mode),
            write: /w/i.test(mode),
            modifyOwned: /o/i.test(mode),
            modify: /m/i.test(mode),
        });
    }
    static encodeAccessMode(mode) {
        let encodedMode = 0;
        encodedMode |= mode.view ? 1 : 0;
        encodedMode |= mode.write ? 2 : 0;
        encodedMode |= mode.modifyOwned ? 4 : 0;
        encodedMode |= mode.modify ? 12 : 0;
        return encodedMode;
    }
    static decodeAccessMode(mode) {
        return {
            view: ((mode & 1) != 0),
            write: ((mode & 2) != 0),
            modifyOwned: ((mode & 4) != 0),
            modify: ((mode & 8) != 0),
        };
    }
    /**
     * Serializes permissions to their string representation
     * @param perms Permissions to serialize
     * @returns Serialized permission list
     */
    static serialize(perms) {
        return perms
            .map((perm) => perm.toString())
            .filter((e) => e != undefined);
    }
    /**
     * Parses the permissions from their string representations
     * @param perms Permissions to parse
     * @returns Succesfully parsed permissions
     */
    static deserialize(perms) {
        // @ts-ignore ffs.........
        return perms.map((perm) => Permission.parse(perm)).filter((e) => e);
    }
}
exports.Permission = Permission;
Permission.PERMISSION_PATH_SEPARATOR = "/";
Permission.PERMISSION_PART_SEPARATOR = ":";
Permission.PERMISSION_MODE_FILLER = "-";
