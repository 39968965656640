"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebFSUtil = void 0;
class WebFSUtil {
    /**
     * Checks it the given name is a valid file or folder name
     * @param name name to be checked
     * @returns boolean
     */
    static nameValid(name) {
        if (!name)
            return false;
        if (["", ".", "..", "*"].includes(name))
            return false;
        if (name.length > WebFSUtil.MAX_SUPPORTED_PATH_LENGTH)
            return false;
        return !name.match(/[\\\/]/gi);
    }
    /**
     * Checks if the provided path is valid
     * @param path string path or pre-parsed path
     * @returns
     */
    static pathValid(path) {
        if (path == undefined)
            return false;
        if (path.length > WebFSUtil.MAX_SUPPORTED_PATH_LENGTH)
            return false;
        let pathb = WebFSUtil.splitPath(path);
        return this.splitPathValid(pathb);
    }
    /**
     * Checks if the provided path is valid
     * @param path string path or pre-parsed path
     * @returns
     */
    static splitPathValid(path) {
        if (path == undefined)
            return false;
        if (path.length == 0)
            return true;
        // @ts-ignore piss off xD
        return path.reduce((val, current) => {
            if (!val)
                return false;
            return !["", ".", "..", "*"].includes(current);
        }, true);
    }
    /**
     * Splits the path by the PATH_SEPARATOR
     * Removes empty elements, essential merging multiple separators into one
     * ex. /home//test/ => ['home', 'test']
     * @param path string path or pre-parsed path ( will return it unchanged )
     * @returns the split path
     */
    static splitPath(path) {
        return path.split(/[\/\\]/g).filter(e => e != '');
    }
    static normalize(path) {
        const parsedPath = this.splitPath(path);
        return this.normalizeSplit(parsedPath);
    }
    static normalizeSplit(path) {
        if (path == undefined || !this.splitPathValid(path))
            return undefined;
        const p = path.join(WebFSUtil.PATH_SEPARATOR);
        if (p == '')
            return WebFSUtil.PATH_SEPARATOR; // is root dir
        return p;
    }
    static join(...path) {
        let joined = [];
        for (let i = 0; i < path.length; i++) {
            let p = path[i];
            if (p == undefined)
                continue;
            const parsed = this.splitPath(p);
            if (parsed == undefined)
                return undefined;
            joined = joined.concat(parsed);
        }
        if (!this.splitPathValid(joined))
            return undefined;
        return this.normalizeSplit(joined);
    }
    /**
     * Encodes each path part using `encodeURIComponent`.
     * Similar to `encodeURI` but `?` and `&` characters are also encoded
     * @param path
     * @returns encoded path
     */
    static encodeFilePath(path) {
        const encodedPath = WebFSUtil.splitPath(path).map(encodeURIComponent);
        return WebFSUtil.join(...encodedPath);
    }
    /**
     * Decodes each path part using `encodeURIComponent`.
     * Similar to `decodeURI` but `?` and `&` characters are also decoded
     * @param path
     * @returns encoded path
     */
    static decodeFilePath(path) {
        const encodedPath = WebFSUtil.splitPath(path).map(decodeURIComponent);
        return WebFSUtil.join(...encodedPath);
    }
    /**
     *
     * @param path
     * @returns second to last element of the path
     */
    static dirname(path) {
        if (!path)
            return undefined;
        const split = this.splitPath(path);
        if (!this.splitPathValid(split))
            return undefined;
        //@ts-check
        if (split.length <= 1)
            return WebFSUtil.PATH_SEPARATOR;
        return this.normalizeSplit(split.slice(0, split.length - 1));
    }
    /**
     * Checks if the path is a child of the root path.
     * A path is considered a child of the root path
     * is contained in the beginning of the child path
     * and the child path is longer than the root path
     * @param root root path
     * @param path child path
     * @param immediate whether the child path should be an immediate child of the root path
     */
    static isChild(root, path, immediate = false) {
        const s1 = this.splitPath(root);
        const s2 = this.splitPath(path);
        if (s2.length <= s1.length || (immediate && s2.length - 1 != s1.length))
            return false;
        for (let i = 0; i < s1.length; i++)
            if (s1[i] !== s2[i])
                return false;
        return true;
    }
    /**
     * @param path
     * @returns the last element of the path
     */
    static basename(path) {
        const split = this.splitPath(path);
        if (!this.splitPathValid(split))
            return undefined;
        if (split.length == 0)
            return "/";
        return split[split.length - 1];
    }
    /**
     * @param name filename
     * @returns name split into its components
     */
    static splitname(name) {
        const rext = /(\.[^.]*)$/.exec(name);
        const ext = rext == null || rext.length == 0 ? undefined : rext[0];
        name = name.substring(0, name.length - (ext != undefined ? ext.length : 0));
        return { name: (name != '' ? name : undefined), ext };
    }
    /**
     * Returns a sublist of the path parts
     * Similar to Array.prototype.slice
     * @param path path to split
     * @param begin start index
     * @param end end index
     * @returns
     */
    static subPath(path, begin, end) {
        const split = this.splitPath(path);
        return this.normalizeSplit(split.slice(begin, end));
    }
    /**
     * Validates and decodes a path from the uri
     * @param uripath path to validate
     * @param begin optional subpath arg
     * @param end optional subpath arg
     * @see subPath
     */
    static pathFromURI(uripath, begin, end) {
        const decodedURI = WebFSUtil.decodeFilePath(uripath);
        if (!this.pathValid(decodedURI))
            return undefined;
        return this.subPath(decodedURI, begin, end);
    }
    static absolutePath(path) {
        return path.startsWith("/") ? path : "/" + path;
    }
}
exports.WebFSUtil = WebFSUtil;
WebFSUtil.PATH_SEPARATOR = "/";
WebFSUtil.MAX_SUPPORTED_PATH_LENGTH = 4096;
