import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3730e244"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmallLogo = _resolveComponent("SmallLogo")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_FilenameInput = _resolveComponent("FilenameInput")!
  const _component_ClosableModal = _resolveComponent("ClosableModal")!
  const _component_UploadManager = _resolveComponent("UploadManager")!
  const _component_SearchSettings = _resolveComponent("SearchSettings")!
  const _component_CollapsableTextfield = _resolveComponent("CollapsableTextfield")!
  const _component_AccountIcon = _resolveComponent("AccountIcon")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createVNode(_component_SmallLogo),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_IconButton, {
        icon: "arrow_back.svg",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.navBack()), ["prevent"])),
        disabled: _ctx.returnDisabled
      }, null, 8, ["disabled"]),
      _createVNode(_component_IconButton, {
        icon: "arrow_forward.svg",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => {
          _ctx.$emit('forward');
          _ctx.navForward();
        }, ["prevent"])),
        disabled: _ctx.forwardDisabled
      }, null, 8, ["disabled"]),
      _createVNode(_component_IconButton, {
        icon: "create_new_folder.svg",
        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.vfm.open('mkdirModalId')), ["prevent"])),
        disabled: !_ctx.FSApi.hasPermission(_ctx.$route.params.filepath, 'vw') || _ctx.isInArchive
      }, null, 8, ["disabled"]),
      _createVNode(_component_ClosableModal, {
        "modal-id": `mkdirModalId`,
        onClose: _cache[5] || (_cache[5] = () => _ctx.vfm.close('mkdirModalId'))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_FilenameInput, {
            ref: "mkdirform",
            "hint-is-dir": true,
            onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.vfm.close('mkdirModalId'))),
            onChoose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('mkdir', $event))),
            modalkey: "mkdir"
          }, null, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_IconButton, {
        icon: "file_upload.svg",
        onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.vfm.open('uploadModalId')), ["prevent"])),
        disabled: !_ctx.FSApi.hasPermission(_ctx.$route.params.filepath, 'vw') || _ctx.isInArchive
      }, null, 8, ["disabled"]),
      _createVNode(_component_ClosableModal, {
        "modal-id": `uploadModalId`,
        onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.vfm.close('uploadModalId')))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_UploadManager, { directory: _ctx.directory }, null, 8, ["directory"])
        ]),
        _: 1
      }),
      _createVNode(_component_IconButton, {
        icon: "add_notes.svg",
        onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.vfm.open('mkfileModalId')), ["prevent"])),
        disabled: !_ctx.FSApi.hasPermission(_ctx.$route.params.filepath, 'vw') || _ctx.isInArchive
      }, null, 8, ["disabled"]),
      _createVNode(_component_ClosableModal, {
        "modal-id": `mkfileModalId`,
        onClose: _cache[12] || (_cache[12] = () => _ctx.vfm.close('mkfileModalId'))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_FilenameInput, {
            "hint-is-dir": false,
            "auto-warn": "",
            onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.vfm.close('mkfileModalId'))),
            onChoose: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('mkfile', $event))),
            onMkdir: _cache[11] || (_cache[11] = ($event: any) => {_ctx.$emit('mkdir', $event);}),
            modalkey: "mkfile"
          })
        ]),
        _: 1
      }),
      _createVNode(_component_CollapsableTextfield, {
        icon: "search.svg",
        buttons: [{name:'settings', icon:'settings.svg'}],
        onClick: _ctx.searchClickHandler,
        placeholder: _ctx.t('directory-nav.search-placeholder'),
        onSubmit: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('search', $event)))
      }, {
        "extra-content": _withCtx(() => [
          _createVNode(_component_SearchSettings)
        ]),
        _: 1
      }, 8, ["buttons", "onClick", "placeholder"]),
      _createVNode(_component_IconButton, {
        icon: _ctx.showGrid ? 'view_list.svg' : 'grid_view.svg',
        onClick: _withModifiers(_ctx.changeDisplayMode, ["prevent"])
      }, null, 8, ["icon", "onClick"])
    ]),
    _createVNode(_component_AccountIcon, {
      clickable: "",
      user: _ctx.FSApi.userInfo.value?.uuid
    }, null, 8, ["user"])
  ]))
}